import { Component } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'OQCS';
  user;
  private previousUrl: string;
  private currentUrl: string;
  subscription: Subscription;
  constructor(location: PlatformLocation, private router: Router) {

    localStorage.setItem("impersonatemode", "false");
    localStorage.removeItem("currentloginId");
    localStorage.removeItem("minisidemenu");
    location.onPopState(() => {

      this.user = localStorage.getItem('jwtToken');

      if (this.user === null || this.user == "") {
        this.router.navigate(['/login']);
      }
    });

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        if (browserRefresh === true) {
          // keep cache on refresh
          localStorage.removeItem('companylist');
          localStorage.removeItem('searchvalue');
        }
        else {
          // clear userlist filter data
          localStorage.removeItem('filterdata');
          localStorage.removeItem('chipdisplayfilterdata');
          localStorage.removeItem('clearfilteritemkey');
          localStorage.removeItem('filterapibodycontent');

          localStorage.removeItem("selectTabIndex");
          localStorage.removeItem("selectTabIndexName");
          localStorage.removeItem("selectTabBreadCrumbs");

          // clear grouplistp filter data
          localStorage.removeItem('groupfilterdata');
          localStorage.removeItem('group_chipdisplayfilterdata');
          localStorage.removeItem('group_clearfilteritemkey');
          localStorage.removeItem('group_filterapibodycontent');

          // clear grouplistp filter data
         localStorage.removeItem('groupfilterdataforcurri');  // clear grouplistpopup filter data
         localStorage.removeItem('group_chipdisplayfilterdataforcurri');
         localStorage.removeItem('group_clearfilteritemkeyforcurri');
         localStorage.removeItem('group_filterapibodycontentforcurri');

          // clear Manager filter data
          localStorage.removeItem('Manager_filterdata');
          localStorage.removeItem('Manager_chipdisplayfilterdata');
          localStorage.removeItem('Manager_clearfilteritemkey');
          localStorage.removeItem('Manager_filterapibodycontent');

             // clear Manager filter data
             localStorage.removeItem('Evaluator_filterdata');
             localStorage.removeItem('Evaluator_chipdisplayfilterdata');
             localStorage.removeItem('Evaluator_clearfilteritemkey');
             localStorage.removeItem('Evaluator_filterapibodycontent');

         // localStorage.removeItem('user_details');
         localStorage.removeItem('chipdisplayfilterdataforterms');
        }
      }
    });


  }
}
