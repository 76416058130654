import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-transcriptlist-filter',
  templateUrl: './transcriptlist-filter.component.html',
  styleUrls: ['./transcriptlist-filter.component.scss'],
  providers: [DatePipe]
})
export class TranscriptlistFilterComponent implements OnInit {
   filterform: FormGroup;
  statuslist: any = [""];
  loadspinner: any = false;
  companylistdata: any = [""];
  apibodycontent: any;
  disablebutton:any;
  enddateconsistnull:any;
  today = new Date();
  category: any = [""];
  type: any = [""];
  sharedRec: any = [""];

  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      Title: [''],
      CompanyType: [''],
      Owner: [''],
      Status: [''],
      start: [''],
      end: [''],
      Category:[""],
      sharedRec: [''],

    });
   this.apicallgetdefaultdropdown();
  }
  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.statuslist = [
          { "status_id": 1, "statusName": "Not Started" },
          { "status_id": 4, "statusName": "In Progress" },
          { "status_id": 2, "statusName": "Completed" },
          { "status_id": 3, "statusName": "Failed" },
          { "status_id": 5, "statusName": "Passed" },
          { "status_id": 6, "statusName": "Expired" },
          { "status_id": 7, "statusName": "Qualified" },
          { "status_id": 8, "statusName": "Not Qualified" },
        ]
        this.category = [
          { "categoryName": "eCourse" },
          { "categoryName": "Curriculum" },
          { "categoryName": "Forms" },
          { "categoryName": "Evaluation" },
          { "categoryName": "Task" },
          { "categoryName": "Exam" },

        ]
        this.type = [
          {"typeName": "TNG" },
          {"typeName": "KNT" },
          {"typeName": "PEF" },
          {"typeName": "Video" },
          {"typeName": "Other" },

        ]
        this.sharedRec = [
          { "status_id": 1, "statusName": "Yes" },
          { "status_id": 4, "statusName": "No" },
        ]
        this.companylistdata = response["companies"];
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          // console.log(err)

        });


        this.setdefaultdropdownvalues();

  }

  ngOnInit(): void {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Manager_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
      // this.filterform.controls['ISTEMPLATE'].setValue(false);
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['Title'].setValue(data["Title"]);
      this.filterform.controls['CompanyType'].setValue(data["Type"]);
      this.filterform.controls['Owner'].setValue(data["Owner"]);
      this.filterform.controls['Status'].setValue(data["Status"]);
      this.filterform.controls['Category'].setValue(data["Category"]);
      this.filterform.controls['sharedRec'].setValue(data["SharedRec"]);

      // for date range
      let a = data["Completion Date"].split('-')[0];
      let b = data["Completion Date"].split('-')[1];

      if (data["Completion Date"] === null || data["Completion Date"] === undefined || data["Completion Date"] === "") {
        this.filterform.controls['start'].setValue("");
        this.filterform.controls['end'].setValue("");
      } else {
        this.filterform.controls['start'].setValue(new Date(a));
        this.filterform.controls['end'].setValue(new Date(b));
      }
      this.clearfilterfunction();
    }
  }


  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Title") {
          this.filterform.controls['Title'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Owner") {
          this.filterform.controls['Owner'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Status") {
          this.filterform.controls['Status'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Completion Date") {
          this.filterform.controls['start'].setValue("");
          this.filterform.controls['end'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Type") {
          this.filterform.controls['CompanyType'].setValue("");
        }
        else if (clearfilteritemkey[i] === "SharedRec") {
          this.filterform.controls['sharedRec'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  // on keyup enable filter button
  selectowner(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Owner'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }

  selectStatusName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }

  selectCategory(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Category'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }

  selectType(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyType'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }

  inputchangeID1(value) {
    this.dynamicEnableDisablebtn()

  }

  inputchangeID2(value) {
    this.dynamicEnableDisablebtn()

  }

  selectcheckbox(value)
  {
    this.dynamicEnableDisablebtn()
  }

  startChange(event) {
    console.log("startChange", event.value)
    //console.log(this.filterform.value.end);

  }
  endChange(event) {
    console.log("endChange", event.value);
    if (event.value === null) {
      this.enddateconsistnull=true;
      this.disablebutton = true;
    }
    else {
      this.enddateconsistnull=false;
      this.dynamicEnableDisablebtn();

    }
    //
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('Manager_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('Manager_filterdata');
      localStorage.removeItem('Manager_chipdisplayfilterdata');
      localStorage.removeItem('Manager_clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      //this.Filter();
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }


  getbodycontent() {
    let daterange = "";
    let startdate = "";
    let enddate = "";
    let apidaterange = "";
    let apistartdate = "";
    let apienddate = "";
    if (this.filterform.value.start === "" || this.filterform.value.start === undefined || this.filterform.value.start === null) {
      daterange = "";
    }
    else {
      startdate = this.datePipe.transform(this.filterform.value.start, 'MM/dd/yyyy');
      enddate = this.datePipe.transform(this.filterform.value.end, 'MM/dd/yyyy');
      daterange = startdate + " - " + enddate;
      apistartdate = this.datePipe.transform(this.filterform.value.start, 'yyyy-MM-dd');
      apienddate = this.datePipe.transform(this.filterform.value.end, 'yyyy-MM-dd');
    }

    let filterdata = {
      "Title": this.filterform.value.Title.trim(),
      "Type": this.filterform.value.CompanyType.trim(),
      "Completion Date": daterange,
      "Status": this.filterform.value.Status.trim(),
      "Owner": this.filterform.value.Owner.trim(),
      "Category": this.filterform.value.Category.trim(),
      "SharedRec": this.filterform.value.sharedRec.trim(),

    }
    this.getapibodycontent( apistartdate, apienddate);
    return filterdata

  }

  getapibodycontent(apistartdate, apienddate) {
    let companylistdataid = "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.Owner) {
        console.log(this.companylistdata)
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    let loginName = localStorage.getItem('loginId')

    this.apibodycontent = {
    "title":this.filterform.value.Title.trim(),
    "type":this.filterform.value.CompanyType.trim(),
    "status":this.filterform.value.Status.trim(),
    "companyId":companylistdataid,
    "startCompletionDate":apistartdate,
    "endCompletionDate":apienddate,
    "category":this.filterform.value.Category.trim(),
    "loginName": loginName,
    "sharedFlag":this.filterform.value.sharedRec.trim(),
    }
  }

  



  checkformvalue() {
    let formempty;
    console.log(this.filterform.value.Title)
    if ((this.filterform.value.Title.trim() === "") && (this.filterform.value.CompanyType.trim() === "") && (this.filterform.value.Status.trim() === "") && (this.filterform.value.sharedRec.trim() === "") && 
     (this.filterform.value.Owner.trim() === "") && (this.filterform.value.Category.trim() === "")&& (this.filterform.value.start === "" || this.filterform.value.start === null) && (this.filterform.value.end === "" || this.filterform.value.end === null)) {
      formempty = true;
    }
    else {
      formempty = false;
      if(this.enddateconsistnull===true){
        formempty = true;
      }
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }
  selectSharedRec(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['sharedRec'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }
}