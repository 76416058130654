import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidemenudataserviceService {
  appPages:any;
  appPages1:any;

  HOME_MENU: any;
  USERS_MENU: any;
  AssignableContent_Menu: any;
  TASK_MENU: any;
  COMPANY_MENU: any;
  ExamManagement_MENU: any;
  Announcements_MENU: any;
  Reports_MENU: any;
  Help_MENU: any;

  LearnerHome: any;
  LearnerAssignment: any;
  LearnerTask: any;
  LearnerTranscripts: any;
  LearnerCalendar: any;

  ProctorHome :any;
  EvaluatorHome :any;
  CourseList :any;
  AssignedUser :any;
  constructor() {
  }
  GetSidemenuDatafooter() {
    this.appPages1 = [
      {
        title: 'Settings',
        sideMenutitle: 'Settings',
        Icon: 'assets/images/settings_white_24dp.svg',
        Icon1: 'assets/images/settingyellow.svg',
        path: "/Adminsetting",
        SubMenu: []

      }
    ]
    return this.appPages1
  }

  GetSidemenuData() {

    let role = localStorage.getItem("loginRole")
    if (role === "Learner") {
      this.appPages = [
        {
          title: 'Home',
          sideMenutitle: 'Home',
          Icon: 'assets/images/home.svg',
          Icon1: 'assets/images/home-copy.svg',
          path: "/LearnerHome",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Assignments',
          sideMenutitle: 'Assignments',
          Icon: 'assets/images/course-catalogside.svg',
          Icon1: 'assets/images/course-catalog-copyside.svg',
          path: "/Assignments",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Tasks',
          sideMenutitle: 'Tasks',
          Icon: 'assets/images/qualifications.svg',
          Icon1: 'assets/images/qualifications-copy.svg',
          path: "/LearnerTask",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Transcripts',
          sideMenutitle: 'Transcripts',
          Icon: 'assets/images/company-management.svg',
          Icon1: 'assets/images/company-management-copy.svg',
          path: "/LearnerTranscripts",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Calendar',
          sideMenutitle: 'Calendar',
          Icon: 'assets/images/exam-mgmt.svg',
          Icon1: 'assets/images/exam-mgmt-copy.svg',
          path: "/LearnerCalendar",
          Showdata: true,
          SubMenu: []

        },
      ]
      let p = this.LearnerTabFunctionality(this.appPages);
      return p;
    }

    else if (role === "System Administrator") {
      this.appPages = [
        {
          title: 'Home',
          sideMenutitle: 'Home',
          Icon: 'assets/images/home.svg',
          Icon1: 'assets/images/home-copy.svg',
          path: "/Home",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Users',
          sideMenutitle: 'Users',
          Icon: 'assets/images/users.svg',
          Icon1: 'assets/images/users-copy.svg',
          path: "/user",
          Showdata: true,
          SubMenu: []

        },

        {
          title: 'AssignableContent',
          sideMenutitle: 'Assignable Content',
          Icon: 'assets/images/course-catalogside.svg',
          Icon1: 'assets/images/course-catalog-copyside.svg',
          path: "/AssignableContent",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Tasks',
          sideMenutitle: 'Tasks',
          Icon: 'assets/images/qualifications.svg',
          Icon1: 'assets/images/qualifications-copy.svg',
          path: "/Tasks",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'CompanyManagement',
          sideMenutitle: 'Company Management',
          Icon: 'assets/images/company-management.svg',
          Icon1: 'assets/images/company-management-copy.svg',
          path: "/CompanyManagement",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'ExamManagement',
          sideMenutitle: 'Exam Management',
          Icon: 'assets/images/exam-mgmt.svg',
          Icon1: 'assets/images/exam-mgmt-copy.svg',
          path: "/ExamManagement",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Announcements',
          sideMenutitle: 'Announcements',
          Icon: 'assets/images/announcement.svg',
          Icon1: 'assets/images/announcement-copy.svg',
          path: "/Announcements",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Reports',
          sideMenutitle: 'Reports',
          Icon: 'assets/images/reports.svg',
          Icon1: 'assets/images/reports-copy.svg',
          path: "/Reports",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Help',
          sideMenutitle: 'Help',
          Icon: 'assets/images/help.svg',
          Icon1: 'assets/images/help-copy.svg',
          path: "/user1",
          Showdata: true,
          SubMenu: []

        },
      ];

      let p = this.SystemAdministratorTabFunctionality(this.appPages);
      return p;
    }
    else if (role === "Primary Administrator") {
      this.appPages = [
        {
          title: 'Home',
          sideMenutitle: 'Home',
          Icon: 'assets/images/home.svg',
          Icon1: 'assets/images/home-copy.svg',
          path: "/Home",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Users',
          sideMenutitle: 'Users',
          Icon: 'assets/images/users.svg',
          Icon1: 'assets/images/users-copy.svg',
          path: "/user",
          Showdata: true,
          SubMenu: []

        },

        {
          title: 'AssignableContent',
          sideMenutitle: 'Assignable Content',
          Icon: 'assets/images/course-catalogside.svg',
          Icon1: 'assets/images/course-catalog-copyside.svg',
          path: "/AssignableContent",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Tasks',
          sideMenutitle: 'Tasks',
          Icon: 'assets/images/qualifications.svg',
          Icon1: 'assets/images/qualifications-copy.svg',
          path: "/Tasks",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'CompanyManagement',
          sideMenutitle: 'Company Management',
          Icon: 'assets/images/company-management.svg',
          Icon1: 'assets/images/company-management-copy.svg',
          path: "/CompanyManagement",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'ExamManagement',
          sideMenutitle: 'Exam Management',
          Icon: 'assets/images/exam-mgmt.svg',
          Icon1: 'assets/images/exam-mgmt-copy.svg',
          path: "/ExamManagement",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Announcements',
          sideMenutitle: 'Announcements',
          Icon: 'assets/images/announcement.svg',
          Icon1: 'assets/images/announcement-copy.svg',
          path: "/Announcements",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Reports',
          sideMenutitle: 'Reports',
          Icon: 'assets/images/reports.svg',
          Icon1: 'assets/images/reports-copy.svg',
          path: "/Reports",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Help',
          sideMenutitle: 'Help',
          Icon: 'assets/images/help.svg',
          Icon1: 'assets/images/help-copy.svg',
          path: "/user1",
          Showdata: true,
          SubMenu: []

        },
      ];

      let p = this.PrimaryAdministratorTabFunctionality(this.appPages);
      return p;
    }
    else if (role === "Company Administrator") {
      this.appPages = [
        {
          title: 'Home',
          sideMenutitle: 'Home',
          Icon: 'assets/images/home.svg',
          Icon1: 'assets/images/home-copy.svg',
          path: "/Home",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Users',
          sideMenutitle: 'Users',
          Icon: 'assets/images/users.svg',
          Icon1: 'assets/images/users-copy.svg',
          path: "/user",
          Showdata: true,
          SubMenu: []

        },

        {
          title: 'AssignableContent',
          sideMenutitle: 'Assignable Content',
          Icon: 'assets/images/course-catalogside.svg',
          Icon1: 'assets/images/course-catalog-copyside.svg',
          path: "/AssignableContent",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Tasks',
          sideMenutitle: 'Tasks',
          Icon: 'assets/images/qualifications.svg',
          Icon1: 'assets/images/qualifications-copy.svg',
          path: "/Tasks",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'CompanyManagement',
          sideMenutitle: 'Company Management',
          Icon: 'assets/images/company-management.svg',
          Icon1: 'assets/images/company-management-copy.svg',
          path: "/CompanyManagement",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'ExamManagement',
          sideMenutitle: 'Exam Management',
          Icon: 'assets/images/exam-mgmt.svg',
          Icon1: 'assets/images/exam-mgmt-copy.svg',
          path: "/ExamManagement",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Announcements',
          sideMenutitle: 'Announcements',
          Icon: 'assets/images/announcement.svg',
          Icon1: 'assets/images/announcement-copy.svg',
          path: "/Announcements",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Reports',
          sideMenutitle: 'Reports',
          Icon: 'assets/images/reports.svg',
          Icon1: 'assets/images/reports-copy.svg',
          path: "/Reports",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Help',
          sideMenutitle: 'Help',
          Icon: 'assets/images/help.svg',
          Icon1: 'assets/images/help-copy.svg',
          path: "/user1",
          Showdata: true,
          SubMenu: []

        },
      ];

      let p = this.CompanyAdministratorTabFunctionality(this.appPages);
      return p;
    }

    else if (role === "Manager") {
      this.appPages = [
        {
          title: 'Home',
          sideMenutitle: 'Home',
          Icon: 'assets/images/home.svg',
          Icon1: 'assets/images/home-copy.svg',
          path: "/Home",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Users',
          sideMenutitle: 'Users',
          Icon: 'assets/images/users.svg',
          Icon1: 'assets/images/users-copy.svg',
          path: "/user",
          Showdata: true,
          SubMenu: []

        },

        {
          title: 'AssignableContent',
          sideMenutitle: 'Assignable Content',
          Icon: 'assets/images/course-catalogside.svg',
          Icon1: 'assets/images/course-catalog-copyside.svg',
          path: "/AssignableContent",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Tasks',
          sideMenutitle: 'Tasks',
          Icon: 'assets/images/qualifications.svg',
          Icon1: 'assets/images/qualifications-copy.svg',
          path: "/Tasks",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'CompanyManagement',
          sideMenutitle: 'Company Management',
          Icon: 'assets/images/company-management.svg',
          Icon1: 'assets/images/company-management-copy.svg',
          path: "/CompanyManagement",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'ExamManagement',
          sideMenutitle: 'Exam Management',
          Icon: 'assets/images/exam-mgmt.svg',
          Icon1: 'assets/images/exam-mgmt-copy.svg',
          path: "/ExamManagement",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Announcements',
          sideMenutitle: 'Announcements',
          Icon: 'assets/images/announcement.svg',
          Icon1: 'assets/images/announcement-copy.svg',
          path: "/Announcements",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Reports',
          sideMenutitle: 'Reports',
          Icon: 'assets/images/reports.svg',
          Icon1: 'assets/images/reports-copy.svg',
          path: "/Reports",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'Help',
          sideMenutitle: 'Help',
          Icon: 'assets/images/help.svg',
          Icon1: 'assets/images/help-copy.svg',
          path: "/user1",
          Showdata: true,
          SubMenu: []

        },
      ];

      let p = this.ManagerTabFunctionality(this.appPages);
      return p;
    }

    else if (role === "Proctor") {
      this.appPages = [
        {
          title: 'Home',
          sideMenutitle: 'Home',
          Icon: 'assets/images/home.svg',
          Icon1: 'assets/images/home-copy.svg',
          path: "/ProctorHome",
          Showdata: true,
          SubMenu: []

        },
        {
          title: 'CourseList',
          sideMenutitle: 'Course List',
          Icon: 'assets/images/qualifications.svg',
          Icon1: 'assets/images/group-28.svg',
          path: "/Proctor",
          Showdata: true,
          SubMenu: []

        },
      ]
      let p = this.ProctorTabFunctionality(this.appPages);
      return p;
  }
  else if (role === "Evaluator") {
    this.appPages = [
      {
        title: 'Home',
        sideMenutitle: 'Home',
        Icon: 'assets/images/home.svg',
        Icon1: 'assets/images/home-copy.svg',
        path: "/Home",
        Showdata: true,
        SubMenu: []

      },
      {
        title: 'AssignedUser',
        sideMenutitle: 'Assigned User',
        Icon: 'assets/images/users.svg',
        Icon1: 'assets/images/users-copy.svg',
        path: "/AssignedUser",
        Showdata: true,
        SubMenu: []

      },
    ]
    let p = this.EvaluatorTabFunctionality(this.appPages);
    return p;
}
}

// Tab dynamic Hide/show functionality

  SystemAdministratorTabFunctionality(appPages) {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
  //  console.log(Permissiongroupdata)
    if (Object.keys(Permissiongroupdata["rolesPermissions"]["System Administrator"]).length != 0) {
      this.HOME_MENU =  Permissiongroupdata["rolesPermissions"]["System Administrator"]["HOMEADMIN_MENU"];;
      this.USERS_MENU = Permissiongroupdata["rolesPermissions"]["System Administrator"]["USERS_MENU"];
      this.AssignableContent_Menu = Permissiongroupdata["rolesPermissions"]["System Administrator"]["A_C_MENU"];
      this.TASK_MENU = Permissiongroupdata["rolesPermissions"]["System Administrator"]["TASK_MENU"];
      this.COMPANY_MENU = Permissiongroupdata["rolesPermissions"]["System Administrator"]["COMPANY_MENU"];
      this.ExamManagement_MENU =Permissiongroupdata["rolesPermissions"]["System Administrator"]["EXAMMANAGEMENT_MENU"];
      this.Announcements_MENU = Permissiongroupdata["rolesPermissions"]["System Administrator"]["ANNOUNCEMENTS_MENU"];
      this.Reports_MENU = Permissiongroupdata["rolesPermissions"]["System Administrator"]["REPORTS_MENU"];
      this.Help_MENU = Permissiongroupdata["rolesPermissions"]["System Administrator"]["HELP_MENU"];
    }
    else {
      this.HOME_MENU = true;
      this.USERS_MENU = true;
      this.AssignableContent_Menu = true;
      this.TASK_MENU = true;
      this.COMPANY_MENU = true;
      this.ExamManagement_MENU = true;
      this.Announcements_MENU = true;
      this.Reports_MENU = true;
      this.Help_MENU = true;
    }

    // Home Menu
    if (this.HOME_MENU === true) {
      appPages[0].Showdata = true;
    } else {
      appPages[0].Showdata = false;
    }

    // Users Menu
    if (this.USERS_MENU === true) {
      appPages[1].Showdata = true;
    } else {
      appPages[1].Showdata = false;
    }

    // Assignable content Manu
    if (this.AssignableContent_Menu === true) {
      appPages[2].Showdata = true;
    } else {
      appPages[2].Showdata = false;
    }

    // Task Menu
    if (this.TASK_MENU === true) {
      appPages[3].Showdata = true;
    } else {
      appPages[3].Showdata = false;
    }

    // Company Management Menu
    if (this.COMPANY_MENU === true) {
      appPages[4].Showdata = true;
    } else {
      appPages[4].Showdata = false;
    }

    // Exam Management Menu
    if (this.ExamManagement_MENU === true) {
      appPages[5].Showdata = true;
    } else {
      appPages[5].Showdata = false;
    }

    // Announcement Menu
    if (this.Announcements_MENU === true) {
      appPages[6].Showdata = true;
    } else {
      appPages[6].Showdata = false;
    }

    // Reports Menu
    if (this.Reports_MENU === true) {
      appPages[7].Showdata = true;
    } else {
      appPages[7].Showdata = false;
    }

    // Help Memu
    if (this.Help_MENU === true) {
      appPages[8].Showdata = true;
    } else {
      appPages[8].Showdata = false;
    }

    return appPages;

  }

  PrimaryAdministratorTabFunctionality(appPages) {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
   // console.log(Permissiongroupdata)
    if (Object.keys(Permissiongroupdata["rolesPermissions"]["Primary Administrator"]).length != 0) {
      this.HOME_MENU =  Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["HOMEADMIN_MENU"];;
      this.USERS_MENU = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["USERS_MENU"];
      this.AssignableContent_Menu = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["A_C_MENU"];
      this.TASK_MENU = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["TASK_MENU"];
      this.COMPANY_MENU = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["COMPANY_MENU"];
      this.ExamManagement_MENU =Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["EXAMMANAGEMENT_MENU"];
      this.Announcements_MENU = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["ANNOUNCEMENTS_MENU"];
      this.Reports_MENU = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["REPORTS_MENU"];
      this.Help_MENU = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["HELP_MENU"];
    }
    else {
      this.HOME_MENU = false;
      this.USERS_MENU = false;
      this.AssignableContent_Menu = false;
      this.TASK_MENU = false;
      this.COMPANY_MENU = false;
      this.ExamManagement_MENU = false;
      this.Announcements_MENU = false;
      this.Reports_MENU = false;
      this.Help_MENU = false;
    }
    // Home Menu
    if (this.HOME_MENU === true) {
      appPages[0].Showdata = true;
    } else {
      appPages[0].Showdata = false;
    }

    // Users Menu
    if (this.USERS_MENU === true) {
      appPages[1].Showdata = true;
    } else {
      appPages[1].Showdata = false;
    }

    // Assignable content Manu
    if (this.AssignableContent_Menu === true) {
      appPages[2].Showdata = true;
    } else {
      appPages[2].Showdata = false;
    }

    // Task Menu
    if (this.TASK_MENU === true) {
      appPages[3].Showdata = true;
    } else {
      appPages[3].Showdata = false;
    }

    // Company Management Menu
    if (this.COMPANY_MENU === true) {
      appPages[4].Showdata = true;
    } else {
      appPages[4].Showdata = false;
    }

    // Exam Management Menu
    if (this.ExamManagement_MENU === true) {
      appPages[5].Showdata = true;
    } else {
      appPages[5].Showdata = false;
    }

    // Announcement Menu
    if (this.Announcements_MENU === true) {
      appPages[6].Showdata = true;
    } else {
      appPages[6].Showdata = false;
    }

    // Reports Menu
    if (this.Reports_MENU === true) {
      appPages[7].Showdata = true;
    } else {
      appPages[7].Showdata = false;
    }

    // Help Memu
    if (this.Help_MENU === true) {
      appPages[8].Showdata = true;
    } else {
      appPages[8].Showdata = false;
    }

    return appPages;

  }

  CompanyAdministratorTabFunctionality(appPages) {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
  //  console.log(Permissiongroupdata)
    if (Object.keys(Permissiongroupdata["rolesPermissions"]["Company Administrator"]).length != 0) {
      this.HOME_MENU =  Permissiongroupdata["rolesPermissions"]["Company Administrator"]["HOMEADMIN_MENU"];;
      this.USERS_MENU = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["USERS_MENU"];
      this.AssignableContent_Menu = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["A_C_MENU"];
      this.TASK_MENU = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["TASK_MENU"];
      this.COMPANY_MENU = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["COMPANY_MENU"];
      this.ExamManagement_MENU =Permissiongroupdata["rolesPermissions"]["Company Administrator"]["EXAMMANAGEMENT_MENU"];
      this.Announcements_MENU = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["ANNOUNCEMENTS_MENU"];
      this.Reports_MENU = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["REPORTS_MENU"];
      this.Help_MENU = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["HELP_MENU"];
    }
    else {
      this.HOME_MENU = false;
      this.USERS_MENU = false;
      this.AssignableContent_Menu = false;
      this.TASK_MENU = false;
      this.COMPANY_MENU = false;
      this.ExamManagement_MENU = false;
      this.Announcements_MENU = false;
      this.Reports_MENU = false;
      this.Help_MENU = false;
    }
    // Home Menu
    if (this.HOME_MENU === true) {
      appPages[0].Showdata = true;
    } else {
      appPages[0].Showdata = false;
    }

    // Users Menu
    if (this.USERS_MENU === true) {
      appPages[1].Showdata = true;
    } else {
      appPages[1].Showdata = false;
    }

    // Assignable content Manu
    if (this.AssignableContent_Menu === true) {
      appPages[2].Showdata = true;
    } else {
      appPages[2].Showdata = false;
    }

    // Task Menu
    if (this.TASK_MENU === true) {
      appPages[3].Showdata = true;
    } else {
      appPages[3].Showdata = false;
    }

    // Company Management Menu
    if (this.COMPANY_MENU === true) {
      appPages[4].Showdata = true;
    } else {
      appPages[4].Showdata = false;
    }

    // Exam Management Menu
    if (this.ExamManagement_MENU === true) {
      appPages[5].Showdata = true;
    } else {
      appPages[5].Showdata = false;
    }

    // Announcement Menu
    if (this.Announcements_MENU === true) {
      appPages[6].Showdata = true;
    } else {
      appPages[6].Showdata = false;
    }

    // Reports Menu
    if (this.Reports_MENU === true) {
      appPages[7].Showdata = true;
    } else {
      appPages[7].Showdata = false;
    }

    // Help Memu
    if (this.Help_MENU === true) {
      appPages[8].Showdata = true;
    } else {
      appPages[8].Showdata = false;
    }

    return appPages;

  }

  ManagerTabFunctionality(appPages) {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
    //console.log(Permissiongroupdata)
    if (Object.keys(Permissiongroupdata["rolesPermissions"]["Manager"]).length != 0) {
      this.HOME_MENU = Permissiongroupdata["rolesPermissions"]["Manager"]["HOMEADMIN_MENU"];
      this.USERS_MENU = Permissiongroupdata["rolesPermissions"]["Manager"]["USERS_MENU"];
      this.AssignableContent_Menu = Permissiongroupdata["rolesPermissions"]["Manager"]["A_C_MENU"];
      this.TASK_MENU = Permissiongroupdata["rolesPermissions"]["Manager"]["TASK_MENU"];
      this.COMPANY_MENU = Permissiongroupdata["rolesPermissions"]["Manager"]["COMPANY_MENU"];
      this.ExamManagement_MENU =Permissiongroupdata["rolesPermissions"]["Manager"]["EXAMMANAGEMENT_MENU"];
      this.Announcements_MENU = Permissiongroupdata["rolesPermissions"]["Manager"]["ANNOUNCEMENTS_MENU"];
      this.Reports_MENU = Permissiongroupdata["rolesPermissions"]["Manager"]["REPORTS_MENU"];
      this.Help_MENU = Permissiongroupdata["rolesPermissions"]["Manager"]["HELP_MENU"];
    }
    else {
      this.HOME_MENU = false;
      this.USERS_MENU = false;
      this.AssignableContent_Menu = false;
      this.TASK_MENU = false;
      this.COMPANY_MENU = false;
      this.ExamManagement_MENU = false;
      this.Announcements_MENU = false;
      this.Reports_MENU = false;
      this.Help_MENU = false;
    }
    // Home Menu
    if (this.HOME_MENU === true) {
      appPages[0].Showdata = true;
    } else {
      appPages[0].Showdata = false;
    }

    // Users Menu
    if (this.USERS_MENU === true) {
      appPages[1].Showdata = true;
    } else {
      appPages[1].Showdata = false;
    }

    // Assignable content Manu
    if (this.AssignableContent_Menu === true) {
      appPages[2].Showdata = true;
    } else {
      appPages[2].Showdata = false;
    }

    // Task Menu
    if (this.TASK_MENU === true) {
      appPages[3].Showdata = true;
    } else {
      appPages[3].Showdata = false;
    }

    // Company Management Menu
    if (this.COMPANY_MENU === true) {
      appPages[4].Showdata = true;
    } else {
      appPages[4].Showdata = false;
    }

    // Exam Management Menu
    if (this.ExamManagement_MENU === true) {
      appPages[5].Showdata = true;
    } else {
      appPages[5].Showdata = false;
    }

    // Announcement Menu
    if (this.Announcements_MENU === true) {
      appPages[6].Showdata = true;
    } else {
      appPages[6].Showdata = false;
    }

    // Reports Menu
    if (this.Reports_MENU === true) {
      appPages[7].Showdata = true;
    } else {
      appPages[7].Showdata = false;
    }

    // Help Memu
    if (this.Help_MENU === true) {
      appPages[8].Showdata = true;
    } else {
      appPages[8].Showdata = false;
    }

    return appPages;

  }

  LearnerTabFunctionality(appPages) {

    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
   // console.log(Permissiongroupdata)
    if (Object.keys(Permissiongroupdata["rolesPermissions"]["Learner"]).length != 0) {
      this.LearnerHome = Permissiongroupdata["rolesPermissions"]["Learner"]["HOMELEARNER_MENU"];
      this.LearnerAssignment =  Permissiongroupdata["rolesPermissions"]["Learner"]["ASSIGNMENTS_MENU"];
      this.LearnerTask = Permissiongroupdata["rolesPermissions"]["Learner"]["TASKLEARNER_MENU"];
      this.LearnerTranscripts = Permissiongroupdata["rolesPermissions"]["Learner"]["TRANSCRIPTS_MENU"];
      this.LearnerCalendar = Permissiongroupdata["rolesPermissions"]["Learner"]["ASSIGNMENTS_CALENDAR_TAB"];

    }
    else {
      this.LearnerHome = true;
      this.LearnerAssignment = true;
      this.LearnerTask = true;
      this.LearnerTranscripts = true;
      this.LearnerCalendar = true;

    }
    // Learner Home
    if (this.LearnerHome === true) {
      appPages[0].Showdata = true;
    } else {
      appPages[0].Showdata = false;
    }

    // Learner Assignment
    if (this.LearnerAssignment === true) {
      appPages[1].Showdata = true;
    } else {
      appPages[1].Showdata = false;
    }

    // Learner Task
    if (this.LearnerTask === true) {
      appPages[2].Showdata = true;
    } else {
      appPages[2].Showdata = false;
    }

    // Learner Task
    if (this.LearnerTranscripts === true) {
      appPages[3].Showdata = true;
    } else {
      appPages[3].Showdata = false;
    }

    // Learner Calender
    if (this.LearnerCalendar === true) {
      appPages[4].Showdata = true;
    } else {
      appPages[4].Showdata = false;
    }
    return appPages;
  }

  ProctorTabFunctionality(appPages) {

    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
   // console.log(Permissiongroupdata)
    if (Object.keys(Permissiongroupdata["rolesPermissions"]["Proctor"]).length != 0) {
      this.ProctorHome = Permissiongroupdata["rolesPermissions"]["Proctor"]["COURSE_LIST_REQUIREMENT_EDIT"];
      this.CourseList = Permissiongroupdata["rolesPermissions"]["Proctor"]["COURSE_LIST_MENU"];
    }
    else {
      this.ProctorHome = false;
      this.CourseList = false

    }
    // Proctor Home
    if (this.ProctorHome === true) {
      appPages[0].Showdata = true;
    } else {
      appPages[0].Showdata = false;
    }

    // Courselist
    if (this.CourseList === true) {
      appPages[1].Showdata = true;
    } else {
      appPages[1].Showdata = false;
    }


    return appPages;
  }

  EvaluatorTabFunctionality(appPages) {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
    if (Object.keys(Permissiongroupdata["rolesPermissions"]["Evaluator"]).length != 0) {
      this.EvaluatorHome = Permissiongroupdata["rolesPermissions"]["Evaluator"]["ASSIGNED_USER_LIST_FILTER"];
      this.AssignedUser = Permissiongroupdata["rolesPermissions"]["Evaluator"]["ASSIGNED_USER_MENU"];
    }
    else {
      this.EvaluatorHome = false//need to be false
      this.AssignedUser = false;//need to be false

    }
    // Evaluator Home
    if (this.EvaluatorHome === true) {
      appPages[0].Showdata = true;
    } else {
      appPages[0].Showdata = false;
    }

    // Assigned User
    if (this.AssignedUser === true) {
      appPages[1].Showdata = true;
    } else {
      appPages[1].Showdata = false;
    }


    return appPages;
  }
}
