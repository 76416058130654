<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Company ID</mat-label>
        <!-- <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID0 (change)="inputonchangeIDO(inpID0.value)" (keyup)="inputchangeID0(inpID0.value)" class="textinputmedium"
            formControlName="CompanyID">
        </mat-form-field> -->

        <mat-form-field appearance="outline"  class="formfieldusertable">
          <mat-select formControlName="CompanyID" placeholder="Company (COID)" class="inputClass" panelClass="myPanelmatselectClassCOID"
            (selectionChange)=selectCompanyID($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of companylistdata" [value]="item.companyName + ' ' +'('+ item.companyId + ')'">
              <div class="matoptiontxt">{{item.companyName}}&nbsp;({{item.companyId}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div class="col-12 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Groups</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Group" placeholder="Groups" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectGroupName($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of groupslist" [value]="item.groupName">
              <div class="matoptiontxt">{{item.groupName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

     <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID1 (keyup)="inputchangeID1(inpID1.value)" class="textinputmedium"
            formControlName="Name">
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Login</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID2 (keyup)="inputchangeID2(inpID2.value)" class="textinputmedium"
            formControlName="Login">
        </mat-form-field>

      </div>



      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Email ID</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID3 (keyup)="inputchangeID3(inpID3.value)" class="textinputmedium"
            formControlName="EmailID">

            <mat-error *ngIf="filterform.get('EmailID').hasError('pattern')" class="mat-error">
              Please enter a valid Email ID
            </mat-error>

        </mat-form-field>
      </div>


      <div class="col-12 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Status" placeholder="Status" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectStatusName($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of statuslist" [value]="item.statusName">
              <div class="matoptiontxt">{{item.statusName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

   </div>
</form>

<div class="row">
  <div class="col-6 cancelbtncol"><br><br>
    <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      Cancel
    </button>
  </div>
  <div class="col-6 Filterbtncol"><br><br>
    <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Filter
    </button>
  </div>
</div>


  </div>
