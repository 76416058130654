import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-addremovetasknamelistfilter',
  templateUrl: './addremovetasknamelistfilter.component.html',
  styleUrls: ['./addremovetasknamelistfilter.component.scss']
})
export class AddremovetasknamelistfilterComponent implements OnInit {
  sel_data: any = [];
  backup_data: any = [];
  user_data: any = [];
  selected_users = [];
  backup_users: any = [];
  rem_data: any = [];
  loadspinner: boolean;
  AlluserList: any = [];
  companyId: any;
  Login: any;
  username: any;
  loginId: string;
  sel_user_array = [];
  temp_group_data: any = [];
  filter_companyid: any = '';
  usersListData: any;
  taskId: any;
  requirement_selectall_right = false;
  req_selectall = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, public dialog: MatDialogRef<UserComponent>, private formBuilder: FormBuilder, public _Service: ApiServiceService,
    private apiService: ApiServiceService) {
    this.username = data['Name'];
    this.taskId = data['taskId'];
    this.Login = data['Login'];

    this.loginId = localStorage.getItem('loginId');


    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'task';
    const endpoint2 = 'existinguserstask/' + this.taskId;


    this.apiService.Addremoveget(endpoint1, endpoint2, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.usersListData = response['existingUsers'];

        if (this.usersListData != "" && this.usersListData != undefined && this.usersListData != null) {
          for (var i = 0; i < this.usersListData.length; i++) {
            this.usersListData[i].isSelected = false;
          }
        }
        this.selected_users = this.usersListData;
        console.log(this.selected_users)
        this.backup_users = this.selected_users;
        this.listUserApi();
      },
        (err) => {
          this.loadspinner = false;
          // console.log(err)

        });



  }

  ngOnInit() {

  }
  close() {

    this.dialog.close();
    let element = document.getElementById("user-content")
    element.classList.remove('blur-content');

  }

  listUserApi() {
    let compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body
    if (cacheddata === "System Administrator") {
      body = {
        "filters": {
          "firstName": "",
          "lastName": "",
          "loginName": "",
          "displayName": "",
          "email": "",
          "companyId": "",
          "empCode": "",
          "jobPositionName": "",
          "groupName": "",
          "roleName": "",
          "managerName": "",
          "statusName": ""
        }
      }
    }
    else {
      body = {
        "filters": {
          "firstName": "",
          "lastName": "",
          "loginName": "",
          "displayName": "",
          "email": "",
          "companyId": compId,
          "empCode": "",
          "jobPositionName": "",
          "groupName": "",
          "roleName": "",
          "managerName": "",
          "statusName": ""
        }
      }
    }
    const endpoint1 = 'users';
    const endpoint2 = 'searchusersfilter';


    this.apiService.manager_filterpost(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        let temp_data = response["userListFilter"];
        console.log(temp_data)
        for (var i = 0; i < temp_data.length; i++) {
          temp_data[i].isSelected = false;
        }
        for (var i = 0; i < temp_data.length; i++) {
          for (var j = 0; j < this.selected_users.length; j++) {
            if (temp_data[i].loginName == this.selected_users[j].loginName) {
              temp_data[i].isSelected = true;
            }
          }
        }
        temp_data = temp_data.filter(x => x.isSelected != true);

        this.user_data = temp_data;
        var setValue = this.user_data;
        // console.log(this.user_data, this.username)
        // const dataRemoved = setValue.filter((el) => {
        //   return el.displayName !==  this.username;
        // });
        //console.log(dataRemoved);
        //   this.user_data= dataRemoved;
        this.loadspinner = false;
        this.backup_data = this.user_data;

        this.loadspinner = false;
      },
        (err) => {
          this.loadspinner = false;
        });

  }

  create_user() {

    this.loadspinner = true;
    let endpoint = 'task/' + 'addusertask/' + this.taskId;
    let user_list: any = [];
    console.log(this.backup_users)
    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        user_list.push(this.backup_users[i].loginName)
      }
    }

    let body = {

      "usersList": user_list

    }
    this._Service.add_remove_usertask(endpoint, body, 'PATCH')
      .subscribe((response) => {
        this.loadspinner = false;
        if (response["message"] === 'success') {

          // let element1 = document.getElementById("Task-content")
          // element1.classList.add('blur-content')
          // this.dialogopen.open(DialogpopupComponent, {
          let element = document.getElementById("Task-content")
          element.classList.add('blur-content')
          let dialogRef = this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            disableClose: true,
            data:
            {
              from: "popup-success",
              // navigateTo: "edit-user";
              successmsg: "Hurray!!",
              successmsgbody: 'Users list updated successfully ',
              imagelogo: "assets/images/thumbsup.svg"
            },
            position: {
              top: '25vh'
            }
          });
          this.dialog.close('success');
          dialogRef.afterClosed().subscribe(result => {
            element.classList.remove('blur-content')

          })
          // let element2 = document.getElementById("Task-content")
          // element2.classList.remove('blur-content');
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          // this.dialogopen.open(DialogpopupComponent, {
          //   width: '550px',
          //   data: {  from: 'error-popup',
          //   name: data },
          //   position: {
          //     top: '40vh'
          //   }
          // });
        }
      },
        (err) => {
          this.loadspinner = false;
          //  console.log(err)

        });


  }

  search_left(ev) {
    this.user_data = this.backup_data;
    console.log(this.user_data)
    let filterValue = ev;
    this.user_data = this.user_data.filter(option => (option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase())));


    for (var l = 0; l < this.user_data.length; l++) {
      if (this.user_data[l].isSelected === true) {
        this.req_selectall = true;
      }
      else {
        this.req_selectall = false;
        break
      }
    }


  }

  search_right(ev) {

    this.selected_users = this.backup_users;
    let filterValue = ev;
    this.selected_users = this.selected_users.filter(option => (option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase())));

    for (var l = 0; l < this.selected_users.length; l++) {
      if (this.selected_users[l].isSelected === true) {
        this.requirement_selectall_right = true;
      }
      else {
        this.requirement_selectall_right = false;
        break
      }
    }


  }

  itemSelected_leftbox(ev) {
    let instance = this;
    instance.req_selectall = null;
    let present = false;
    for (var k = 0; k < this.sel_data.length; k++) {
      if (ev.loginName == this.sel_data[k].loginName) {
        present = true;
        this.sel_data.splice(k, 1);
      }
    }
    if (!present) {
      this.sel_data.push(ev)
    }

    for (var l = 0; l < this.backup_data.length; l++) {
      if (!present) {
        if (ev.loginName == this.backup_data[l].loginName) {
          this.backup_data[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.backup_data[l].loginName) {
          this.backup_data[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.user_data.length; l++) {
      console.log(present)
      if (!present) {
        if (ev.loginName == this.user_data[l].loginName) {
          this.user_data[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.user_data[l].loginName) {
          this.user_data[l].isSelected = false;
        }
      }
    }
    // console.log(this.user_data.length)
    // console.log(this.sel_data.length)
    // if(this.user_data.length == this.sel_data.length){
    //   this.req_selectall=true;
    // }


    for (var l = 0; l < this.user_data.length; l++) {
      if (this.user_data[l].isSelected === true) {
        this.req_selectall = true;
      }
      else {
        this.req_selectall = false;
        break
      }
    }

  }
  itemSelected_rightbox(ev) {
    this.requirement_selectall_right = false;
    let present = false;
    for (var k = 0; k < this.rem_data.length; k++) {
      if (ev.loginName == this.rem_data[k].loginName) {
        present = true;
        this.rem_data.splice(k, 1);
      }
    }
    if (!present) {
      this.rem_data.push(ev)
    }
    for (var l = 0; l < this.backup_users.length; l++) {
      if (!present) {
        if (ev.loginName == this.backup_users[l].loginName) {
          this.backup_users[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.backup_users[l].loginName) {
          this.backup_users[l].isSelected = false;
        }
      }
    }

    for (var l = 0; l < this.selected_users.length; l++) {
      if (!present) {
        if (ev.loginName == this.selected_users[l].loginName) {
          this.selected_users[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.selected_users[l].loginName) {
          this.selected_users[l].isSelected = false;
        }
      }
    }
    // if(this.selected_users.length == this.rem_data.length){
    //   this.requirement_selectall_right=true;
    // }
    for (var l = 0; l < this.selected_users.length; l++) {
      if (this.selected_users[l].isSelected === true) {
        this.requirement_selectall_right = true;
      }
      else {
        this.requirement_selectall_right = false;
        break
      }
    }
  }

  add_users() {
    this.req_selectall = null;
    this.requirement_selectall_right = null;
    if (this.sel_data.length > 0) {

      for (var j = 0; j < this.backup_data.length; j++) {

        if (this.backup_data[j].isSelected) {
          console.log(this.backup_data[j])
          this.selected_users.push(this.backup_data[j])
          // console.log(this.selected_users)
        }
      }


      this.user_data = this.backup_data;
      this.user_data = this.user_data.filter(x => x.isSelected != true);
      this.sel_data = [];
      for (var m = 0; m < this.backup_data.length; m++) {
        for (var n = 0; n < this.selected_users.length; n++) {
          if (this.selected_users[n].loginName == this.backup_data[m].loginName) {
            this.backup_data[m].isSelected = true;
          }
        }
      }

      this.backup_data = this.backup_data.filter(x => x.isSelected != true);
      this.user_data = this.backup_data;
      for (var j = 0; j < this.selected_users.length; j++) {
        this.selected_users[j].isSelected = false;
      }
      this.backup_users = this.selected_users;
      //setting the serach value
      const name: any = document.getElementById('sid1');
      name.value = "";
    }
  }

  remove_user() {

    if (this.rem_data.length > 0) {
      console.log(this.rem_data)
      let element = document.getElementById("addremovetasknameid")
      element.classList.add('blur-content')
      let dialogRef = this.dialogopen.open(DialogpopupComponent, {
        width: '530px',
        height: '330px',
        disableClose: true,
        data:
        {
          title: 'Are you sure you want to Remove?',
          subtitle: 'By removing the selected users, you will lose the data for the users attempt, Would you like to proceed?',
          imagelogo: "assets/images/create-u-ser.svg",
          from: "confirm-popup"
        },
        backdropClass: 'deletePopup',

        position: {
          top: '27vh'
        }
      });


      dialogRef.afterClosed().subscribe(res => {
        console.log(res)
        let element = document.getElementById("addremovetasknameid")
        element.classList.remove('blur-content');
        if (res === undefined || res === "") {
        }
        else {
          this.req_selectall = null;
          this.requirement_selectall_right = null;
          if (this.rem_data.length > 0) {

            for (var j = 0; j < this.backup_users.length; j++) {
              if (this.backup_users[j].isSelected) {
                this.user_data.push(this.backup_users[j])
              }
            }

            // this.selected_users=this.backup_users;
            // this.selected_users = this.selected_users.filter(x => x.isSelected != true);

            this.backup_users = this.backup_users.filter(x => x.isSelected != true);
            this.selected_users = this.backup_users;

            for (var j = 0; j < this.backup_data.length; j++) {
              this.backup_data[j].isSelected = false
            }

            this.rem_data = [];
            for (var m = 0; m < this.backup_users.length; m++) {
              for (var n = 0; n < this.user_data.length; n++) {
                if (this.user_data[n].loginName == this.backup_users[m].loginName) {
                  this.backup_users[m].isSelected = true;
                }
              }
            }
          }
        }
      })
    }




    // this.backup_users = this.backup_users.filter(x => x.isSelected != true);
    // this.selected_users=this.backup_users;
    // for(var j=0; j<this.user_data.length; j++){
    //   this.user_data[j].isSelected=false;
    // }
    // this.backup_data=this.user_data;
    //   const name:any =  document.getElementById('sid2');
    //   name.value = "";
  }


  reqAll(ev) {
    // this.loadspinner=true;
    if (this.req_selectall == true) {
      this.user_data.forEach(element => {
        element.isSelected = true;
      })
      this.sel_data = [];
      this.backup_data = [];
      this.user_data.forEach(element => {
        this.sel_data.push(element);
        this.backup_data.push(element)

      })

    } else if (this.req_selectall == false) {
      this.user_data.forEach(element => {
        element.isSelected = false;
      })

      this.sel_data = [];
      this.backup_data = this.user_data;
      this.user_data.forEach(element => {
        //this.sel_data.push(element);
      })
    }

  }




  reqAll_right(ev) {
    if (this.requirement_selectall_right == true) {
      this.selected_users.forEach(element => {
        element.isSelected = true;
      })
      this.rem_data = [];
      this.backup_users = [];
      this.selected_users.forEach(element => {
        this.rem_data.push(element);
        this.backup_users.push(element)

      })

    } else if (this.requirement_selectall_right == false) {
      this.selected_users.forEach(element => {
        element.isSelected = false;
      })

      this.rem_data = [];
      this.backup_users = this.selected_users;
      this.selected_users.forEach(element => {
        //this.rem_data.push(element);
        // this.backup_users.push(element)
      })

    }

  }
}


