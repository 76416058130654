<div class="container">

  <div class="row" *ngIf="sequence_data.length>0">
    <div class="col-12 text-center margin">
      <img src="assets/images/info.svg"  width="47" height="47" alt="">
    </div>
    <br>
    <div class="col-12 margin">
      <p class="title1">Sorry, this curriculum requires you to complete the training in sequence. Please complete in the sequence shown below:</p>
    </div>
    <br>
    <div class="col-12 title2">
      Training Sequence
    </div>
    <div class="col-12 list-data" *ngFor="let data of sequence_data">
{{data.title}}
    </div>
    <div class="col-12">
        <button mat-button  class="Filterbtn" (click)="cancel()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          OK</button>
      </div>
   
  </div>

  <div class="row" *ngIf="sequence_data.length<=0">
    <div class="col-12 text-center margin">
      <img src="assets/images/info.svg"  width="47" height="47" alt="">
    </div>
    <br>
    <div class="col-12 margin">
      <p class="title1">{{show_msg}}</p>
    </div>
    <br>
   
    <div class="col-12">
        <button mat-button  class="Filterbtn" (click)="cancel()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          OK</button>
      </div>
   
  </div>
 
</div>


