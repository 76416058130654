import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-tasklistviewfilter',
  templateUrl: './tasklistviewfilter.component.html',
  styleUrls: ['./tasklistviewfilter.component.scss'],
  providers: [DatePipe]
})
export class TasklistviewfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  categorylist: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  today = new Date();
  enddateconsistnull:any;
  typelist=["KNT","TNG", "PEF"];
  statuslist=["Qualified", "Not Qualified","In Progress", "Completed", "Passed", "Not Started"];
  loginid:any='';
  taskid:any='';
  enddateconsistnullApproveddenied:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private datePipe: DatePipe, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<CompanyManagementComponent>) {
    this.loginid=data['loginname']
    this.taskid=data['taskid']
    this.filterform = this.formBuilder.group({
      Name: [''],
      Type: [''],
      Status: [''],
      Completionstart: [''],
      Completionend: [''],
      Expiredstart: [''],
      Expiredend: ['']
    });
  
   this.setdefaultdropdownvalues();
  }

  ngOnInit(): void {
  }

  
  setdefaultdropdownvalues() {
    
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      

      // let filterdata = {
      //   "Title": this.filterform.value.Name.trim(),
      //   "Start Date": daterange,
      //   "End Date": daterangeaexpired,
      //   "Type": this.filterform.value.Type.trim(),
      //   "Status": this.filterform.value.Status.trim(),
      // }
      this.disablebutton=false;
     
      let data = JSON.parse(cacheddata);
      if(data["Completion Date"] != '' && data["Completion Date"] != null && data["Completion Date"] != undefined){
        let d1=data["Completion Date"].split('-');
        let dd1=this.datePipe.transform(d1[0], 'yyyy-MM-dd');
        let dd2=this.datePipe.transform(d1[1], 'yyyy-MM-dd');
        this.filterform.controls['Completionstart'].setValue(dd1);
        this.filterform.controls['Completionend'].setValue(dd2);
        }
      if(data["Expiration Date"] != '' && data["Expiration Date"] != null && data["Expiration Date"] != undefined){
        let d2=data["Expiration Date"].split('-');
        let ddd1=this.datePipe.transform(d2[0], 'yyyy-MM-dd');
        let ddd2=this.datePipe.transform(d2[1], 'yyyy-MM-dd');
        this.filterform.controls['Expiredstart'].setValue(ddd1);
        this.filterform.controls['Expiredend'].setValue(ddd2);
      }
      this.filterform.controls['Name'].setValue(data["Name"]);
      this.filterform.controls['Type'].setValue(data["Type"]);
      this.filterform.controls['Status'].setValue(data["Status"]);
      this.clearfilterfunction();
      this.dynamicEnableDisablebtn();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
       if (clearfilteritemkey[i] === "Name") {
          this.filterform.controls['Name'].setValue("");
        }

        else if (clearfilteritemkey[i] === "Completion Date") {
          this.filterform.controls['Completionstart'].setValue("");
          this.filterform.controls['Completionend'].setValue("");
        }

        else if (clearfilteritemkey[i] === "Expiration Date") {
          this.filterform.controls['Expiredstart'].setValue("");
          this.filterform.controls['Expiredend'].setValue("");
        }

        else if (clearfilteritemkey[i] === "Type") {
          this.filterform.controls['Type'].setValue("");
        }

        else if (clearfilteritemkey[i] === "Status") {
          this.filterform.controls['Status'].setValue("");
        }

        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }



  inputchangeID0(value) {
    this.dynamicEnableDisablebtn()
  }

  startChange(event) {
    console.log("startChange", event.value)
    //console.log(this.filterform.value.end);
  }

  endChange(event) {
    console.log("endChange", event.value);
    if (event.value === null) {
      this.disablebutton = true;
      this.enddateconsistnull=true;
    }
    else {
      this.enddateconsistnull=false;
      this.dynamicEnableDisablebtn();
    }
    //
  }

  startChangeApproveddenied(event) {
    console.log("startChange", event.value)
    //console.log(this.filterform.value.end);
  }

  endChangeApproveddenied(event) {
    console.log("endChange", event.value);
    if (event.value === null) {
      this.disablebutton = true;
      this.enddateconsistnullApproveddenied=true;
    }
    else {
      this.enddateconsistnullApproveddenied=false;
      this.dynamicEnableDisablebtn();
    }
    //
  }

  selectStatusName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
    }
    else {
      this.disablebutton = false;
    }
    this.dynamicEnableDisablebtn();
  }

  selectTypeName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Type'].setValue("");
    }
    else {
      this.disablebutton = false;
    }
    this.dynamicEnableDisablebtn();
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }



  EnterSubmit(event) {
    if (event.keyCode === 13) {
      //this.Filter();
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  getbodycontent() {
    
    let daterange = "";
    let Completionstart = "";
    let Completionend = "";
    let apicompletionstartdate = "";
    let apicompletionenddate = "";

    let Expiredstart = "";
    let Expiredend = "";
    let apiExpiredstart = "";
    let apiExpiredend = "";
    let daterangeaexpired = "";


    // start date
    if (this.filterform.value.Completionstart === "" || this.filterform.value.Completionstart === undefined || this.filterform.value.Completionstart === null) {
      daterange = "";
    }
    else {
      Completionstart = this.datePipe.transform(this.filterform.value.Completionstart, 'MM/dd/yyyy');
      Completionend = this.datePipe.transform(this.filterform.value.Completionend, 'MM/dd/yyyy');
      daterange = Completionstart + " - " + Completionend;

      apicompletionstartdate = this.datePipe.transform(this.filterform.value.Completionstart, 'yyyy-MM-dd');
      apicompletionenddate = this.datePipe.transform(this.filterform.value.Completionend, 'yyyy-MM-dd');
    }

    // end date
    if (this.filterform.value.Expiredstart === "" || this.filterform.value.Expiredstart === undefined || this.filterform.value.Expiredstart === null) {
      daterangeaexpired = "";
    }
    else {
      Expiredstart = this.datePipe.transform(this.filterform.value.Expiredstart, 'MM/dd/yyyy');
      Expiredend = this.datePipe.transform(this.filterform.value.Expiredend, 'MM/dd/yyyy');
      daterangeaexpired = Expiredstart + " - " + Expiredend;

      apiExpiredstart = this.datePipe.transform(this.filterform.value.Expiredstart, 'yyyy-MM-dd');
      apiExpiredend = this.datePipe.transform(this.filterform.value.Expiredend, 'yyyy-MM-dd');
    }

    let filterdata = {
      "Name": this.filterform.value.Name.trim(),
      "Completion Date": daterange,
      "Expiration Date": daterangeaexpired,
      "Type": this.filterform.value.Type.trim(),
      "Status": this.filterform.value.Status.trim(),
    }

    this.getapibodycontent(apicompletionstartdate, apicompletionenddate, apiExpiredstart, apiExpiredend);
    return filterdata

  }

  getapibodycontent(apicompletionstartdate, apicompletionenddate, apiExpiredstart, apiExpiredend) {

    this.apibodycontent = {
      "loginName":this.loginid,
      "taskId":this.taskid,
       "courseName":this.filterform.value.Name.trim(),
       "type":this.filterform.value.Type.trim(),
       "courseStatus":this.filterform.value.Status.trim(),
       "completionDate":"",
       "expirationDate":"",
       "completedDateBegin":apicompletionstartdate,
       "completedDateEnd":apicompletionenddate,
       "expirationDateBegin":apiExpiredstart,
       "expirationDateEnd":apiExpiredend
    }
  }

  checkformvalue() {
    let formempty;
    if ((this.filterform.value.Name.trim() === "") &&
     (this.filterform.value.Expiredstart === "" || this.filterform.value.Expiredstart === null) &&
      (this.filterform.value.Expiredend === "" || this.filterform.value.Expiredend === null) && (this.filterform.value.Type.trim()==="") && (this.filterform.value.Status.trim()==="") &&
      (this.filterform.value.Completionstart === "" || this.filterform.value.Completionstart === null) &&
      (this.filterform.value.Completionend === "" || this.filterform.value.Completionend === null) ) {
      formempty = true;

    }
    else {
      formempty = false;
      if(this.enddateconsistnull===true || this.enddateconsistnullApproveddenied===true){
        formempty = true;
      }

    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

}
