import { Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AssignableContentComponent } from 'src/app/Administrator/assignable-content/assignable-content.component';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
import { MatTableDataSource } from '@angular/material/table';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-reportschedule',
  templateUrl: './reportschedule.component.html',
  styleUrls: ['./reportschedule.component.scss']
})
export class ReportscheduleComponent implements OnInit {

  sel_data:any=[];
  backup_data:any=[];
  user_data:any=[];
  selected_users=[];
  temp_selected_user=[];
  temp_backup_users=[];
  backup_users:any=[];
  rem_data:any=[];
  loadspinner: boolean;
  AlluserList:any=[];
  companyId: any;
  Login: any;
  username: any;
  loginId: string;
  sel_user_array=[];
  temp_group_data:any=[];
  filter_companyid:any='';
  usersListData: any;
  taskId: any;
  requirement_selectall_right=false;
  req_selectall=false;
  compId: string;
  examId: any;
  totalitemlength: any;
  courseId: any;
  title: any;
  companyId1: any;
  pagesize: any;
  sortby: any;
  sortOrder: any;
  ExternalELEMENT_DATA: any = [];
  lastName: string;
  company_list:any=[];
  ids:any=[]
  from:any='';
  owncompany:any=[];
  reportname:any='';
  report_id='';
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, public dialog: MatDialogRef<AssignableContentComponent>, private formBuilder: FormBuilder, public _Service: ApiServiceService,) {
   // this.courseId = data['courseId'];
   
    this.loginId = localStorage.getItem('loginId');
    this.report_id=data['reportid'];
    this.reportname=data['reportname']
  }

  ngOnInit(): void {
    this.sel_user_list();
  }

  sel_user_list(){
   // this.listUsers();
    this.loadspinner = true;
      const endpoint1 = 'reports/viewreport/'+this.report_id;
     
      this._Service.get_report_users(endpoint1,'GET')
        .subscribe((response) => {
          
          this.loadspinner = false;
          let temp_data: any = response['data'];
         
           
          this.selected_users=temp_data;
          this.backup_users =  this.selected_users;
          this.listUsers();
        },
        (err) => {
          this.loadspinner = false;
        });
  }
  
    listUsers(){
  
      let compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body
    if (cacheddata === "System Administrator") {
      body = {
        "filters": {
          "firstName": "",
          "lastName": "",
          "loginName":"",
          "displayName": "",
          "email":"",
          "companyId": '',
          "empCode": "",
          "jobPositionName": "",
          "groupName": "",
          "roleName":"",
          "managerName":"",
          "statusName":"Active"
          }
          }
        }else{
          body = {
            "filters": {
              "firstName": "",
              "lastName": "",
              "loginName":"",
              "displayName": "",
              "email":"",
              "companyId": compId,
              "empCode": "",
              "jobPositionName": "",
              "groupName": "",
              "roleName":"",
              "managerName":"",
              "statusName":"Active"
              }
              }
        }
      this.loadspinner = true;
      const endpoint1 = 'users';
      const endpoint2 = 'searchusersfilter';
    
      
    
          this._Service.manager_filterpost(endpoint1, endpoint2, body, 'POST')
          .subscribe((response) => {
          this.loadspinner = false;
         let temp_data:any = response["userListFilter"];
      for(var i=0; i<temp_data.length; i++){
        temp_data[i].isSelected=false;
      }
      for(var i=0; i<temp_data.length; i++){
        for(var j=0; j<this.selected_users.length; j++){
          if(temp_data[i].loginName==this.selected_users[j].loginName){
             temp_data[i].isSelected=true;
           }
      }
     }
     temp_data = temp_data.filter(x => x.isSelected != true);
    
      this.user_data=temp_data;
      var setValue = this.user_data;
     // console.log(this.user_data, this.username)
      const dataRemoved = setValue.filter((el) => {
        return el.displayName !==  this.lastName +"," + this.lastName;
      });
      //console.log(dataRemoved);
      this.user_data= dataRemoved;
     this.loadspinner = false;
      this.backup_data=this.user_data;
    
      this.loadspinner = false;
    },
    (err) => {
      this.loadspinner = false;
    });
    }
  

  listcompanies1(){
    this.loadspinner = true;
      const endpoint1 = 'lookup';
      this._Service.Simpleget(endpoint1, 'GET')
        .subscribe((response) => {
          
          let temp_data: any = response["companies"];
          for (var i = 0; i < temp_data.length; i++) {
            temp_data[i].isSelected = false;
          }
          this.user_data = temp_data;
          this.backup_data = this.user_data;
          this.loadspinner = false;

        },
        (err) => {
          this.loadspinner = false;
          //  console.log(err)

        });
  }

  close() {

    this.dialog.close();
    let element = document.getElementById("assign-content")
    element.classList.remove('blur-content');

  }
  



  search_left(ev){
  
    this.user_data=this.backup_data;
    let filterValue=ev;
    this.user_data= this.user_data.filter(option => ( option.firstName.toLowerCase().includes(filterValue.toLowerCase()) ||  option.lastName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase())  ));   }

     search_right(ev){
  
      this.selected_users=this.backup_users;
      let filterValue=ev;
      this.selected_users= this.selected_users.filter(option =>( option.firstName.toLowerCase().includes(filterValue.toLowerCase()) ||  option.lastName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase())  )); 
    }
  
  itemSelected_leftbox(ev){
    
    let instance=this;
    instance.req_selectall=null;
    let present=false;
    for(var k=0; k<this.sel_data.length; k++){
      if(ev.loginName==this.sel_data[k].loginName){
      present=true;
      this.sel_data.splice(k, 1);
    }
  }
  if(!present){
    this.sel_data.push(ev)
  }

  for(var l=0; l<this.backup_data.length; l++){
    if(!present){
    if(ev.loginName == this.backup_data[l].loginName){
      this.backup_data[l].isSelected=true;
    }
  }else{
    if(ev.loginName == this.backup_data[l].loginName){
      this.backup_data[l].isSelected=false;
    }
  }
  }
  for(var l=0; l<this.user_data.length; l++){
    console.log(present)
    if(!present){
    if(ev.loginName == this.user_data[l].loginName){
      this.user_data[l].isSelected=true;
    }
  }else{
    if(ev.loginName == this.user_data[l].loginName){
      this.user_data[l].isSelected=false;
    }
  }
  }
 
  for(var l=0; l<this.user_data.length; l++){
      if(this.user_data[l].isSelected===true){
        this.req_selectall=true;
      }
      else{
        this.req_selectall=false;
        break
      }
    }

  }

  itemSelected_rightbox(ev){
    this.requirement_selectall_right=false;
    let present=false;
    for(var k=0; k<this.rem_data.length; k++){
      if(ev.loginName==this.rem_data[k].loginName){
        present=true;
        this.rem_data.splice(k, 1);
      }
    }
    if(!present){
      this.rem_data.push(ev)
    }
    for(var l=0; l<this.backup_users.length; l++){
      if(!present){
      if(ev.loginName == this.backup_users[l].loginName){
        this.backup_users[l].isSelected=true;
      }
    }else{
      if(ev.loginName == this.backup_users[l].loginName){
        this.backup_users[l].isSelected=false;
      }
    }
    }

    for(var l=0; l<this.selected_users.length; l++){
      if(!present){
      if(ev.loginName == this.selected_users[l].loginName){
        this.selected_users[l].isSelected=true;
      }
    }else{
      if(ev.loginName == this.selected_users[l].loginName){
        this.selected_users[l].isSelected=false;
      }
    }
    }
   
    for(var l=0; l<this.selected_users.length; l++){
      if(this.selected_users[l].isSelected===true){
        this.requirement_selectall_right=true;
      }
      else{
        this.requirement_selectall_right=false;
        break
      }
    }
  }

  add_users(){
    this.req_selectall=null;
    this.requirement_selectall_right=null;
    if(this.sel_data.length>0){

  for(var j=0; j<this.backup_data.length; j++){

    if(this.backup_data[j].isSelected){
      console.log(this.backup_data[j])
      this.selected_users.push(this.backup_data[j])
      // console.log(this.selected_users)
    }
  }


  this.user_data=this.backup_data;
  this.user_data = this.user_data.filter(x => x.isSelected != true);
  this.sel_data=[];
  for(var m=0; m<this.backup_data.length; m++){
    for(var n=0; n<this.selected_users.length; n++){
      if(this.selected_users[n].loginName==this.backup_data[m].loginName){
        this.backup_data[m].isSelected=true;
      }
    }
  }

  this.backup_data = this.backup_data.filter(x => x.isSelected != true);
  this.user_data=this.backup_data;
  for(var j=0; j<this.selected_users.length; j++){
    this.selected_users[j].isSelected=false;
  }
  this.backup_users=this.selected_users;
  //setting the serach value
  const name:any =  document.getElementById('sid1');
  name.value = "";
  }
  }

  remove_user() {
    if (this.rem_data.length != 0) {
      
        this.req_selectall=null;
      this.requirement_selectall_right=null;
      if(this.rem_data.length>0){

      for(var j=0; j<this.backup_users.length; j++){
        if(this.backup_users[j].isSelected){
          this.user_data.push(this.backup_users[j])
        }
      }

    // this.selected_users=this.backup_users;
    // this.selected_users = this.selected_users.filter(x => x.isSelected != true);

    this.backup_users = this.backup_users.filter(x => x.isSelected != true);
    this.selected_users=this.backup_users;

    for(var j=0; j<this.backup_data.length; j++){
      this.backup_data[j].isSelected=false
      }

    this.rem_data=[];
    for(var m=0; m<this.backup_users.length; m++){
      for(var n=0; n<this.user_data.length; n++){
        if(this.user_data[n].loginName==this.backup_users[m].loginName){
          this.backup_users[m].isSelected=true;
        }
      }
    }
      }
    }

  }

  reqAll(){
    // this.loadspinner=true;
    if(this.req_selectall==true){
    this.user_data.forEach(element => {
    element.isSelected=true;
    })
    this.sel_data=[];
    this.backup_data=[];
    this.user_data.forEach(element => {
    this.sel_data.push(element);
    this.backup_data.push(element)

    })

    }else if(this.req_selectall==false){
    this.user_data.forEach(element => {
    element.isSelected=false;
    })

    this.sel_data=[];
    this.backup_data= this.user_data;
    this.user_data.forEach(element => {
    //this.sel_data.push(element);
    })
    }

    }




  reqAll_right(){
    if(this.requirement_selectall_right==true){
    this.selected_users.forEach(element => {
    element.isSelected=true;
    })
    this.rem_data=[];
    this.backup_users=[];
    this.selected_users.forEach(element => {
    this.rem_data.push(element);
    this.backup_users.push(element)

    })

    }else if(this.requirement_selectall_right==false){
    this.selected_users.forEach(element => {
    element.isSelected=false;
    })

    this.rem_data=[];
    this.backup_users=this.selected_users;
    this.selected_users.forEach(element => {
    //this.rem_data.push(element);
   // this.backup_users.push(element)
    })

    }

    }

submit(){
    this.schedule_report();
}

schedule_report() {
    this.loadspinner = true;
  
    let endpoint = 'reports/reportenroll/'+this.report_id;
    let user_list: any = [];
    const loginId: string = localStorage.getItem('loginId');

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        user_list.push(this.backup_users[i].loginName)
      }
    }

    let body ={
      "usersList":user_list
    }

    this._Service.scheduleReport(endpoint, body, 'POST')
      .subscribe((response) => {
        
        this.loadspinner = false;
        if (response["message"] === 'Success') {
          //if(this.pageId==1){
          this.dialog.close('success');
          
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            data: {  from: 'error-popup',
            name: data },
            position: {
              top: '40vh'
            }
          });
        }
      },
        (err) => {
          this.loadspinner = false;
          //  console.log(err)

        });
   }

 
}
