
export const environment = {
  production: false,
  message: 'hello dev',
  encrypt_text: 'cHangeTmrW21'
};

//local
//export const launch_callback_serverurl="http://localhost:4200/";

// Dev URL
export const loginapiUrl = 'https://iye6qvyu52.execute-api.us-east-1.amazonaws.com/dev';
export const setpassapiUrl = 'https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev';
export const filterservice = 'https://on30f0imw8.execute-api.us-east-1.amazonaws.com/dev';
export const usertable = 'https://npaqxb829l.execute-api.us-east-1.amazonaws.com/dev';
export const createuserUrl = 'https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev';
export const grouplistTable = 'https://e953cju8g2.execute-api.us-east-1.amazonaws.com/dev';
export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/';
export const uploadurl = "https://p00amw9f0l.execute-api.us-east-1.amazonaws.com/dev/content/upload";
export const companylistMngmnt = 'https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev';
export const managerlist = "https://npaqxb829l.execute-api.us-east-1.amazonaws.com/dev";
export const license = 'https://662f268s2f.execute-api.us-east-1.amazonaws.com/dev';
export const courseurl = "https://4b7djluwok.execute-api.us-east-1.amazonaws.com/dev/"
export const externaltraininglist = "https://4b7djluwok.execute-api.us-east-1.amazonaws.com/dev";
export const companynamevalidate = " https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev";
export const assignableList = 'https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev';
export const createcourse = "https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev";
export const enrollmentlist = " https://isojb1gbbl.execute-api.us-east-1.amazonaws.com/dev";
export const courseProperties = "https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev/";
export const curriculum = "https://cbbr8voemi.execute-api.us-east-1.amazonaws.com/dev";
export const updatecurriclumUrl = "https://cbbr8voemi.execute-api.us-east-1.amazonaws.com/dev";
export const assignments = "https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev";
export const assignmentList = 'https://ceesrp9s99.execute-api.us-east-1.amazonaws.com/dev';
export const formlist = "https://qv50ljzka0.execute-api.us-east-1.amazonaws.com/dev";
export const formlistimport = "https://qv50ljzka0.execute-api.us-east-1.amazonaws.com/dev";
export const launch = "https://v6qh9su372.execute-api.us-east-1.amazonaws.com/dev";
export const createforms = "https://qv50ljzka0.execute-api.us-east-1.amazonaws.com/dev";
export const pefsettings = "https://qv50ljzka0.execute-api.us-east-1.amazonaws.com/dev"; //
export const announcement = "https://u8yieraaxg.execute-api.us-east-1.amazonaws.com/dev";
export const task = "https://8brrdwq4ff.execute-api.us-east-1.amazonaws.com/dev";
export const learnertask = "https://8brrdwq4ff.execute-api.us-east-1.amazonaws.com/dev"
export const createtasklist = "https://8brrdwq4ff.execute-api.us-east-1.amazonaws.com/dev"; //
export const launchlink = "https://v6qh9su372.execute-api.us-east-1.amazonaws.com/dev/"
export const curriculasequesnce = "https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev"
export const launch_callback_serverurl = "https://dev.stellar-oqcs.com/";
export const evaluatorlist = 'https://npaqxb829l.execute-api.us-east-1.amazonaws.com/dev'
export const evaluatoraddremove = 'https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev';
export const calendarview = "https://ceesrp9s99.execute-api.us-east-1.amazonaws.com/dev";
export const groupstastsexporturl = 'https://e953cju8g2.execute-api.us-east-1.amazonaws.com/dev';
export const assignmentlistexporturl = 'https://ceesrp9s99.execute-api.us-east-1.amazonaws.com/dev/';
export const tasklistnameexporturl = 'https://8brrdwq4ff.execute-api.us-east-1.amazonaws.com/dev';
export const scorm_importcourse = 'https://import.dev.stellar-oqcs.com/course/import/'
export const filepeekurl = 'https://import.dev.stellar-oqcs.com/course/peek';
 export const activeuserlistprinturl = 'https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev';
 export const groupStatsprinturl = 'https://e953cju8g2.execute-api.us-east-1.amazonaws.com/dev';
 export const qrcodeinfo = 'https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev';
 export const transcriptprinturl = 'https://ceesrp9s99.execute-api.us-east-1.amazonaws.com/dev';
 export const requirementRule = 'https://4c4mf4do15.execute-api.us-east-1.amazonaws.com/dev'
 export const taskrule = 'https://4c4mf4do15.execute-api.us-east-1.amazonaws.com/dev'
export const delete_groupurl = 'https://8brrdwq4ff.execute-api.us-east-1.amazonaws.com/dev';
export const curriculumprinturl = 'https://cbbr8voemi.execute-api.us-east-1.amazonaws.com/dev';
export const enrollmentprinturl = 'https://isojb1gbbl.execute-api.us-east-1.amazonaws.com/dev';
export const learnerAssignmentprinturl = 'https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev';
export const userListprinturl = 'https://npaqxb829l.execute-api.us-east-1.amazonaws.com/dev' ;
export const companyListprinturl = 'https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev' ;
export const reportlink = 'https://vng16a5bwc.execute-api.us-east-1.amazonaws.com/dev' ;
export const jobPositionprinturl = 'https://8brrdwq4ff.execute-api.us-east-1.amazonaws.com/dev' ;
export const learnertaskprinturl = 'https://ceesrp9s99.execute-api.us-east-1.amazonaws.com/dev' ;
export const learnerviewtaskurl = 'https://8brrdwq4ff.execute-api.us-east-1.amazonaws.com/dev' ;
export const mergeUserurl = 'https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev' ;
export const homeCounturl = 'https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev' ;
export const homeAnnouncementurl = 'https://u8yieraaxg.execute-api.us-east-1.amazonaws.com/dev' ;
export const isntransferurl = 'https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev' ;
export const cataloglist = 'https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev' ;
export const generatePortability = 'https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev';
export const sharedRecCompanyId = 'https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev';
export const companylogo = 'https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev/companies/uploadlogo';
export const ExamManagement='https://cpz85bqp38.execute-api.us-east-1.amazonaws.com/dev';
export const DemoLogin='https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev';
export const siteKey = '6LcwHP4fAAAAAG75mhICNHv2e7EIZXf2qItRF6Ez';
export const extnltrningdnldcsvtemplate ='https://externaltraining-properties.s3.amazonaws.com/ExternalTraining.csv';
export const extnltrningdnldcsvinstruction ='https://externaltraining-properties.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
export const formsdnldcsvtemplate ='https://form-properties.s3.amazonaws.com/FormDetails.csv';
export const formsdnldcsvinstruction ='https://form-properties.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
export const Tasksdnldcsvtemplate ='https://task-properties.s3.amazonaws.com/TaskDetails.csv';
export const Tasksdnldcsvinstruction ='https://task-properties.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
export const usersdnldcsvtemplate ='https://usersproperties.s3.amazonaws.com/UserDetails.csv';
export const usersdnldcsvinstruction ='https://usersproperties.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
export const evaluation="https://ua68cqu6mg.execute-api.us-east-1.amazonaws.com/dev";
export const MEA_user_QRcode="https://qrcode.stellar-oqcs.com/";

//Test URL
// export const loginapiUrl = 'https://lcp8ihx612.execute-api.us-east-1.amazonaws.com/test'; //IDMS
// export const setpassapiUrl = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'; //USERS
// export const filterservice = 'https://5zzag9g15j.execute-api.us-east-1.amazonaws.com/test'; //LOOKUP
// export const usertable = 'https://bfx933ntn3.execute-api.us-east-1.amazonaws.com/test'; // USERLIST
// export const createuserUrl = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'; //USERS
// export const grouplistTable = 'https://axoe6yh0r7.execute-api.us-east-1.amazonaws.com/test'; //GROUPS
// export const importUrl='https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED
// export const uploadurl="https://yh1rupl1q1.execute-api.us-east-1.amazonaws.com/test/content/upload"; //USER PROFILE
// export const companylistMngmnt='https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test'; //COMPANIES
// export const managerlist="https://bfx933ntn3.execute-api.us-east-1.amazonaws.com/test"; //USERSLIST
// export const license = ' https://l6zxxqakxl.execute-api.us-east-1.amazonaws.com/test'; //LICENSE
// export const courseurl="https://vaeqlqrvze.execute-api.us-east-1.amazonaws.com/test/"; //EXTERNAL TRAINING
// export const externaltraininglist="https://vaeqlqrvze.execute-api.us-east-1.amazonaws.com/test"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test"; //COMPANIES
// export const assignableList = 'https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test'; //COURSE
// export const createcourse = "https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test"; //COURSE
// export const enrollmentlist= "https://a13kew48pj.execute-api.us-east-1.amazonaws.com/test"; //ENROLLMENT
//  export const courseProperties="https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test";//COURSE
//  export const curriculum = "https://7ixtonpioh.execute-api.us-east-1.amazonaws.com/test";//CURRICULLUM
//  export const updatecurriclumUrl ="https://7ixtonpioh.execute-api.us-east-1.amazonaws.com/test";//CURRICULLUM
//  export const assignments="https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test";//COURSE
//  export const assignmentList = 'https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test';//COURSE
//  export const formlist="https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test";//PEF
//  export const formlistimport="https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test";//PEFimport
//  export const launch="https://i0ci4j1tl7.execute-api.us-east-1.amazonaws.com/test/";//LAUNCH
//  export const createforms="https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test";//PEF
//  export const pefsettings = "https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test"; //PEF
//  export const announcement = "https://i98bbrpmfd.execute-api.us-east-1.amazonaws.com/test/";//AANNOUNCEMENT
//  export const task = "https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test";//TASK
//  export const learnertask = "https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test"//TASK
//  export const createtasklist = "https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test"; //TASK
//  export const launchlink="https://i0ci4j1tl7.execute-api.us-east-1.amazonaws.com/test/";//LAUNCH
//  export const curriculasequesnce="https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test"//COURSE
// export const launch_callback_serverurl="https://test.stellar-oqcs.com/";
//   export const evaluatoraddremove = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test';
//   export const evaluatorlist = 'https://bfx933ntn3.execute-api.us-east-1.amazonaws.com/test';
//   export const calendarview = "https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test";
//  export const assignmentlistexporturl = 'https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test';
//  export const groupstastsexporturl = "https://axoe6yh0r7.execute-api.us-east-1.amazonaws.com/test/";
//  export const tasklistnameexporturl = 'https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test';
// export const scorm_importcourse = 'https://import.test.stellar-oqcs.com/course/import/'
// export const filepeekurl = 'https://import.test.stellar-oqcs.com/course/peek';
// export const qrcodeinfo = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'
// export const activeuserlistprinturl = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test';
// export const groupStatsprinturl = 'https://axoe6yh0r7.execute-api.us-east-1.amazonaws.com/test';
// export const transcriptprinturl = 'https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test';
// export const requirementRule = 'https://nmivseawzi.execute-api.us-east-1.amazonaws.com/test'
// export const taskrule = 'https://nmivseawzi.execute-api.us-east-1.amazonaws.com/test';
// export const delete_groupurl = 'https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test';
// export const curriculumprinturl = 'https://7ixtonpioh.execute-api.us-east-1.amazonaws.com/test';
//  export const enrollmentprinturl = 'https://a13kew48pj.execute-api.us-east-1.amazonaws.com/test';
// export const learnerAssignmentprinturl = 'https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test';
// export const userListprinturl = 'https://bfx933ntn3.execute-api.us-east-1.amazonaws.com/test' ;
// export const companyListprinturl = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test' ;
// export const reportlink = 'https://l18tvuo37j.execute-api.us-east-1.amazonaws.com/test' ;
// export const jobPositionprinturl = 'https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test' ;
// export const learnertaskprinturl = 'https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test' ;
// export const learnerviewtaskurl = 'https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test' ;
// export const mergeUserurl = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test' ; //USERS
// export const homeCounturl = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test' ;
// export const homeAnnouncementurl = 'https://i98bbrpmfd.execute-api.us-east-1.amazonaws.com/test' ;
// export const isntransferurl = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test' ;
// export const cataloglist = 'https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test' ;
// export const generatePortability = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'
// export const sharedRecCompanyId = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'
// export const companylogo = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test/companies/uploadlogo';
// export const ExamManagement='https://ibxbohooji.execute-api.us-east-1.amazonaws.com/test';
// export const DemoLogin='https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test';
// export const siteKey = '6LcwHP4fAAAAAG75mhICNHv2e7EIZXf2qItRF6Ez';
// export const extnltrningdnldcsvtemplate ='https://externaltraining-properties.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction ='https://externaltraining-properties.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate ='https://form-properties.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction ='https://form-properties.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate ='https://task-properties.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction ='https://task-properties.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// export const usersdnldcsvtemplate ='https://usersproperties.s3.amazonaws.com/UserDetails.csv';
// export const usersdnldcsvinstruction ='https://usersproperties.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation="https://xxrmcaaj31.execute-api.us-east-1.amazonaws.com/test"
// export const MEA_user_QRcode="https://qrcode.stellar-oqcs.com/";

// // // UAT new env
// export const loginapiUrl = 'https://wbqayilfb0.execute-api.us-east-1.amazonaws.com/uat'; //IDMS
// export const setpassapiUrl = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'; //USERS
// export const filterservice = 'https://zzjo3wawbb.execute-api.us-east-1.amazonaws.com/uat'; //LOOKUP
// export const usertable = 'https://6sb3uswr9l.execute-api.us-east-1.amazonaws.com/uat'; // USERLIST
// export const createuserUrl = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'; //USERS
// export const grouplistTable = 'https://lcspzrrwe1.execute-api.us-east-1.amazonaws.com/uat'; //GROUPS
// export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED------------
// export const uploadurl = "https://pxm4n7ryk4.execute-api.us-east-1.amazonaws.com/uat/content/upload"; //USER PROFILE
// export const companylistMngmnt = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat'; //COMPANIES
// export const managerlist = "https://6sb3uswr9l.execute-api.us-east-1.amazonaws.com/uat"; //USERSLIST
// export const license = 'https://bd6mra0z71.execute-api.us-east-1.amazonaws.com/uat'; //LICENSE
// export const courseurl = "https://rnp1i5fcna.execute-api.us-east-1.amazonaws.com/uat/"; //EXTERNAL TRAINING
// export const externaltraininglist = "https://rnp1i5fcna.execute-api.us-east-1.amazonaws.com/uat"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat"; //COMPANIES
// export const assignableList = 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat'; //COURSE
// export const createcourse = "https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat"; //COURSE
// export const enrollmentlist = "https://iyeh4122k7.execute-api.us-east-1.amazonaws.com/uat"; //ENROLLMENT
// export const courseProperties = "https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat";//COURSE
// export const curriculum = "https://ekl9kytp9k.execute-api.us-east-1.amazonaws.com/uat";//CURRICULLUM
// export const updatecurriclumUrl = "https://ekl9kytp9k.execute-api.us-east-1.amazonaws.com/uat";//CURRICULLUM
// export const assignments = "https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat";//COURSE
// export const assignmentList = 'https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat/';//COURSE
// export const formlist = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat/";//PEF
// export const formlistimport = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat";//PEFimport
// export const launch = "https://94n2vvdy70.execute-api.us-east-1.amazonaws.com/uat";//RUNTIME
// export const createforms = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat/";//PEF
// export const pefsettings = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat/"; //PEF
// export const announcement = "https://tvasax3kf0.execute-api.us-east-1.amazonaws.com/uat/";//ANNOUNCEMENT
// export const task = "https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat/";//TASK
// export const learnertask = "https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat/"//TASK
// export const createtasklist = "https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat/"; //TASK
// export const launchlink = "https://94n2vvdy70.execute-api.us-east-1.amazonaws.com/uat/";//RUNTIME
// export const curriculasequesnce = "https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat"//COURSE
// export const launch_callback_serverurl = "https://uat.stellar-oqcs.com/"; //--------
// export const evaluatorlist = 'https://6sb3uswr9l.execute-api.us-east-1.amazonaws.com/uat'; //USERSLIST
// export const evaluatoraddremove = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'; //USERS
// export const calendarview = "https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat"; //TRANSCRIPT
// export const groupstastsexporturl = 'https://lcspzrrwe1.execute-api.us-east-1.amazonaws.com/uat'; //GROUPS
// export const assignmentlistexporturl = 'https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat/'; //TRANSCRIPT
// export const tasklistnameexporturl = 'https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat'; //TASK
// export const scorm_importcourse = 'https://import.uat.stellar-oqcs.com/course/import/';
// export const filepeekurl = 'https://import.uat.stellar-oqcs.com/course/peek';
// export const activeuserlistprinturl = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat'; //COMPANIES
// export const groupStatsprinturl = 'https://lcspzrrwe1.execute-api.us-east-1.amazonaws.com/uat'; //GROUPS
// export const qrcodeinfo = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'; //USERS
// export const transcriptprinturl = 'https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat'; //TRANSCRIPT
// export const requirementRule = 'https://5pg9y5od0a.execute-api.us-east-1.amazonaws.com/uat'; //----
// export const taskrule = 'https://5pg9y5od0a.execute-api.us-east-1.amazonaws.com/uat'; //--------
// export const delete_groupurl = 'https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat'; //TASK
// export const curriculumprinturl = 'https://ekl9kytp9k.execute-api.us-east-1.amazonaws.com/uat'; //CURRICULLUM
// export const enrollmentprinturl = 'https://iyeh4122k7.execute-api.us-east-1.amazonaws.com/uat'; //ENROLLMENT
// export const learnerAssignmentprinturl = 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat'; //COURSE
// export const userListprinturl = 'https://6sb3uswr9l.execute-api.us-east-1.amazonaws.com/uat' ;
// export const companyListprinturl = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat' ;
// export const reportlink = 'https://qmg7wqag3h.execute-api.us-east-1.amazonaws.com/uat' ;
// export const jobPositionprinturl = 'https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat' ;
// export const learnertaskprinturl = 'https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat' ;
// export const learnerviewtaskurl = 'https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat' ;
// export const mergeUserurl = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat' ; //USERS
// export const homeCounturl = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat' ;
// export const homeAnnouncementurl = 'https://tvasax3kf0.execute-api.us-east-1.amazonaws.com/uat' ;
// export const isntransferurl = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat' ;
// export const cataloglist = 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat' ;
// export const generatePortability = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'
// export const sharedRecCompanyId = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'
// export const companylogo = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat/companies/uploadlogo';
// export const ExamManagement='https://v4ik9w16yj.execute-api.us-east-1.amazonaws.com/uat';
// export const DemoLogin='https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat';
// export const siteKey = '6LcfHP4fAAAAABVkzdTQ2bhzMRFaBVP8AF2689Tx';
// export const extnltrningdnldcsvtemplate ='https://externaltrainingproperties.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction ='https://externaltrainingproperties.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate ='https://pef-properties.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction ='https://pef-properties.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate ='https://taskproperties.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction ='https://taskproperties.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// export const usersdnldcsvtemplate ='https://users-properties.s3.amazonaws.com/UserDetails.csv';
// export const usersdnldcsvinstruction ='https://users-properties.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation="https://bm1b49l2t4.execute-api.us-east-1.amazonaws.com/uat"
// export const MEA_user_QRcode="https://qrcodes.stellar-oqcs.com";

//DEMO server
// export const loginapiUrl = 'https://demo1.stellar-oqcs.com/idms'; //IDMS
// export const setpassapiUrl = 'https://demo1.stellar-oqcs.com'; //USERS
// export const filterservice = 'https://demo1.stellar-oqcs.com'; //LOOKUP
// export const usertable = 'https://demo2.stellar-oqcs.com'; // USERLIST
// export const createuserUrl = 'https://demo1.stellar-oqcs.com'; //USERS
// export const grouplistTable = 'https://demo1.stellar-oqcs.com'; //GROUPS
// export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED------------
// export const uploadurl = "https://demo1.stellar-oqcs.com"; //USER PROFILE
// export const companylistMngmnt = 'https://demo1.stellar-oqcs.com'; //COMPANIES
// export const managerlist = "https://demo2.stellar-oqcs.com"; //USERSLIST
// export const license = 'https://demo1.stellar-oqcs.com'; //LICENSE
// export const courseurl = "https://demo1.stellar-oqcs.com"; //EXTERNAL TRAINING
// export const externaltraininglist = "https://demo1.stellar-oqcs.com"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://demo1.stellar-oqcs.com"; //COMPANIES
// export const assignableList = 'https://demo1.stellar-oqcs.com'; //COURSE
// export const createcourse = "https://demo1.stellar-oqcs.com"; //COURSE
// export const enrollmentlist = "https://demo1.stellar-oqcs.com"; //ENROLLMENT
// export const courseProperties = "https://demo1.stellar-oqcs.com";//COURSE
// export const curriculum = "https://demo1.stellar-oqcs.com";//CURRICULLUM
// export const updatecurriclumUrl = "https://demo1.stellar-oqcs.com";//CURRICULLUM
// export const assignments = "https://demo1.stellar-oqcs.com";//COURSE
// export const assignmentList = 'https://demo1.stellar-oqcs.com';//COURSE
// export const formlist = "https://demo1.stellar-oqcs.com";//PEF
// export const formlistimport = "https://demo1.stellar-oqcs.com";//PEF
// export const launch = "https://demo2.stellar-oqcs.com";//RUNTIME
// export const createforms = "https://demo1.stellar-oqcs.com";//PEF
// export const pefsettings = "https://demo1.stellar-oqcs.com"; //PEF
// export const announcement = "https://demo1.stellar-oqcs.com";//ANNOUNCEMENT
// export const task = "https://demo1.stellar-oqcs.com";//TASK
// export const learnertask = "https://demo1.stellar-oqcs.com"//TASK
// export const createtasklist = "https://demo1.stellar-oqcs.com"; //TASK
// export const launchlink = "https://demo2.stellar-oqcs.com";//RUNTIME
// export const curriculasequesnce = "https://demo1.stellar-oqcs.com"//COURSE
// export const launch_callback_serverurl = "http://demoui-ap-south-1.s3-website.ap-south-1.amazonaws.com/"; //--------
// export const evaluatorlist = 'https://demo2.stellar-oqcs.com'; //USERSLIST
// export const evaluatoraddremove = 'https://demo1.stellar-oqcs.com'; //USERS
// export const calendarview = "https://demo1.stellar-oqcs.com"; //TRANSCRIPT
// export const groupstastsexporturl = 'https://demo1.stellar-oqcs.com'; //GROUPS
// export const assignmentlistexporturl = 'https://demo1.stellar-oqcs.com'; //TRANSCRIPT
// export const tasklistnameexporturl = 'https://demo1.stellar-oqcs.com'; //TASK
// export const scorm_importcourse = 'https://demo2.stellar-oqcs.com';//IMPORT
// export const filepeekurl = 'https://demo2.stellar-oqcs.com';//IMPORT
// export const activeuserlistprinturl = 'https://demo1.stellar-oqcs.com'; //COMPANIES
// export const groupStatsprinturl = 'https://demo1.stellar-oqcs.com'; //GROUPS
// export const qrcodeinfo = 'https://demo1.stellar-oqcs.com'; //USERS
// export const transcriptprinturl = 'https://demo1.stellar-oqcs.com'; //TRANSCRIPT
// export const requirementRule = 'https://demo1.stellar-oqcs.com'; //----
// export const taskrule = 'https://demo1.stellar-oqcs.com'; //--------
// export const delete_groupurl = 'https://demo1.stellar-oqcs.com'; //TASK
// export const curriculumprinturl = 'https://demo1.stellar-oqcs.com'; //CURRICULLUM
// export const enrollmentprinturl = 'https://demo1.stellar-oqcs.com'; //ENROLLMENT
// export const learnerAssignmentprinturl = 'https://demo1.stellar-oqcs.com'; //COURSE
// export const userListprinturl = 'https://demo1.stellar-oqcs.com' ;
// export const companyListprinturl = 'https://demo1.stellar-oqcs.com' ;
// export const reportlink = 'https://demo1.stellar-oqcs.com' ;
// export const jobPositionprinturl = 'https://demo1.stellar-oqcs.com' ;
// export const learnertaskprinturl = 'https://demo1.stellar-oqcs.com' ;
// export const learnerviewtaskurl = 'https://demo1.stellar-oqcs.com' ;
// export const mergeUserurl = 'https://demo1.stellar-oqcs.com' ;
// export const homeCounturl = 'https://demo1.stellar-oqcs.com' ;
// export const homeAnnouncementurl = 'https://demo1.stellar-oqcs.com' ;
// export const isntransferurl = 'https://demo1.stellar-oqcs.com' ;
// export const cataloglist = 'https://demo1.stellar-oqcs.com' ;
// export const MEA_user_QRcode="https://qrcode.stellar-oqcs.com/";