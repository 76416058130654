import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { ExamManagementComponent } from 'src/app/Administrator/exam-management/exam-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-examsenrollmentsfilter',
  templateUrl: './examsenrollmentsfilter.component.html',
  styleUrls: ['./examsenrollmentsfilter.component.scss'],
  providers: [DatePipe]
})
export class ExamsenrollmentsfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  today = new Date();
  enddateconsistnull: any;
  isDisabled: boolean;
  examId: any='';

  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<ExamManagementComponent>) {

    this.filterform = this.formBuilder.group({
      Login: [''],
      Firstname: [''],
      CompanyId:[''],
      LastName: [''],
      start: [''],
      end: [''],
      Status:['']

      // DateRequested: [''],
    });
    this.apicallgetdefaultdropdown();
  }
  ngOnInit() {
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Not Started" },
          { "status_id": 2, "statusName": "Failed" },
          { "status_id": 3, "statusName": "In Progress" },
          { "status_id": 4, "statusName": "Passed" },
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          // console.log(err)

        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['Login'].setValue(data["Login"]);
      this.filterform.controls['Firstname'].setValue(data["First name"]);
      this.filterform.controls['LastName'].setValue(data["Last Name"]);
      this.filterform.controls['CompanyId'].setValue(data["Company (COID)"]);
      this.filterform.controls['Status'].setValue(data["Status"]);


      // for date range
      let a = data["Completion date range"].split('-')[0];
      let b = data["Completion date range"].split('-')[1];

      if (data["Completion date range"] === null || data["Completion date range"] === undefined || data["Completion date range"] === "") {
        this.filterform.controls['start'].setValue("");
        this.filterform.controls['end'].setValue("");
      } else {
        this.filterform.controls['start'].setValue(new Date(a));
        this.filterform.controls['end'].setValue(new Date(b));
      }
      this.clearfilterfunction();
    }

    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      this.isDisabled = true;
      this.disablebutton = false;
    }
    else {
      this.isDisabled = false;
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Company (COID)") {
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Status") {
          this.filterform.controls['Status'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Login") {
          this.filterform.controls['Login'].setValue("");
        }
        else if (clearfilteritemkey[i] === "First name") {
          this.filterform.controls['Firstname'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Last Name") {
          this.filterform.controls['LastName'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Completion date range") {
          this.filterform.controls['start'].setValue("");
          this.filterform.controls['end'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  // on keyup enable filter button
  selectCompanyID(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }

   // on keyup enable filter button
   selectStatus(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }

  inputchangeID1(value) {
    this.dynamicEnableDisablebtn()

  }

  inputchangeID2(value) {
    this.dynamicEnableDisablebtn()

  }

  inputchangeID3(value) {
    this.dynamicEnableDisablebtn()
  }


  startChange(event) {
    console.log("startChange", event.value)
    //console.log(this.filterform.value.end);

  }
  endChange(event) {
    console.log("endChange", event.value);
    if (event.value === null) {
      this.enddateconsistnull = true;
      this.disablebutton = true;
    }
    else {
      this.enddateconsistnull = false;
      this.dynamicEnableDisablebtn();

    }
    //
  }


  inputchangeID4(value) {
    this.dynamicEnableDisablebtn()
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(filterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      //this.Filter();
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }



  getbodycontent() {
    let daterange = "";
    let startdate = "";
    let enddate = "";
    let apistartdate = "";
    let apienddate = "";
    if (this.filterform.value.start === "" || this.filterform.value.start === undefined || this.filterform.value.start === null) {
      daterange = "";
    }
    else {
      startdate = this.datePipe.transform(this.filterform.value.start, 'MM/dd/yyyy');
      enddate = this.datePipe.transform(this.filterform.value.end, 'MM/dd/yyyy');
      daterange = startdate + " - " + enddate;
      apistartdate = this.datePipe.transform(this.filterform.value.start, 'yyyy-MM-dd');
      apienddate = this.datePipe.transform(this.filterform.value.end, 'yyyy-MM-dd');

    }


    let CompanyID = "";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID = "";
    }
    else {
      CompanyID = this.filterform.value.CompanyId
    }

    let filterdata = {
      "Login":this.filterform.value.Login.trim(),
      "First name":this.filterform.value.Firstname.trim(),
      "Last Name":this.filterform.value.LastName.trim(),
      "Company (COID)": CompanyID,
      "Completion date range": daterange,
      "Status": this.filterform.value.Status.trim(),
    }
    this.getapibodycontent( apistartdate, apienddate);
    return filterdata

  }

  getapibodycontent( apistartdate, apienddate) {
    let companylistdataid = "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    this.examId=localStorage.getItem("examId");
    this.apibodycontent = {

      "loginName":this.filterform.value.Login.trim(),
      "firstName":this.filterform.value.Firstname.trim(),
      "lastName":this.filterform.value.LastName.trim(),
      "companyId": companylistdataid,
      "status": this.filterform.value.Status.trim(),
      "startCompletionDate": apistartdate,
      "endCompletionDate": apienddate,
      "examId": this.examId

    }
  }


  checkformvalue() {
    let formempty;
    if ((this.filterform.value.CompanyId.trim() === "") && (this.filterform.value.Login.trim() === "") &&
      (this.filterform.value.Firstname.trim() === "") && (this.filterform.value.LastName.trim() === "") && (this.filterform.value.Status.trim() === "") &&
      (this.filterform.value.start === "" || this.filterform.value.start === null) && (this.filterform.value.end === "" || this.filterform.value.end === null)) {
      formempty = true;
    }
    else {
      formempty = false;
      if (this.enddateconsistnull === true) {
        formempty = true;
      }
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

}
