
<!-- progress loader code starts-->
<div class="spinner_overlay" *ngIf="loadspinner===true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>
<!-- progress loader code ends-->

<!-- delete popup starts-->
<div *ngIf="from=='delete' || from=='delete-group'" class="delete-popup">
  <div class="row header">
    <img src="assets/images/delete.svg" alt="filter">
  </div>
  <div class="row title">
<p>{{title}}</p>
  </div>
  <div class="row sub-title" id="subtitleid">
    <p>{{subtitle}}</p>
  </div>

  <div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
    <button mat-button  class="cancel-btn" (click)="close()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      Cancel</button>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
    <button mat-button  class="Filterbtn" (click)="delete()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Delete</button>
  </div>
  </div>
</div>
<!-- delete popup ends-->

<!-- import popup starts-->
<div *ngIf="from=='import' || from=='importforms' || from=='importrecords' || from=='importtask'" class="box blur-conten import-popup">
  <div class="row header">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec">
    <img  src="assets/images/import.svg" >
     <span class="Import">Import</span>

    <button type="button" class="close" aria-label="Close" (click)="cancel()"> <mat-icon class="clear-btn">clear</mat-icon></button>
 </div>
  </div>

    <hr>
<div class="content-sec">
    <div class="row right-algn" >
      <div class="col-xl-4 col-lg-4 col-md-2 col-sm-0 col-xs-12"></div>
        <div class="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12 disply-cls">
    <button type="button" mat-button class="btn1" (click)="DownloadCSVtemplate(from)"><img src="assets/images/csv.svg" ><span style="padding: 4%;" class="text-css">Download CSV template</span></button>
    <button type="button" mat-button class="btn2" (click)="DownloadCSVinstruction(from)" ><img src="assets/images/docx.svg"><span style="padding: 4%;" class="text-css">CSV Formatting Instructions</span></button>
    </div>
    </div>
<div class="padding">
    <div class="row main-row ">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-height left-text text-css">
       <span *ngIf="from=='import'">Import User File</span> 
       <span *ngIf="from=='importrecords'">Import Records</span> 
       <span *ngIf="from=='importforms'">Import Forms</span> 
       <span *ngIf="from=='importtask'">Import Task</span>
       
       <span class="coln">:</span>
      </div>
      <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12 right-text">
         <button class="file-upload-button1234" > <img class="img-cls" src="assets/images/folder.svg">
         <input type="file" accept=".xlsx, .xls, .csv, .pdf" #fileInput  (change)="onFileSelect($event)"/>
        </button>

        <div *ngIf="importformat===true && filetypecsv===false" class="mat-error">
            <p >Please add the file.</p>
          </div>

        <span class="fileName" *ngIf="this.filetype=='text/csv'"> {{ fileName }} </span>

        <div *ngIf="filetypecsv===false && importformat===false" class="mat-error">
          <p style="margin-left:15px">Please upload CSV file.</p>
        </div>
    </div>
    </div>

    

    <!-- <div class="row main-row">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 left-text text-css">
        <span>Prompt Password Reset </span> <span class="coln">:</span>
       </div>
       <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12 right-text">
         <mat-checkbox  class="checkbox matcheckbox" (change)="update()">  </mat-checkbox>
      <label class="label-checkbox" for="item1">Change Password At Next Login</label><br>
    </div>
    </div> -->
  </div>

    <div class="row">
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-0 col-xs-12"></div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 cancel-btn-col"><br>
        <button mat-button  class="cancel-btn" (click)="cancel()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel</button>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 Filterbtncol"><br>
       
        <button mat-button  class="Filterbtn" (click)="onSubmit()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Import</button>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-0 col-xs-12"></div>
    </div>
</div>
</div>
<!-- import popup ends-->

<!-- import success popup-->

<div *ngIf="from=='import-success'" class="box blur-conten import-success">
  <button type="button" class="close" aria-label="Close" (click)="close()"> <mat-icon class="clear-btn">clear</mat-icon></button>
    <br>

    <div class="imagelogo">
        <img  style="padding: 5%;" src="assets/images/thumbsup.svg">
      </div>


    <h1 class="hurray">Hurray!! File has been imported successfully</h1>
    <h4 class="importLogs">Import Logs</h4>
    <div class="table">
    <div class="row main-row" >
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 right-text text">
        <span>Total Records</span><span>&nbsp;&nbsp;&nbsp;&nbsp;:</span>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
       <span class="number1"> {{import_response.data.totalRecords}} </span>
      </div>
    </div>
    <div class="row main-row" >
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 right-text text">
        <span>Records imported</span><span>&nbsp;&nbsp;&nbsp;&nbsp;:</span>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
      <span class="number2"> {{import_response.data.importedRecords}}  </span>
      </div>
    </div>
    <div class="row main-row" >
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7  right-text text">
        <span>Records Failed</span><span>&nbsp;&nbsp;&nbsp;&nbsp;:</span>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
       <span class="number3"> {{import_response.data.failedRecords}}  </span>
      </div>

      <div class="col-12" style="text-align: center;" *ngIf="filenamenull==='true'" ><br>
      <div class="mat-error">
        <p>Records not found in the imported file </p>
      </div>
      </div>

      </div>
    </div>

    <div class="row btn-row">
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-0 col-0"></div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-5 col-12 cancel-btn-col"><br>
        <button mat-button  class="cancel-btn " (click)="cancel()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel</button>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-7 col-12 Filterbtncol"><br>
        <button mat-button  class="Filterbtn " (click)="download()"
        [disabled]="import_response.data.failedRecords===0 
        && import_response.data.totalRecords !=0 ? true : false">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Download Import Logs</button>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-0 col-0"></div>
    </div>

      </div>
<!-- import success popup-->



<!--login support icon-->
<div *ngIf="from=='support-icon-popup'" class="support-icon">
  <div class="row header">
    <img src="assets/images/mic.svg" alt="filter">
  </div>
  <div class="row title">
<p>Contact MEA</p>
  </div>
  <div class="row main-row content-row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div class="row">
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 sub-content">
    <img src="assets/images/call.svg" alt="filter">
  </div>
   <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 content sub-content">
    <p class="text">(651) 289-9600</p>
   </div>
  </div>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
    <div class="row">
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 sub-content">
    <img src="assets/images/time.svg" alt="filter">
    </div>
    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 content sub-content">
    <p class="text">Mon-Fri   7:30AM-4:30PM CST</p>
    </div>
    </div>
  </div>
  </div>
  <div class="row main-row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
      <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 sub-content">
      <img src="assets/images/support-email.svg" alt="filter">
      </div>
      <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 content sub-content">
      <p class="text">productsupport@meaenergy.org</p>
      </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
      <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 sub-content">
      <img src="assets/images/globe.svg" alt="filter">
      </div>
      <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 content sub-content">
      <p class="text link-css" (click)="link()">EnergyU Support Page</p>
      </div>
      </div>
    </div>
    </div>
    <div class="row button-class">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-0"></div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
        <button mat-button  class="Filterbtn" (click)="cancel()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          CLOSE</button>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-0"></div>
      </div>

</div>
<!--login support icon-->

<!-- Success message popup-->
<div class="row success-popup" *ngIf="from=='popup-success' && imagelogo =='assets/images/information.svg'">
  <div class="col-12 imagelogo"><br>
    <img src={{imagelogo}} alt="popuplogo" style="height:30px !important;">
  </div>

  <div class="col-12 imagelogo">
    <div class="successmsg"><br>
      {{successmsg}}
    </div>
  </div>

  <div class="col-12 imagelogo"><br>
    <div class="successmsgbody" style="word-wrap: break-word;">
      {{successmsgbody}}
    </div>
  </div>

  <div class="col-12" style="text-align: center;"><br><br>
    <button mat-button id="okbtnid" class="Okbtn" (click)="Okbtn()">
      <img src="assets/images/success.svg" alt="successlogo">
      Close</button>
  </div>
</div>
<div class="row success-popup" *ngIf="from=='popup-success' && imagelogo !='assets/images/information.svg'">
  <div class="col-12 imagelogo"><br>
    <img src={{imagelogo}} alt="popuplogo">
  </div>

  <div class="col-12 imagelogo">
    <div class="successmsg"><br>
      {{successmsg}}
    </div>
  </div>

  <div class="col-12 imagelogo"><br>
    <div class="successmsgbody">
      {{successmsgbody}}
    </div>
  </div>

  <div class="col-12" style="text-align: center;"><br><br>
    <button mat-button id="okbtnid" class="Okbtn" (click)="Okbtn()">
      <img src="assets/images/success.svg" alt="successlogo">
      Close</button>
  </div>
</div>
<!-- Success message popup-->

<!-- error service popup-->
<div class="row popup-error" *ngIf="from=='error-popup' || from=='manager-error-popup'">

  <div class="col-12" style="text-align: center"><br>
    <img src="assets/images/failure.svg" alt="popuplogo">
  </div>

  <div *ngIf!='manager-error-popup' class="col-12" style="text-align: center;">
    <div class="failure"><br>
      Failure
    </div>

  </div>

  <div class="col-12" style="text-align: center;"><br>
    <div class="bodymsg">
      {{data['name'].status}}
    </div>

  </div>



  <div class="col-12" style="text-align: center;"><br><br>
    <mat-icon class="filterbtnicon">done</mat-icon>
    <button mat-button id="btntermid" class="closebtn" (click)="errorclose()">
        <mat-icon class="filterbtnicon" alt="successlogo" style="color: white;">done</mat-icon>
        CLOSE</button>
  </div>

</div>

<!-- reset popup screen starts-->
<div class="row popup-error" *ngIf="from=='popup-reset'">

  <div class="col-12" style="text-align: center"><br>
    <img src="assets/images/failure.svg" alt="popuplogo">
  </div>



  <div class="col-12" style="text-align: center;"><br>
    <div class="bodymsg">
     {{content}} with <strong>Not Started</strong> can't be reset!
    </div>

  </div>



  <div class="col-12" style="text-align: center;"><br><br>
    <mat-icon class="filterbtnicon">done</mat-icon>
    <button mat-button id="btntermid" class="closebtn" (click)="errorclose()">
        <mat-icon class="filterbtnicon" alt="successlogo" style="color: white;">done</mat-icon>
        CLOSE</button>
  </div>

</div>
<!-- reset popup screen starts-->

<div class="row popup-error" *ngIf="from=='edit-formdetails-popup'">

  <div class="col-12" style="text-align: center;margin-bottom: 10px"><br>
    <img width="38" height="40" src="assets/images/information.svg" alt="popuplogo">
  </div><br>

  <div *ngIf="from=='edit-formdetails-popup'" class="col-12" style="text-align: center;">
    <div class="failure"><br>
      Editing the form will increment the version.
    </div>

  </div>

  <div class="col-12" style="text-align: center;"><br>
    <div class="bodymsg">
      The form will remain in draft mode until published.<br>
      Evaluators will need to synchronize to obtain the new version<br>
    </div>

  </div>



  <div class="col-12" style="text-align: center;"><br><br>
    <mat-icon class="filterbtnicon">done</mat-icon>
    <button mat-button id="btntermid" class="closebtn" style="margin-right: 20px;" (click)="errorcancel()">
      <mat-icon class="filterbtnicon" alt="successlogo" style="color: white;">done</mat-icon>
      CANCEL</button>
    <button mat-button id="btntermid" class="closebtn" (click)="errorclose()">
        <mat-icon class="filterbtnicon" alt="successlogo" style="color: white;">done</mat-icon>
        CONTINUE</button>
  </div>

</div>
<!--error service popup-->

<!--CANCEL DIALOG POPUP-->
<div class="row cancel-dialog-popup" *ngIf="from=='cancel-dialog' || from=='addexternal-dialog' || from == 'userstatus-dialog' || from=='cancel-qrcode' || from=='cancel-dialog-company' || from=='cancel-dialog-Badges'
|| from=='cancel-dialog-createtasklistname'">
  <div class="col-12 imagelogo"><br>
    <img src={{imagelogo}} alt="popuplogo" >
  </div>

  <div class="col-12 imagelogo" style="margin-top: -5%;">
    <div class="successmsg"><br>
      {{successmsg}}
    </div>
  </div>

  <div class="col-12 imagelogo">
    <div class="successmsgbody">
      {{successmsgbody}}
    </div>
  </div>



  <div class="row" style="width:100%">
    <!-- <div class="col-md-4"> &#160; </div> -->
    <div class="col-12" style="text-align: center;"> <br> <br><br>
      <button mat-button  class="no" (click)="no()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      No</button> &nbsp;&nbsp;

      <!-- <div class="col-md-1"> </div> -->
    <!-- <div class="col-md-6"> -->
      <button mat-button  class="yes" (click)="yes()">
      <mat-icon class="tickicon">done</mat-icon>
      Yes</button> </div>

      <!-- </div> -->
    </div>
    </div>
<!--CANCEL DIALOG POPUP-->

<!-- Terms & Conditions -->
<!-- <div class="terms-popup" > -->
<div class="row scrollablerow " *ngIf="from=='terms-conditions'">
  <div class="col-12 termconditioncol">
    <div class="termcondition">
      EnergyU Terms & Conditions
    </div>
    </div>

    <div class="col-12"><br>
      <p class="privacypolicy"> Privacy Policy </p>

      <p class="termsconditioncontent">
        This Privacy Policy applies to information collected through the following websites: MEAenergy.org,
        connect.MEAenergy.org, MEAenergy.org/energyu, energeticwomen.org and nationalgasrodeo.org.


      </p>

      <p class="termsconditioncontent">
        We collect no personal information about you when you visit this site unless you choose to provide this
        information. If you choose to subscribe to this site, you voluntarily provide some basic information about
        yourself. We collect personally identifiable information (such as name, address, and email address) only if
        specifically and knowingly provided by you. We do not collect or use this information for commercial marketing.
        For MEAenergy.org, we do not provide any User information to any third parties. For EnergyU.org, information is
        used for the purposes of User authentication, distribution of courses/system rights to Users, and reporting to
        the client company on employee learning activity. Information may be provided to third parties, such as
        ISNetworld, at the User company's request.
      </p>


    </div>

    <div class="col-12">
      <p class="privacypolicy"><br>
        Use of your Personal Information
      </p>

      <p class="termsconditioncontent">
        MEA and its operational service partners collect and use your personal information to process product orders and
        deliver the products requested. It is possible that MEA may also use personal information to inform you of other
        products or services available from MEA and its affiliates. MEA may also contact you via surveys to conduct
        research about your opinion of current services or of potential new services that may be offered. MEA does not
        sell, rent or lease its customer lists to third parties. Member information (name, phone, and email) may be
        shared between MEA members through our directory. When registering and making payments for events via
        MEAenergy.org, Secure Sockets Layer (SSL) technology is used to protect the security of your credit card
        information as it is transmitted to us. SSL is the industry standard in Internet encryption technology. As the
        credit card information travels from your computer to our server
       <br><br>
      </p>

    </div>
    </div>

    <div class="row emptyrow" *ngIf="from=='terms-conditions'">
      </div>

    <div class="row closebtnrow" *ngIf="from=='terms-conditions'">
    <div class="col-12" style="text-align: center;" *ngIf="from=='terms-conditions'">
      <button mat-button id="btntermid" class="closebtn" (click)="close_terms()">CLOSE</button>
    </div>
  </div>
  <!-- </div> -->
<!-- Terms & Conditions -->

<!--- Upload image popup-->
<div *ngIf="from=='upload-image'">
  <div class="row header">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec">
    <img  src="assets/images/upload-image.svg" >
     <span class="Import">Upload Image</span>

    <button type="button" class="close" aria-label="Close" (click)="cancel()"> <mat-icon class="clear-btn">clear</mat-icon></button>
 </div>
  </div>

    <hr>
    <div class="content-sec">
      <div class="text-center image-container row">
        <div class="image-box" *ngIf="image_type=='url'">
          <image-cropper
          [imageURL]="imageURL"
          [maintainAspectRatio]="true"
          [roundCropper]="true"
          [transform]="transform"
          [resizeToWidth]="165"
          [resizeToHeight]="165"
          [cropperStaticWidth]="cropperStaticWidth"
          [cropperStaticHeight]="cropperStaticHeight"
          [hideResizeSquares]="true"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
          </div>
          <div class="image-box" *ngIf="image_type!='url'">
            <image-cropper
            [imageChangedEvent]="imageURL"
            [maintainAspectRatio]="true"
            [roundCropper]="true"
            [transform]="transform"
            [resizeToWidth]="165"
            [resizeToHeight]="165"
            [cropperStaticWidth]="cropperStaticWidth"
            [cropperStaticHeight]="cropperStaticHeight"
            [hideResizeSquares]="true"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
            </div>
      </div>

      <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 "></div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
        <p class="Import vertical-center">Crop Image</p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
          <button (click)="zoomOut()" class="minus-btn">
          <mat-icon  class="minus" alt="clear" >minimize</mat-icon>
        </button>
        <mat-slider min="0.1" max="10" [(ngModel)]="scale" (change)="updateSetting($event)"></mat-slider>
        <button (click)="zoomIn()" class="minus-btn">
        <img class="hand-css"  src="assets/images/zoom-in.svg" >
        </button>
         </div>
         <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 "></div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-12 file-btn-outer">
        <button type="button" class="file-button" mat-raised-button (click)="fileInput.click()"> <mat-icon class="attach-icon"> attach_file</mat-icon>Choose Image</button>
        <input hidden (change)="onimagecropselect($event)" #fileInput type="file" id="file">
       </div>
       </div>

      <div class="row footer-btn">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-0 col-xs-12"></div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 cancel-btn-col"><br>
          <button mat-button  class="cancel-btn " (click)="cancel_image()" >
            <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
            Cancel</button>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 Filterbtncol"><br>
          <button mat-button  class="Filterbtn" (click)="save_image()">
            <mat-icon class="filterbtnicon">done</mat-icon>
            Save</button>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-0 col-xs-12"></div>
      </div>
  </div>
</div>
<!-- Upload image popup -->
<!--- Template logo upload popup-->
<div *ngIf="from=='template-upload-logo'">
  <div class="row header">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec">
    <img  src="assets/images/upload-image.svg" >
     <span class="Import">Upload Image</span>

    <button type="button" class="close" aria-label="Close" (click)="cancel()"> <mat-icon class="clear-btn">clear</mat-icon></button>
 </div>
  </div>

    <hr>
<div class="content-sec">


    <div class="text-center image-container row">
      <div class="image-box">
        <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [roundCropper]="false"
        [transform]="transform"
        [resizeToWidth]="150"
        [resizeToHeight]="50"
        [cropperStaticWidth]="150"
        [cropperStaticHeight]="50"
        [hideResizeSquares]="true"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
        </div>
    </div>

    <div class="row">
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 "></div>
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
      <p class="Import vertical-center">Crop Image</p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
        <button (click)="zoomOut()" class="minus-btn">
        <mat-icon  class="minus" alt="clear" >minimize</mat-icon>
      </button>
      <mat-slider class="sliderCls" min="1" max="10" [(ngModel)]="scale" (change)="updateSetting($event)"></mat-slider>
      <button (click)="zoomIn()" class="minus-btn">
      <img class="hand-css"  src="assets/images/zoom-in.svg" >
      </button>
       </div>
       <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 "></div>
    </div>

    <div class="row footer-btn">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-0 col-xs-12"></div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 cancel-btn-col"><br>
        <button mat-button  class="cancel-btn " (click)="cancel_image()" >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel</button>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 Filterbtncol"><br>
        <button mat-button  class="Filterbtn" (click)="save_image()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Save</button>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-0 col-xs-12"></div>
    </div>
</div>
</div>
<!-- Template logo upload popup -->


<!-- image-cancel-->
<div *ngIf="from=='image-cancel' || from=='group-cancel' || from=='category-cancel' || from=='course-cancel' || from=='audience-cancel' || from=='job-cancel' || from=='settings-cancel' || from=='task-cancel' || from=='forms-cancel' || from=='exam-cancel' || from=='exampool-cancel' || from=='questionpool-cancel' || from=='managelogo-cancel'" class="delete-popup">  <div class="row header">
    <img src="assets/images/image_cancel.svg" alt="filter">
  </div>
  <div class="row title">
<p>Do you want to discard the changes?</p>
  </div>
  <div class="row sub-title" id="subtitleid">
    <p>Your unsaved changes will be lost.</p>
  </div>

  <div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
    <button mat-button  class="cancel-btn" (click)="image_cancel_no()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      No</button>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
    <button mat-button  class="Filterbtn" (click)="image_cancel_yes()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Yes</button>
  </div>
  </div>
</div>
<!-- image cancel-->

<!-- Image size issue-->
<div *ngIf="from=='image-size' || from=='import-image-size'" class="delete-popup">
  <div class="row header">
    <img src="assets/images/image_size.svg" alt="filter">
  </div>
  <div class="row title">
<p>{{title}}</p>
  </div>
  <div class="row sub-title" id="subtitleid">
    <p>{{subtitle}}</p>
  </div>

  <div class="row">
  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 cancel-btn-col"><br><br>

  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center"><br><br>
    <button mat-button  class="Filterbtn" (click)="image_cancel_yes()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Ok</button>
  </div>
  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 cancel-btn-col"><br><br>

  </div>
  </div>
</div>
<!-- Image size issue-->
<!-- add user for group success popup-->
<div class="row success-popup adduser-sec" *ngIf="from=='adduser-success'">
  <div class="col-12 imagelogo1">
    <img src={{imagelogo}} alt="popuplogo">
  </div>

  <div class="col-12 imagelogo">
    <div class="successmsg"><br>
      {{successmsg}}
    </div>
  </div>

  <div class="col-12 imagelogo">
    <div class="successmsgbody1"><br>
      {{successmsgbody}}
    </div>
  </div>

  <div class="col-12" style="text-align: center;"><br>
    <button mat-button id="okbtnid" class="Okbtn" (click)="okay_user()">
      <img src="assets/images/success.svg" alt="successlogo">
      Close</button>
  </div>
</div>
<!-- add user for group success popup-->
<!--confirmation popup start-->
<div *ngIf="from=='confirm-popup'  || from=='other-confirm-popup' || from=='confirm-warning-popup'" class="delete-popup">
  <div class="row header">
    <img src={{imagelogo}} style="width: 44px; margin-bottom: 10px;" alt="filter">
  </div>
  <div class="row title">
<p>{{title}}</p>
  </div>
  <div class="row sub-title" id="subtitleid">
    <p>{{subtitle}}</p>
  </div>

  <div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
    <button  *ngIf="from!='other-confirm-popup'"  mat-button  class="cancel-btn" (click)="closeDialog()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      Cancel</button>

      <button  *ngIf="from=='other-confirm-popup'"  mat-button  class="cancel-btn" (click)="closeDialog()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        NO</button>

  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
    <button mat-button  class="Filterbtn" (click)="delete1()" *ngIf="from!='confirm-warning-popup'">
      <mat-icon class="filterbtnicon">done</mat-icon>
      YES</button>
      <button mat-button  class="Filterbtn" (click)="delete1()" *ngIf="from =='confirm-warning-popup'">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Continue</button>
  </div>
  </div>
</div>
<!--confirmation popup ends-->

<!-- archived user popup starts -->
<div *ngIf="from=='archiveduser-popup'" class="delete-popup">
  <div class="row header">
    <img src={{imagelogo}} style="width: 44px; margin-bottom: 10px;" alt="filter">
  </div>
  <div class="row title">
<p>{{title}}</p>
  </div>
  <div class="row sub-title" id="subtitleid">
    <p>{{subtitle}}</p>
  </div>

  <div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
      <button  *ngIf="from=='archiveduser-popup'"  mat-button  class="cancel-btn" (click)="no()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        NO</button>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
    <button mat-button  class="Filterbtn" (click)="archivedYes()" *ngIf="from=='archiveduser-popup'">
      <mat-icon class="filterbtnicon">done</mat-icon>
      YES</button>
  </div>
  </div>
</div>
<!-- archived user popup ends -->

<!-- impersonate popup starts-->
<div *ngIf="from=='impersonate'" class="delete-popup">
  <div class="row header">
    <img src="assets/images/thumbsup.svg" alt="filter">
  </div>
  <div class="row title">
<p>{{title}}</p>
  </div>
  <div class="row sub-title" id="subtitleid">
    <p>{{subtitle}}</p>
  </div>

  <div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
    <button mat-button  class="cancel-btn" (click)="close()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      Cancel</button>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
    <button mat-button  class="Filterbtn" (click)="impersonate()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Proceed</button>
  </div>
  </div>
</div>
<!-- impersonate popup ends-->
