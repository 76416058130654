    <div class="row headr">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec">
        <img src="assets/images/create-u-ser.svg">
        <span class="titles">Change Manager</span>
  
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <mat-icon class="clear-btn">clear</mat-icon>
        </button>
      </div>
    </div>
    <hr>
    <form [formGroup]="changeManagerform" class="box blur-conten filtermaincontainer">
            <div class="row">
        <div class="col-6 col-md-6 inputcolumn">
      <mat-label id="usernameid" class="labelname">Existing Manager</mat-label>
      <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
        <input matInput placeholder="Existing Manager" class="textinputusertablefilter"
          formControlName="ExistingManager" readonly>
      </mat-form-field>
    
    </div>
    
    <div class="col-6 col-md-6 inputcolumn">
      <mat-label id="usernameid" class="labelname">New Manager</mat-label>
      <mat-form-field appearance="outline" class="formfieldmanager">
      
        

        <mat-select style="margin-top: 15px;" matNativeControl formControlName="NewManager" (selectionChange)=selectManager($event.value)
        placeholder="Select Manager">
        <mat-option [value]="" class="none_style">  None </mat-option>
        <mat-option *ngFor="let manager of managet_list" [value]="manager.loginName">
          {{manager.displayName}} ({{manager.loginName}})
          </mat-option>
    
        </mat-select>


      </mat-form-field>
    </div>
  </div>
    
    <div class="row" style="padding-top: 4%;">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
        <button mat-button class="cancel-btn" (click)="close()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
        <button mat-button class="Filterbtn" (click)="managerChange()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Execute
        </button>
      </div>
    </div>
    </form>
  

  