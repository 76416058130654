import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-addcomment',
  templateUrl: './addcomment.component.html',
  styleUrls: ['./addcomment.component.scss']
})
export class AddcommentComponent implements OnInit {
  from:any;
  approveform: FormGroup;  
  denyform: FormGroup;
  licenseData: any =[];
  select_licenseData:any=[];
  licenseId: any;
  companyId: any;
  requestorName: any;
  licenseRequested: any;
  loginId: string;
  denyBtn: boolean;
  comment:any;

  constructor(public dialog: MatDialogRef<CompanyManagementComponent>, @Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder,  public _Service:ApiServiceService, public dialogopen: MatDialog,) { 
    this.from=data['from'];
    this.select_licenseData= data['licenseData'];
    this.licenseId=data['licenseId'];
    this.companyId=data['companyId'];
    this.requestorName=data['requestorName'];
    this.licenseRequested=data['licenseRequested'];  
    this.loginId = localStorage.getItem('loginId');
    this.comment=data['comment']; 
    

  }

  loadspinner: any;
  ngOnInit(): void {
    this.approveform = this.formBuilder.group({
      RequestorComments: this.comment,
      InvoiceNumber:[''],
      approvercomment:['']
    });

    this.denyform = this.formBuilder.group({
      ReasonforDenial: ['',  [Validators.required]]
    });
  }
  cancelbtn(){
    this.dialog.close();
    this.denyBtn= false;
  }
  approve_submit(){

    if(this.approveform.valid) {
   
      let requestorName = this.requestorName.split(',')
      requestorName= requestorName[requestorName.length-1]
    
   
      var body={
          "licenseId":  this.licenseId,
          "companyId": this.companyId,
          "updatedBy": this.loginId,
          "licenseRequested":  this.licenseRequested,
          "invoiceNumber" : this.approveform.value.InvoiceNumber,
          "requestorComment" : this.approveform.value.RequestorComments,
          "approverComments" : this.approveform.value.approvercomment,
          "status" :"Approved"
           }
         
           this.loadspinner = true;
           const endpoint = 'license/update'
           this._Service.approve_license(endpoint, body,  'PATCH').subscribe(response => {
           // this.loadspinner = false;
            if(response["message"]== "Success"){   
              this.dialogopen.open(DialogpopupComponent, {
                width: '550px',
                disableClose: true,
                data:
                {
                  from: "adduser-success",
                 // navigateTo: "edit-user";
                successmsg: "Request Approved!",
                successmsgbody:'Your licenses have been added.',
                imagelogo:"assets/images/thumbsup.svg"                },
                position: {
                  top: '25vh'
                }
              });
              this.dialog.close();
              let element = document.getElementById("CompanyManagement-content")
              element.classList.remove('blur-content');
            }
            else{
              this.loadspinner= false;
              let data = {
                status: response["message"]
              };
            }
          },
          (err) => {
            this.loadspinner = false;
         
           });
          
  }
  
}
deny_submit(){
  this.denyBtn= true;
  if(this.denyform.valid) {
    var body={
      "licenseId":  this.licenseId,
      "companyId": this.companyId,
      "updatedBy": this.loginId,
      "licenseRequested":  this.licenseRequested,
      "denyReason": this.denyform.value.ReasonforDenial,
      "status":"Denied"
       }
    
       this.loadspinner = true;
       const endpoint = 'license/update'
       this._Service.approve_license(endpoint, body,  'PATCH').subscribe(response => {
        this.loadspinner = false;
        if(response["message"]=== 'Success'){
          this.dialog.close();
          let element = document.getElementById("CompanyManagement-content")
          element.classList.remove('blur-content');
        }
        else{
          this.loadspinner= false;
          let data = {
            status: response["message"]
          };
        }
      },
      (err) => {
        this.loadspinner = false;
      
  
       });

  }

}
}
