import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-externaltrainingfilter',
  templateUrl: './externaltrainingfilter.component.html',
  styleUrls: ['./externaltrainingfilter.component.scss'],
  providers: [DatePipe]
})
export class ExternaltrainingfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  today = new Date();
  typelist:  any = [""];
  categoryList: any = [""];

  constructor(private datePipe: DatePipe,private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      Type: [''],
      Category: [''],
      Startdate: [''],
      Enddate: [''],
      Status: [''],
      LearningProgram:['']
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
    // console.log(this.filterform.value.Startdate.trim())

  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      // console.log(data)
    
      this.filterform.controls['Type'].setValue(data["Type"]);
      this.filterform.controls['Category'].setValue(data["Category"]);
      this.filterform.controls['Status'].setValue(data["Status"]);
      this.filterform.controls['LearningProgram'].setValue(data["Learning Program"]);

      // for date range
      let a = data["Completion Date"].split('-')[0];
      let b = data["Completion Date"].split('-')[1];
      // console.log(a);
      if (data["Completion Date"] === null || data["Completion Date"] === undefined || data["Completion Date"] === "") {
        this.filterform.controls['Startdate'].setValue("");
        this.filterform.controls['Enddate'].setValue("");
      } else {
        this.filterform.controls['Startdate'].setValue(new Date(a));
        this.filterform.controls['Enddate'].setValue(new Date(b));
      }


      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Type"){
          this.filterform.controls['Type'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Category"){
          this.filterform.controls['Category'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Completion Date") {
          this.filterform.controls['Startdate'].setValue("");
          this.filterform.controls['Enddate'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Status"){
          this.filterform.controls['Status'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Learning Program"){
          this.filterform.controls['LearningProgram'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    // this.loadspinner = true;
    // const endpoint1 = 'lookup';
    // this.apiService.Simpleget(endpoint1, 'GET')
    //   .subscribe((response) => {
    //     this.loadspinner = false;
    //     this.companylistdata = response["companies"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Active" },
          { "status_id": 2, "statusName": "Expired" }
        ]
        this.typelist = [
          { "type": "TNG" },
          { "type": "KNT" },
          { "type": "Video" },
          { "type": "Other" },
        ]
        this.categoryList = [
          { "category": "eCourse" },
          { "category": "Certification" },
          { "category": "Workshop" },
          { "category": "Assessment" },
          { "category": "Classroom" }
        ]
       
        this.setdefaultdropdownvalues();
      // },
      //   (err) => {
      //     this.loadspinner = false;
      //    // console.log(err)

      //   });
  }


  // on keyup enable filter button
  inputchangegroupname(value){
   this.dynamicEnableDisablebtn()
  }

  inputchangegroupID(value){
  this.dynamicEnableDisablebtn()
  }

  inputchangeLearningProgram(value){
    this.dynamicEnableDisablebtn()
    }

  selectStatusName(value){
    if(value==="" || value === undefined || value===null || (this.filterform.value.Startdate != "" && this.filterform.value.Enddate === null)){
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  selectType(value){
    if(value==="" || value === undefined || value===null || (this.filterform.value.Startdate != "" && this.filterform.value.Enddate === null)){
      // if none select set value as ''
      this.filterform.controls['Type'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  
  selectCategory(value){
    if(value==="" || value === undefined || value===null || (this.filterform.value.Startdate != "" && this.filterform.value.Enddate === null)){
      // if none select set value as ''
      this.filterform.controls['Category'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }



  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {

    let daterange="";
    let startdate="";
    let enddate="";
    let apidaterange="";
    let apistartdate="";
    let apienddate="";
    if(this.filterform.value.Startdate==="" || this.filterform.value.Startdate===undefined || this.filterform.value.Startdate===null){
      daterange="";
    }
    else{
     startdate=this.datePipe.transform(this.filterform.value.Startdate, 'MM/dd/yyyy');
     enddate=this.datePipe.transform(this.filterform.value.Enddate, 'MM/dd/yyyy');
     daterange=startdate + " - " + enddate;
     apistartdate=this.datePipe.transform(this.filterform.value.Startdate, 'yyyy-MM-dd');
     apienddate=this.datePipe.transform(this.filterform.value.Enddate, 'yyyy-MM-dd');
     apidaterange='2021-07-25'

    }
    let groupfilterdata = {
      "Type": this.filterform.value.Type.trim(),
      "Category": this.filterform.value.Category.trim(),
      "Completion Date":  daterange,
      "Status": this.filterform.value.Status.trim(),
      "Learning Program": this.filterform.value.LearningProgram.trim(),
    }
    this.getapibodycontent(apidaterange,apistartdate,apienddate);
    return groupfilterdata

  }

  getapibodycontent(apidaterange,apistartdate,apienddate) {
    this.groupapibodycontent = {
      "category": this.filterform.value.Category.trim(),
      "type": this.filterform.value.Type.trim(),
      "status": this.filterform.value.Status.trim(),
      "startCompletionDate": apistartdate,
      "endCompletionDate": apienddate,
      "title":this.filterform.value.LearningProgram.trim(),
    }
  }

  checkformvalue(){
    // debugger
    let formempty;
    if(this.filterform.value.Startdate != ""){
      if(this.filterform.value.Enddate === null){
        formempty=true;
        return formempty;
      }
    }
    if(this.filterform.value.Category.trim()==="" && this.filterform.value.Type.trim()==="" && this.filterform.value.LearningProgram.trim()==="" &&
    this.filterform.value.Status.trim()==="" && this.filterform.value.Startdate===""  && this.filterform.value.Enddate==="" ){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    // debugger
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

  changeStartDate(event) {

   }
   changeEndDate(event) {
    
    if(event.value === null){
      this.disablebutton=true;
    }
    else{
     this.dynamicEnableDisablebtn();
    }
   //
   }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }
}
