import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  displayedColumns: string[] = ['executedDate', 'statusmessage'];
  dataSource: any;
  loadspinner: any = false;
  ELEMENT_DATA: any = [];
  companyId:any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: string,private apiService: ApiServiceService,public dialog: MatDialogRef<LogsComponent>) {
    this.companyId=data['companyId'];
    this.loaddefaultValue();
   }

  ngOnInit(): void {
  }

  cancelbtn() {
    // // if filterdata not present --- on first load cancel - clear data
    // let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    // if (data === "" || data === undefined || data === null) {
    // }
    // else {
    //   let filterdata = this.getbodycontent();
    //   localStorage.setItem('filterdata', JSON.stringify(filterdata));
    //   localStorage.removeItem('clearfilteritemkey');
    // }
    this.dialog.close({ method: 'close' })
  }

  loaddefaultValue(){
    this.loadspinner = true;
    const endpoint1 = 'companies';
    const endpoint2 = 'isnlogsview';
    let queryparameter = this.companyId;
    this.apiService.isnTranfer_logs(endpoint1, endpoint2, queryparameter, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.ELEMENT_DATA = response['isnLogsData'];
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      },
        (err) => {
          this.loadspinner = false;
        });
  }

}
