import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-tasklistfilter',
  templateUrl: './tasklistfilter.component.html',
  styleUrls: ['./tasklistfilter.component.scss']
})
export class TasklistfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  isDisabled: boolean;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      TasklistName: [''],
      TasklistOwner:['']
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        // this.statuslist = [
        //   { "status_id": 1, "statusName": "Active" },
        //   { "status_id": 2, "statusName": "Inactive" }
        // ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['TasklistName'].setValue(data["Task List Name"]);
      this.filterform.controls['TasklistOwner'].setValue(data["Task List Owner"]);
      this.clearfilterfunction();
    }

    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['TasklistOwner'].setValue(data);
      this.isDisabled=true;
      this.disablebutton=false;
    }
    else{
      this.isDisabled=false;
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Task List Name"){
          this.filterform.controls['TasklistName'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Task List Owner"){
          this.filterform.controls['TasklistOwner'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  inputchange0(value){
    this.dynamicEnableDisablebtn()
    }

    selecttasklistowner(value) {
      if(value==="" || value === undefined || value===null){
        // if none select set value as ''
        this.filterform.controls['TasklistOwner'].setValue("");
        this.dynamicEnableDisablebtn()
      }
      else{
        this.disablebutton=false;
      }
    }

    cancelbtn() {
      // if filterdata not present --- on first load cancel - clear data
      let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
      if (data === "" || data === undefined || data === null) {
      }
      else {
        let groupfilterdata = this.getbodycontent();
        localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
        localStorage.removeItem('group_clearfilteritemkey');
      }
      this.dialog.close({ method: 'close' })
    }

    EnterSubmit(event){
      if(event.keyCode === 13){
       //this.Filter();
       this.dynamicEnableDisablebtn();
       if(this.disablebutton===true){
        event.preventDefault();
       }
       else{
        event.preventDefault();
        this.Filter();
       }

      }
    }


    Filter() {
      if (this.filterform.valid) {
        localStorage.removeItem('groupfilterdata');
        localStorage.removeItem('group_chipdisplayfilterdata');
        localStorage.removeItem('group_clearfilteritemkey');
        let groupfilterdata = this.getbodycontent();
        localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
        this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
      }

    }

    getbodycontent() {
      let CompanyID="";
     let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID="";
    }
    else{
     CompanyID=this.filterform.value.TasklistOwner
    }

    
      let groupfilterdata = {
        "Task List Name": this.filterform.value.TasklistName.trim(),
        "Task List Owner": CompanyID,
      }
      this.getapibodycontent();
      return groupfilterdata

    }

    getapibodycontent() {
      let companylistdataid= "";
      for (let i = 0; i < this.companylistdata.length; i++) {
        if (this.companylistdata[i].companyName + ' ' + '('+ this.companylistdata[i].companyId + ')' === this.filterform.value.TasklistOwner) {
          companylistdataid = this.companylistdata[i].companyId;
        }
      }
   
      let cacheddata = localStorage.getItem('role');
      let sharedFlag:any;
      if (cacheddata === "System Administrator") {
      this.groupapibodycontent = {
        "taskListName": this.filterform.value.TasklistName.trim(),
        "companyId": companylistdataid,
        "sharedFlag" :"No"
      }
    }else{
      this.groupapibodycontent = {
        "taskListName": this.filterform.value.TasklistName.trim(),
        "companyId": companylistdataid,
      }
    }
    }

    checkformvalue(){
      let formempty;
      if(this.filterform.value.TasklistName.trim()==="" && this.filterform.value.TasklistOwner.trim()===""){
        formempty=true;
      }
      else {
        formempty = false;
      }
      return formempty;
    }

    dynamicEnableDisablebtn(){
      let checkformfield= this.checkformvalue();
      if(checkformfield===true){
        this.disablebutton=true;
      }
      else{
        this.disablebutton=false;
      }
    }
}
