import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import {CommondataserviceService} from 'src/app/Services/commondataservice.service'
import { ViewgroupusersfilterComponent } from 'src/app/Popups/companyManagement/Viewgroupusersfilter/Viewgroupusersfilter.component';
import { UserComponent } from 'src/app/Administrator/user/user.component';

@Component({
  selector: 'app-createfromtemplate',
  templateUrl: './createfromtemplate.component.html',
  styleUrls: ['./createfromtemplate.component.scss']
})
export class CreatefromtemplateComponent implements OnInit {
// grouplist
menuTitle: any;
displayedColumnsGrouplist: string[] = ['Template ID','Template Name','ACTIONS'];
groupdataSource: any;
groupELEMENT_DATA: any = [];
group_todeletefilterdata: any = [];
group_filterdata: any = {};
group_filterbodydata: any;
group_hidbenclearbtn: any = true;
group_masterSelected: boolean = false;
group_checkedList: any = [];

public selectTabIndex: any;
totalitemlength: any;
indexfirst: any;
indexlast: any;
totalpages: any;
pagesize: any;
Rowperpage: any;
sortby: any;
sortOrder: any;
//  pagecountform: FormGroup;
loadspinner: any;
pageSizeOptions: any;
group_ids = ["idp1", "idp2", "idp3", "idp4"];
pagecountform = new FormControl();
@ViewChild(MatAutocompleteTrigger) matAutocomplete: MatAutocompleteTrigger;
dataList: any;
companyId: any;
data_details:any;
companyName: any;
groupName: any;
filterValue: any = '';
templateForm: FormGroup;
searchvalue: any = '';

constructor(private activatedRoute: ActivatedRoute, public dialog: MatDialog,public dialogclose: MatDialogRef<UserComponent>, private formBuilder: FormBuilder, private router:Router,
 private headerservice: HeaderComponent, private apiService: ApiServiceService,private CommondataserviceService:CommondataserviceService) {
 // this.menuTitle = this.activatedRoute.snapshot.paramMap.get('menuTitle');
//  let a =  this.activatedRoute.snapshot.paramMap.get('companylistData');
// this.data_details = this.CommondataserviceService.getdetails();

// //get data even after reload by not clicking view icon
// if (this.data_details['data1'] === "" || this.data_details['data1'] === undefined || this.data_details['data1'] === null) {
//   let resdata = localStorage.getItem('user_details');
//   this.dataList = JSON.parse(resdata);
//   this.companyId = this.dataList['data1'];
//   this.groupName = this.dataList['data2'];

// }
// else {
//   localStorage.setItem('user_details', JSON.stringify(this.data_details));
//   this.companyId = this.data_details['data1'];
//   this.groupName = this.data_details['data2'];
//   this.dataList = this.data_details;
// }

this.templateForm = this.formBuilder.group({
  search:[''],
});


//  this.dataList= JSON.parse(a);
 this.pagesize = '1';
 this.Rowperpage = "10";
 this.sortby = "title";
 this.sortOrder = "ASC";
 this. group_todeletefilterdata=[];
 this.Group_cacheddatadisplaychip();
 this.pageSizeOptions = [
   { id: '10', value: '10' },
   { id: '25', value: '25' },
   { id: '50', value: '50' },
   { id: '100', value: '100' },
 ];

 // this.pagecountform = this.formBuilder.group({
 //   pagecount: [''],
 // });
}

ngOnInit() {

}


// on load display chip if cached data present
Group_cacheddatadisplaychip() {
 // on relead check if existing chips selected to display on UI
 let data = localStorage.getItem("group_chipdisplayfilterdata");
 if (data === null || data === undefined || data === "") {

 } else {
   this.group_hidbenclearbtn = false;
   this.group_filterdata = JSON.parse(data);
 }
 // if filter selected call api --- else call default api
 var group_filterapibodycontent;
 try {
   group_filterapibodycontent = JSON.parse(localStorage.getItem("group_filterapibodycontent"));
   if (group_filterapibodycontent === null || group_filterapibodycontent === undefined || group_filterapibodycontent === "") {
     this.loaddefaultpaginationapi();
   }
   else {
     this.group_filterapipaginationcall(group_filterapibodycontent)
   }
 } catch (e) {
//   console.log(e)
   group_filterapibodycontent = {};    // set default value if localStorage parsing failed
 }

}

// on pagination change check if any filter item present

checkfilteredchipCache(){
 if (this.groupELEMENT_DATA.length > 0) {
   var group_filterapibodycontent;
   try {
     group_filterapibodycontent = JSON.parse(localStorage.getItem("group_filterapibodycontent"));
     if (group_filterapibodycontent === null || group_filterapibodycontent === undefined || group_filterapibodycontent === "") {
       this.grouplistPaginationApicall();
     }
     else {
       this.group_filterapipaginationcall(group_filterapibodycontent)
     }
   } catch (e) {
  //   console.log(e)
     group_filterapibodycontent = {};    // set default value if localStorage parsing failed
   }
 }
}

// grouptable -- pagination -- input
grouplistupdateManualPageinput(value, totalpagesarray){
  if(value > totalpagesarray){
    $("#pagination_viewgroupuserstable").val(totalpagesarray);
    value = totalpagesarray;
  }
  this.matAutocomplete.closePanel();
  if (parseInt(value) >=1 && parseInt(value) <=totalpagesarray) {
    this.pagesize = value;
    this.checkfilteredchipCache();
  }
  else{
    this.pagesize = totalpagesarray;
    this.checkfilteredchipCache();
  }
}
 


// grouptable -- pagination
grouplistupdateManualPage(value) {
 this.pagesize = value;
 this.checkfilteredchipCache();
}

// Table - Row per page
grouplistupdateRowManualPage(value) {
 this.Rowperpage = value;
 this.pagesize = '1';
 this.pagecountform.setValue(this.pagesize);
 this.checkfilteredchipCache();

}




grouplistonPaginateChange(arrowdirection) {
 let pagesizenumber: any;
 if (arrowdirection === "arrowright") {
   pagesizenumber = parseInt(this.pagesize) + 1;
   this.pagesize = pagesizenumber.toString();
   this.checkfilteredchipCache();

 }
 else if (arrowdirection === "arrowleft") {
   pagesizenumber = parseInt(this.pagesize) - 1;
   this.pagesize = pagesizenumber.toString();
   this.checkfilteredchipCache();

 }

}

Grouplistarrow_upward(datatype, data, id) {
 this.sortby = data;
 this.sortOrder = "ASC";
 this.sortorderCSS(id);
 this.checkfilteredchipCache();
}


Grouplistarrow_downward(datatype, data, id) {
 this.sortby = data;
 this.sortOrder = "DESC";
 console.log(id)
 this.sortorderCSS(id);
 this.checkfilteredchipCache();
}

sortorderCSS(id){
  for (var i = 0; i < this.group_ids.length; i++) {
    if (id == this.group_ids[i]) {
      let elemt = document.getElementById(id);
      elemt.style.color = '#eaa22c'
    } else {
      let elemt1 = document.getElementById(this.group_ids[i]);
      elemt1.style.color = "white";
    }
  }
 }


// default load first method
loaddefaultpaginationapi() {
let data={
  'template':true,
  // "formId":"MEA"
}
this.group_filterapipaginationcall(data)

}
// simple sort -- on load second method 
grouplistPaginationApicall() {
this.loadspinner = true;
let data={
  'template':true,
  // "formId":"MEA"
}
this.group_filterapipaginationcall(data)
}

// post --- on click sort after filter fourth method
group_filterapipaginationcall(data) {
// create bodu content for api call
this.loadspinner = true;
const endpoint1 = 'pef'
const endpoint2 = 'template';
const endpoint3 = 'search';
let pageNo = this.pagesize;
let sortby = this.sortby;
let sortOrder = this.sortOrder;
let itemPerPage = this.Rowperpage;
let body = {
  "filters": data
}
// call api get response
this.apiService.createFromTemplate(pageNo, sortby, sortOrder, itemPerPage, endpoint1, endpoint2,endpoint3, body, 'POST')
.subscribe((response) => {
this.loadspinner = false;
this.groupELEMENT_DATA = response['templateList'];
this.groupdataSource = new MatTableDataSource<any>(this.groupELEMENT_DATA);
this.groupELEMENT_DATA.map(i => { i["isSelected"] = false });

setTimeout(() => {
  this.pagecountform.setValue(this.pagesize);
  this.totalitemlength = response['data']['totalrows'];
  this.totalpages = response['data']['totalpages'];
  this.pagesize = this.pagesize;
  this.Rowperpage = this.Rowperpage;
  this.indexfirst = ((parseInt(this.pagesize) - 1) * parseInt(this.Rowperpage)) + 1;
  this.indexlast = parseInt(this.pagesize) * parseInt(this.Rowperpage) > this.totalitemlength ? this.totalitemlength : parseInt(this.pagesize) * parseInt(this.Rowperpage);
  console.log("custom.css")
  this.customCSS();
}, 1000);
},
(err) => {
this.loadspinner = false;
//  console.log(err)

});

}


customCSS() {
if (parseInt(this.pagesize) === 1) {
  $('#paginationarrowleftid12').css("color", '#c9c7c7');   // disable
  $('#paginationarrowrightidss123').css("color", '#696969'); // enable
  $('#paginationarrowleftid12').css("pointer-events", 'none');
  $('#paginationarrowrightidss123').css("pointer-events", 'auto');
  if (this.totalpages === 1) {
    $('#paginationarrowleftid12').css("color", '#c9c7c7');
    $('#paginationarrowrightidss123').css("color", '#c9c7c7');
    $('#paginationarrowleftid12').css("pointer-events", 'none');
    $('#paginationarrowrightidss123').css("pointer-events", 'none');
  }

}
else if (parseInt(this.pagesize) === this.totalpages) {
  $('#paginationarrowleftid12').css("color", '#696969');
  $('#paginationarrowrightidss123').css("color", '#c9c7c7');
  $('#paginationarrowleftid12').css("pointer-events", 'auto');
  $('#paginationarrowrightidss123').css("pointer-events", 'none');
}
else {
  $('#paginationarrowleftid12').css("color", '#696969');
  $('#paginationarrowrightidss123').css("color", '#696969');
  $('#paginationarrowleftid12').css("pointer-events", 'auto');
  $('#paginationarrowrightidss123').css("pointer-events", 'auto');
}
}

grouplistfilterbtn() {
 let element = document.getElementById("CreateCompany-content")
 element.classList.add('blur-content')
 let dialogRef = this.dialog.open(ViewgroupusersfilterComponent, {
   width: '550px',
   // height: '83vh',
   disableClose: true,
   panelClass: 'filterDialog',
   backdropClass: 'deletePopup',
   position: {
     // top: '8vh'
   }
 });

 dialogRef.afterClosed().subscribe(res => {
   let element = document.getElementById("CreateCompany-content")
   element.classList.remove('blur-content');
   this.group_todeletefilterdata = [];
   // received data from dialog-component
   // console.log(res.data);
   if (res.method === 'filter') {
     this.group_filterbodydata = res.groupapibodycontent;
     localStorage.setItem("group_filterapibodycontent", JSON.stringify(this.group_filterbodydata));

     this.group_filterdata = {};
     for (var key in res.data) {
       if (res.data.hasOwnProperty(key)) {
         var val = res.data[key];
         // console.log(key, val);
         if (val) {
           this.group_filterdata[key] = val;
           localStorage.setItem("group_chipdisplayfilterdata", JSON.stringify(this.group_filterdata));
           this.group_hidbenclearbtn = false;
         }
         if (Object.keys(this.group_filterdata).length === 0) {
           this.group_hidbenclearbtn = true;
         }
       }
     }

     // call api to load data to table;
     this.pagesize = '1';
     this.group_filterapipaginationcall(res.groupapibodycontent);

   }
 })
}


// group check item functionality




// group_checkUncheckAll() {
//  if (this.group_checkedList.length > 0 && !this.group_masterSelected) {
//    for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
//      this.groupELEMENT_DATA[i].isSelected = this.group_masterSelected;

//      for (var j = 0; j < this.group_checkedList.length; j++) {
//        if (this.group_checkedList[j].userName == this.groupELEMENT_DATA[i].userName) {
//          this.group_checkedList.splice(j, 1)
//        }
//      }
//    }
//  } else {
//    for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
//      this.groupELEMENT_DATA[i].isSelected = this.group_masterSelected;
//    }
//  }
//  this.group_getCheckedItemList();
// }

// group_isitemSelected(element) {
//  // set isChexked flag true/false
//  this.groupELEMENT_DATA.every(function (item: any) {
//    return item.isSelected == element.isSelected;
//  })

//  // check if content is selected -- if selected set variable true
//  let present = true;
//  for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
//    if (!this.groupELEMENT_DATA[i].isSelected) {
//      present = false;
//    }
//  }
//  if (present) {
//    this.group_masterSelected = true;
//  } else {
//    this.group_masterSelected = false;
//  }
//  this.group_getCheckedItemList();
// }

group_getCheckedItemList() {
 for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
   let present = false;
   for (var j = 0; j < this.group_checkedList.length; j++) {
     if (this.group_checkedList[j].formId == this.groupELEMENT_DATA[i].formId) {
       present = true;
       if (!this.groupELEMENT_DATA[i].isSelected) {
         this.group_checkedList.splice(j, 1);
       }
     }
   }
   if (this.groupELEMENT_DATA[i].isSelected) {
     if (!present) {
       this.group_checkedList.push(this.groupELEMENT_DATA[i]);
     }
   }
 }
}


print_group() {
 let prefix = '';
 let dynamic = '';
 let suffix = '';
 let prefix1 = '';
 let dynamic1 = '';
 let suffix1 = '';
 let closetag = '';


 prefix += '<html><head><link href="https://fonts.googleapis.com/css2?family=Open+Sans:&display=swap"> <meta name="viewport" content="width=device-width, initial-scale=0.8"/> <style> th:nth-child(odd){ background-color:#143249; color-adjust: exact; /*firefox & IE */ -webkit-print-color-adjust: exact; height: 50px; border-left: 1px solid #143249; border-right: 1px solid #143249;} th:nth-child(even){ background-color:#143249; color-adjust: exact; /*firefox & IE */ -webkit-print-color-adjust: exact;height: 50px;border-left: 1px solid #143249; border-right: 1px solid #143249;}.status { font-family: "Open Sans", sans-serif; font-size: 12px; font-weight: 800; font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: normal; color: #ffffff; background-color: #1b405c; -webkit-print-color-adjust: exact; height: 35px; display: flex; align-items: center; border-radius: 5px; margin-left: 10px; margin-right: 10px;  justify-content:center; }td{padding-bottom:10px}.tablecontent { font-family: "Open Sans", sans-serif; padding-top:10px; font-size: 14px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: normal; color: #0e0e0e; text-align: center; word-wrap: break-word;}.userlist{font-family: "Open Sans", sans-serif;font-size: 18px;font-weight: bold;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #143249;padding-top: 2%;padding-left: 15px;padding-bottom: 5px;} </style></head><body><div style="overflow:visible"><div class="userlist">Group List</div>'
 prefix += '<table width="670" cellpadding="0" border="0" align="center" cellspacing="0" style="width:100%">'
 prefix += '<tr>'
 prefix += '<th><div class="status">GROUP NAME</div></th>'
 prefix += '<th><div class="status">NO OF USERS</div></th> '
 prefix += '<th><div class="status">COMPANY ID</div></th> '
 prefix += '<th><div class="status">STATUS</div></th><th></tr>'
 let print_data: any = [];
 if (this.group_checkedList.length != 0) {
   print_data = this.group_checkedList;
 } else {
   print_data = this.groupELEMENT_DATA;
 }

 print_data.forEach(element => {
   dynamic += '<tr>'
   dynamic += '<td><div class="tablecontent">' + element.groupName + ' </div</td>'
   dynamic += '<td><div class="tablecontent">' + element.noOfUsers + '</div></td>'
   dynamic += '<td><div class="tablecontent">' + element.companyId + '</div></td>'
   dynamic += '<td><div class="tablecontent">' + element.groupStatus + ' </div</td>'
   dynamic += '</tr>'
 });
 suffix += '</table>'

 closetag += '</div> </body></html>';

 var frame1 = document.createElement('iframe');
 frame1.name = "frame1";
 frame1.style.position = "absolute";
 frame1.style.top = "-1000000px";
 document.body.appendChild(frame1);
 var frameDoc = frame1.contentWindow;
 frameDoc.document.open();
 frameDoc.document.write(`<html><head><title></title>`);
 frameDoc.document.write('</head><body>');
 frameDoc.document.write(prefix + dynamic + suffix + closetag);
 frameDoc.document.write('</body></html>');
 frameDoc.document.close();
 var instance = this;

 setTimeout(function () {
   window.frames["frame1"].focus();
   window.frames["frame1"].print();
   document.body.removeChild(frame1);
 }, 500);
 return false;
}


CreateGroups(){
 let data = "Users >" + 'Create group';
 // route to particular component but set menutitle as Users as Users sidemenu has to be selected
 this.router.navigate(['user/Create_group'], {queryParams: { menuTitle: 'Users' }});

 // set the local storage and call the header component to set the breadcrumb.
 localStorage.setItem("selectTabIndexName", 'Create group');
 localStorage.setItem("selectTabBreadCrumbs", data);
 setTimeout(() => {
   this.headerservice.updateHeaderBreadcrumb("Users", data);
 }, 500);
}

cancelbtn() {
  localStorage.removeItem('group_filterapibodycontent');
  // if filterdata not present --- on first load cancel - clear data
  this.dialogclose.close({ method: 'close' })
}
search(searchinput) {
  let data={
    'template':true,
    "formId":searchinput
  }
  localStorage.setItem("group_filterapibodycontent", JSON.stringify(data));
  this.group_filterapipaginationcall(data)
}
clearTable(searchinput){
  this.searchvalue=searchinput;
  if(searchinput === ""){
    let data={
      'template':true,
      "formId":searchinput
    }
    this.group_filterapipaginationcall(data)
  }
}
launch(){
  
}

editFromDetails(formId,version){
  localStorage.setItem("formId", formId);
  localStorage.setItem("version", version);
  localStorage.setItem("frompage", 'view');
  this.apiService.user_details('', 'Edit');

  let data = "Assignable Content > Form Details";
  // route to particular component but set menutitle as Users as Users sidemenu has to be selected
  this.router.navigate(['AssignableContent/Formstemplate'], { queryParams: { menuTitle: 'AssignableContent' } });

  // set the local storage and call the header component to set the breadcrumb.
  localStorage.setItem("selectTabIndexName", 'Form Details');
  localStorage.setItem("selectTabBreadCrumbs", data);

  setTimeout(() => {
    this.headerservice.updateHeaderBreadcrumb("Form Details", data);
  }, 500);
  this.cancelbtn()
}

EnterSubmit(event){
  if(event.keyCode === 13){
    this.search(this.searchvalue);
    event.preventDefault();
  }
}

}