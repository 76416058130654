import { Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserComponent } from 'src/app/Administrator/user/user.component';


@Component({
  selector: 'app-changemanager',
  templateUrl: './changemanager.component.html',
  styleUrls: ['./changemanager.component.scss']
})
export class ChangemanagerComponent implements OnInit {
  changeManagerform: FormGroup;
  managerData=[];
  selected_item:any=[];
  loadspinner: any = false;
  updateManager:any;
  sel_manager_list:any=[];
  managerlist: any;
  disablebutton:any;
  managet_list: any = [];
  
  cachedcompanyname: any;
  ExistingManager: any;
  ManagerNew: any;
  loginName: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog,  public dialog: MatDialogRef<UserComponent>, private formBuilder: FormBuilder, public _Service:ApiServiceService,) { 

    this.changeManagerform = this.formBuilder.group({
      ExistingManager: [''],
      NewManager: [''],
   
    });

this.sel_manager_list= data['managerData'];
 if(this.sel_manager_list.length!=0){
  let value= this.sel_manager_list[0].name + ' (' + this.sel_manager_list[0].loginName + ')'
  this.changeManagerform.patchValue({
    ExistingManager: value,

   //loginName
  })
  
  this.getManager(this.sel_manager_list[0].companyId)
 }

// console.log(this.sel_manager_list)

  }
  ngOnInit(): void {
   // this.ExistingManager = this.sel_manager_list.name;

  }


  getManager(value) {
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    const queryparameter1 = value;
    this._Service.getwithparam(queryparameter1, endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.managet_list = response["managers"];
        for(let i=0;i< this.managet_list.length;i++){
          if (this.managet_list[i].displayName == this.sel_manager_list[0].name){
            this.managet_list.splice(i, 1)
          }
        }
        this.managet_list=this.managet_list;
      

      })
  }

  selectManager(event){
this.loginName= event;
  }

  managerChange(){
    this.loadspinner=true;

    let body={
      "existingManager":this.sel_manager_list[0].loginName,
      "newManager":this.loginName,
      "companyId":this.sel_manager_list[0].companyId,
        }
    const endpoint = 'users/' + 'updateManager';  

    this._Service.change_manager(endpoint, body).subscribe(response => {
      this.loadspinner = false;
      if (response["message"] === "success") {
        this.dialog.close('updateSuccess');
        let element = document.getElementById("manager-content")
        element.classList.remove('blur-content');
    }   else{
      this.loadspinner = false;

    let data = {
      status: response["message"]
    };
  }

},
(err) => {
  this.loadspinner = false;
 //  console.log(err)

 });

  }
  
  checkformvalue() {
    let formempty;
    if (this.changeManagerform.value.newManager.trim() === "" && this.changeManagerform.value.Name.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }
  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }
  close(){
  
    this.dialog.close();
    let element = document.getElementById("manager-content")
    element.classList.remove('blur-content');
  
}
}
