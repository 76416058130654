import { Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';


@Component({
  selector: 'app-reqlicense',
  templateUrl: './reqlicense.component.html',
  styleUrls: ['./reqlicense.component.scss']
})
export class ReqlicenseComponent implements OnInit {
  from:any='';
  loadspinner: boolean;
  company_Id: any;
  loginId: string;
  successBack='';
  licenseNumber: number;
  invalidlicenseNumber: any= false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, public dialog: MatDialogRef<CompanyManagementComponent>, private formBuilder: FormBuilder, public _Service:ApiServiceService,) { 


  this.from=data['from'];
  this.company_Id= data['companyId'];
   this.loginId = localStorage.getItem('loginId');



  }
  reqLicenseForm: FormGroup;
  ngOnInit(): void {
    this.reqLicenseForm = this.formBuilder.group({
      licenseNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      commentSection: ['',[Validators.maxLength(50)]],

    })
    this.reqLicenseForm.controls.commentSection.markAsTouched()
  }


  close(){
  
      this.dialog.close();
      let element = document.getElementById("CreateCompany-content")
      element.classList.remove('blur-content');
    
  }
  proceed(){
    
    this.from= "license_request"
        
   }

   invalidlicenseNumbers(){
    if (this.reqLicenseForm.controls.licenseNumber.value == 0 || this.reqLicenseForm.controls.licenseNumber.value > 50){
      this.invalidlicenseNumber = true;
    }else{
      this.invalidlicenseNumber= false;
    }
   }
   req_license(){
    if (this.reqLicenseForm.valid){
   
this.successBack='';
   var body={
     "companyId": this.company_Id,
     "requestorName" : this.loginId,
     "licenseRequested": this.reqLicenseForm.controls.licenseNumber.value,
     "requestorComment" :this.reqLicenseForm.controls.commentSection.value,
   }
   this.loadspinner = true;
   const endpoint = 'license';
   this._Service.license_number(endpoint, body).subscribe(response => {
     this.loadspinner = false;
     if (response["message"] === "Success") {
       this.successBack='success';
       this.dialog.close(this.successBack);
       let element = document.getElementById("CreateCompany-content")
       element.classList.remove('blur-content');
     } else {
       this.successBack='';
     this.loadspinner = false;

       let data = {
         status: response["message"]
       };
       this.dialogopen.open(DialogpopupComponent, {
         width: '550px',
         disableClose: true,
         data: {
           from: 'error-popup',
           name: data
         },
         position: {
           top: '40vh'
         }
       });

 }
})
  
}
 }
  }
