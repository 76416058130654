<div class="filtermaincontainer">
  <div class="row headerrow">
    <div class="col-10">
      <span class="heading" *ngIf="this.from==='licencepending'">&nbsp;&nbsp;View Comment</span>
      <span class="heading" *ngIf="this.from==='licenceApproval'">&nbsp;&nbsp;View Comments</span>
    </div>
    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname" *ngIf="this.from==='licencepending'">Requestor Comment</mat-label>
        <mat-label class="labelname" *ngIf="this.from==='licenceApproval'">Requestor Comments</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
            <textarea matInput class="textarea" formControlName="RequestorComments" readonly></textarea>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-12 inputcolumn" style="margin-top: -2%;" *ngIf="this.from==='licenceApproval' && status==='Denied'">
        <mat-label class="labelname">Reason for Denial</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
            <textarea matInput class="textarea" formControlName="ReasonforDenial" readonly></textarea>
        </mat-form-field>

      </div>

      <div class="col-12 col-md-12 inputcolumn" style="margin-top: -2%;" *ngIf="this.from==='licenceApproval' && status==='Approved'">
        <mat-label class="labelname">Invoice Number</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
            <textarea matInput class="textarea" formControlName="InvoiceNumber" readonly style="height:40px"></textarea>
        </mat-form-field>

      </div>


      <div class="col-12 col-md-12 inputcolumn" style="margin-top: -2%;" *ngIf="this.from==='licenceApproval' && status==='Approved'">
        <mat-label class="labelname">Approver Comments</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
            <textarea matInput class="textarea" formControlName="approvercomment" readonly></textarea>
        </mat-form-field>

      </div>

      </div>

      <div class="row">

        <div class="col-12 Filterbtncol">
          <button mat-button id="btnfiltertermid" class="Filterbtn" (click)="cancelbtn()">
            <mat-icon class="filterbtnicon">done</mat-icon>
            Close
          </button>
        </div>
      </div>

      </form>

    </div>

