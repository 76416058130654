import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-managepermissionfilter',
  templateUrl: './managepermissionfilter.component.html',
  styleUrls: ['./managepermissionfilter.component.scss']
})
export class ManagepermissionfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  roles_list: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton:any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<CompanyManagementComponent>) {

    this.filterform = this.formBuilder.group({
    Permissiongroupname: [''],
    Role: [''],
  });

  this.apicallgetdefaultdropdown();
}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
apicallgetdefaultdropdown() {
  // get default value on load companylist -- api call
  this.loadspinner = true;
  const endpoint1 = 'lookup';
  this.apiService.Simpleget(endpoint1, 'GET')
    .subscribe((response) => {
      this.loadspinner = false;
      // this.roles_list = [
      //   { "id": "1", "name": "System Administrator" },
      //   { "id": "2", "name": "Primary Administrator" },
      //   { "id": "3", "name": "Company Administrator" },
      //   { "id": "4", "name": "Manager" },
      //   { "id": "5", "name": "Evaluator" },
      //   { "id": "6", "name": "Proctor" },
      //   { "id": "7", "name": "Learner" }
      // ]
      let roles = [
        { "id": "1", "name": "System Administrator" },
        { "id": "2", "name": "Primary Administrator" },
        { "id": "3", "name": "Company Administrator" },
        { "id": "4", "name": "Manager" },
        { "id": "5", "name": "Evaluator" },
        { "id": "6", "name": "Proctor" },
        { "id": "7", "name": "Learner" }
      ]
      this.roles_list = this.dynamicrolesfunction(roles);
      this.setdefaultdropdownvalues();


    },
      (err) => {
        this.loadspinner = false;
       // console.log(err)

      });
}
dynamicrolesfunction(roles) {

  let cacheddata = localStorage.getItem('role');
  let rolearray = [];
  if (cacheddata === "System Administrator") {
    rolearray = [
      { "id": "1", "name": "System Administrator" },
      { "id": "2", "name": "Primary Administrator" },
      { "id": "3", "name": "Company Administrator" },
      { "id": "4", "name": "Manager" },
      { "id": "5", "name": "Evaluator" },
      { "id": "6", "name": "Proctor" },
      { "id": "7", "name": "Learner" }
    ]
  }
  if (cacheddata === "Primary Administrator") {
    rolearray = [
      { "id": "2", "name": "Primary Administrator" },
      { "id": "3", "name": "Company Administrator" },
      { "id": "4", "name": "Manager" },
      { "id": "5", "name": "Evaluator" },
      { "id": "6", "name": "Proctor" },
      { "id": "7", "name": "Learner" }
    ]
  }

  if (cacheddata === "Company Administrator") {
    rolearray = [
      { "id": "3", "name": "Company Administrator" },
      { "id": "4", "name": "Manager" },
      { "id": "5", "name": "Evaluator" },
      { "id": "6", "name": "Proctor" },
      { "id": "7", "name": "Learner" }
    ]
  }


  return rolearray;
}

setdefaultdropdownvalues() {
  let rolecacheddata = localStorage.getItem('role');
  let cacheddata = localStorage.getItem('filterdata');
  if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
     this.disablebutton=true;
   if (rolecacheddata != "System Administrator") {
    setTimeout(() => {
      this.filterform.controls["Role"].setValue(rolecacheddata);
      this.disablebutton=false;
    }, 500);
  }
  }
  // if cached data present -- once filter btn was clicked
  else {
    this.disablebutton=false;
    let data = JSON.parse(cacheddata);
    this.filterform.controls['Permissiongroupname'].setValue(data["Permission Group Name"]);
    this.filterform.controls['Role'].setValue(data["Role Name"]);
    this.clearfilterfunction();
  }



}

clearfilterfunction() {
  // if filterchip to remove filter is selcted remove the data on modal
  let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
  if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
  }
  else {
    for (let i = 0; i < clearfilteritemkey.length; i++) {
      if(clearfilteritemkey[i]==="Permission Group Name"){
        this.filterform.controls['Permissiongroupname'].setValue("");
      }
      else if(clearfilteritemkey[i]==="Role Name"){
        this.filterform.controls['Role'].setValue("");
      }
      else{
        this.filterform.controls[clearfilteritemkey[i]].setValue("");
      }

    }
  }
}


selectRoleName(value){
  if(value==="" || value === undefined || value===null){
    // if none select set value as ''
    this.filterform.controls['Role'].setValue("");
    this.dynamicEnableDisablebtn();
  }
  else{
    this.disablebutton=false;
  }
}

 // on keyup enable filter button
 inputchangePermissiongroupname(value){
  this.dynamicEnableDisablebtn();
}

cancelbtn() {
  // if filterdata not present --- on first load cancel - clear data
  let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
  if (data === "" || data === undefined || data === null) {
  }
  else {
    let groupfilterdata = this.getbodycontent();
    localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
    localStorage.removeItem('clearfilteritemkey');
  }
  this.dialog.close({ method: 'close' })
}


Filter(){
  if (this.filterform.valid) {
    localStorage.removeItem('filterdata');
    localStorage.removeItem('chipdisplayfilterdata');
    localStorage.removeItem('clearfilteritemkey');
    let filterdata = this.getbodycontent();
    localStorage.setItem('filterdata', JSON.stringify(filterdata));
    this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
  }

}

EnterSubmit(event){
  if(event.keyCode === 13){
   //this.Filter();
   this.dynamicEnableDisablebtn();
   if(this.disablebutton===true){
    event.preventDefault();
   }
   else{
    event.preventDefault();
    this.Filter();
   }

  }
}

getbodycontent() {
  let filterdata = {
    "Permission Group Name": this.filterform.value.Permissiongroupname.trim(),
    "Role Name": this.filterform.value.Role.trim(),
  }
  this.getapibodycontent();
  return filterdata

}

getapibodycontent() {
  let compId = localStorage.getItem('procCompId');
  let rolecacheddata = localStorage.getItem('role');
  if (rolecacheddata != "System Administrator") {
    this.apibodycontent = {
      "groupName": this.filterform.value.Permissiongroupname.trim(),
      "roleName": this.filterform.value.Role.trim(),
      "companyId":compId
    }
  }
    else{
      this.apibodycontent = {
        "groupName": this.filterform.value.Permissiongroupname.trim(),
        "roleName": this.filterform.value.Role.trim(),
        "companyId":""
      }
    }
  }




checkformvalue(){
  let formempty;
  if(this.filterform.value.Permissiongroupname.trim()==="" && this.filterform.value.Role.trim()==="" ){
    formempty=true;
  }
  else {
    formempty = false;
  }
  return formempty;
}

dynamicEnableDisablebtn(){
  let checkformfield= this.checkformvalue();
  if(checkformfield===true){
    this.disablebutton=true;
  }
  else{
    this.disablebutton=false;
  }
}

}

