<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
    <div class="row headerrow">
        <div class="col-10">
          <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
          <span class="heading">&nbsp;&nbsp;Filter</span>
        </div>
    
        <div class="col-2 cleariconcol">
          <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
        </div>
      </div>
      <hr style="width: 549px; margin-top: -7px; margin-left: -23px;">

      <form [formGroup]="filterform">
          
  <div class="row">
    <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Task Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpgroupname (keyup)="inputchangeCompanyName(inpgroupname.value)" class="inputClassText"
            formControlName="taskName">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Task List Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpgroupID (keyup)="inputchangePrimaryAdmin(inpgroupID.value)" class="inputClassText"
            formControlName="taskListName">
        </mat-form-field>

      </div>

  </div>
  <div class="row">
    <div class="col-6 cancelbtncol"><br><br>
      <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-6 Filterbtncol"><br><br>
      <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
        <mat-icon class ="filterbtnicon">done</mat-icon>
        Filter
      </button>
    </div>
  </div>
      </form>
</div>