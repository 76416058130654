<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>
<div *ngIf="from=='license'" class="box blur-conten filtermaincontainer" id="filtermaincontainer">
    <div class="row header">
      <img src="assets/images/license.svg" alt="filter">
    </div>

    <div class="row title">
      <p>Request Additional Licenses</p>
    </div>
    <div class="row sub-title" id="subtitleid">
      <p>A maximum number of 50 licenses can be requested.<br>
        Adding licenses will incur a fee.<br>
        Do you wish to proceed?</p>
    </div>

    <div class="row" style="margin-top: -13px; margin-bottom: 35px;">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
        <button mat-button class="cancel-btn" (click)="close()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
        <button mat-button class="Filterbtn" (click)="proceed()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Proceed
        </button>
      </div>
    </div>
</div>


<!--license request 2nd pop-up-->
<form [formGroup]="reqLicenseForm">
    <div *ngIf="from=='license_request'" class="box blur-conten filtermaincontainer" style="border-radius: 8px;" id="filtermaincontainer">
      <div class="row headr">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec">
          <img src="assets/images/license (1).svg">
          <span class="titles">License Request</span>

          <button type="button" class="close" aria-label="Close" (click)="close()">
            <mat-icon class="clear-btn">clear</mat-icon>
          </button>
        </div>
      </div>

      <hr style="width: 528px; margin-left: -25px;">


      <div class="col-6 col-md-10 inputcolumn">
        <mat-label id="usernameid" class="labelname"><span class="required"> * </span>License Request </mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" class="textinputusertablefilter" (keyup)=invalidlicenseNumbers()  formControlName="licenseNumber">
          <mat-error class="errorlabelname"
          *ngIf="reqLicenseForm.get('licenseNumber').hasError('required')">
          <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
          &nbsp;
          <span>Please enter License Number</span>
          </mat-error>
          <mat-error *ngIf="reqLicenseForm.get('licenseNumber').hasError('pattern')" class="mat-error">
            <img src="assets/images/error.svg" class="errormsgicon" alt="error icon"> &nbsp;
            Only numbers are allowed!
          </mat-error>
        </mat-form-field>

      </div>

      <div class="col-6 col-md-10 inputcolumn">
        <mat-label id="usernameid" class="labelname">Additional Comments</mat-label>
        <mat-form-field appearance="outline" id="formfieldPortabilityID" class="formfieldusertable1">
          <input formControlName="commentSection" matInput placeholder="Enter here" autocomplete="off" class="textinputusertablefilter">
          <mat-error *ngIf="reqLicenseForm.get('commentSection').hasError('maxlength')" class="mat-error">
          <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
          &nbsp;
        The maximum input limit is of 50 characters.
          </mat-error>
        </mat-form-field>

      </div>

      <div class="row" style="margin-right: 35px;  margin-bottom: 35px;">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
          <button mat-button class="cancel-btn" (click)="close()">
            <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
            Cancel
          </button>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
          <button  *ngIf="!invalidlicenseNumber" mat-button class="Filterbtn" (click)="req_license()">
            <mat-icon class="filterbtnicon">done</mat-icon>
            Submit
          </button>
          <button *ngIf="invalidlicenseNumber" mat-button class="Filterbtn disabled" style="opacity: 0.5;">
            <mat-icon class="filterbtnicon">done</mat-icon>
            Submit
          </button>
        </div>
      </div>
    </div>
</form>
