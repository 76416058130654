import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { CommondataserviceService } from "../../../Services/commondataservice.service";
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-importcontent',
  templateUrl: './importcontent.component.html',
  styleUrls: ['./importcontent.component.scss'],
  providers: [DatePipe]
})
export class ImportcontentComponent implements OnInit {
  @ViewChild('yourInput', {static: false}) yourInput: ElementRef;
  @ViewChild('fileInput', {static: false})
  
  InputVar: ElementRef;
  loadspinner=false;
  disablebutton=true;
  show_screen='import';
  file:any='';
  fileName:any='';
  path:any='';
  complete_progress=false;
  courseid:any='';
  coursename:any='';
  from: any;
  trainingstatus:any;
  filterform: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string,public httpClient: HttpClient, public dialogopen: MatDialog, private headerservice: HeaderComponent, private router: Router, private datePipe: DatePipe,private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>,private CommondataserviceService:CommondataserviceService) {
    this.from = data['from'];

    this.filterform = this.formBuilder.group({
      CreationDate: [''],
      trainingstatus: [''],
      Comments:[''],
    });
  }

  ngOnInit(): void {
  }

  cancel_image(){
    const dialogRef = this.dialogopen.open(DialogpopupComponent, {
      width: '530px',
      height: '330px',
      disableClose: true,
      data:{
        from:'image-cancel'
      }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        });
    }

  cancelbtn(){
    this.dialog.close();
  }

  import(){
    this.show_screen='progress';
    this.import_file();
  }

  onFileSelect(event){
    if (event.target.files.length > 0) {
      
      this.file = event.target.files[0];
      this.fileName=event.target.files[0].name;
      this.path=event.target.files[0].path;
      let filepath=this.fileName.split('.');
      let fileExtention=filepath[filepath.length-1];
      if( fileExtention.toLowerCase() !='zip' ){
       this.file='';
       this.fileName='';
       this.InputVar.nativeElement.value = "";
       this.disablebutton=true;
      this.open_modal('Format not supported', '(Only zip files are allowed)');
    }else{
      this.disablebutton=false;
     
    }
  }
}

open_modal(title, subtitle){
  const dialogRef = this.dialogopen.open(DialogpopupComponent, {
    width: '530px',
    height: '330px',
    data:{
      from:'import-image-size',
      title:title,
      subtitle:subtitle
    }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.show_screen='import';
      this.complete_progress=false;
      });
  }
  import_file(){
   // this.loadspinner = true;
   
    const formData = new FormData();
    formData.append('file', this.file);
   
    this.httpClient.post<any>(this.apiService.filepeekurl, formData, {
    }).subscribe(
      (res) => {
        
       this.complete_progress=true;
     if(res.message=='failure'){
    
       this.open_modal('Please select the proper course package', '');
     }else{
       this.show_screen='next';
       this.courseid=res.courseId;
       this.coursename=res.courseTitle;
     }
      },
      (err) => {
        
       // this.loadspinner = false;

      }
    );
  }
  next(){
    this.show_screen='next';
  }
convert(){
  this.fileTobase64url2(this.file)
}
fileTobase64url2(file){
  this.getBase64(file).then(
    data =>{console.log(data);
     this.file=data;
     this.submit()
    });
}

getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
 
      submit() {
        this.dialog.close();
        // console.log("create")
        //this.router.navigate(['user/Addtraining'], {queryParams: { menuTitle: 'Users' }});
        // localStorage.setItem("companyId", element.companyId);
        let data = "Assignable Content > Create Course";
        // route to particular component but set menutitle as Users as Users sidemenu has to be selected
        this.router.navigate(['AssignableContent/Createcourse'], { queryParams: { menuTitle: 'AssignableContent' } });
        localStorage.setItem("import_courseid", this.courseid);
        localStorage.setItem("import_coursename", this.coursename);
        localStorage.setItem("course_from", 'scrom_import');
       // localStorage.setItem("coursepackage_file", this.file);
       this.CommondataserviceService.set_importcontent(this.file);
        // set the local storage and call the header component to set the breadcrumb.
        localStorage.setItem("selectTabIndexName", 'Create Course');
        localStorage.setItem("selectTabBreadCrumbs", data);
    
        setTimeout(() => {
          this.headerservice.updateHeaderBreadcrumb("Create Course", data);
        }, 500);
        console.log("create")
      }

    Publish(){
      let date=this.datePipe.transform(this.filterform.value.CreationDate,"MM-dd-yyyy");
      let filterdata = {
        "CreationDate": date,
        "trainingstatus": this.filterform.value.trainingstatus,
        "Comments": this.filterform.value.Comments
      }

      console.log(filterdata);
      this.dialog.close();
      
    }
}