import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-isntransfer-filter',
  templateUrl: './isntransfer-filter.component.html',
  styleUrls: ['./isntransfer-filter.component.scss'],
})
export class IsntransferFilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton:any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<CompanyManagementComponent>) {
    this.filterform = this.formBuilder.group({
      CompanyName: [''],
      CompanyId: [''],
      CompanyStatus: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }


  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Enabled" },
          { "status_id": 2, "statusName": "Disabled" },
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['CompanyName'].setValue(data["Company Name"]);
      this.filterform.controls['CompanyId'].setValue(data["Company (COID)"]);
      this.filterform.controls['CompanyStatus'].setValue(data["Status"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Company Name"){
          this.filterform.controls['CompanyName'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Company (COID)"){
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Status"){
          this.filterform.controls['CompanyStatus'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  selectCompanyID(value) {
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else{
      this.disablebutton=false;
    }
  }

  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['CompanyStatus'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else{
      this.disablebutton=false;
    }
  }

   // on keyup enable filter button
  inputchangeCompanyName(value){
    this.dynamicEnableDisablebtn();
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }


  Filter(){
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  getbodycontent() {
    let filterdata = {
      "Company Name": this.filterform.value.CompanyName.trim(),
      "Company (COID)": this.filterform.value.CompanyId.trim(),
      // "Primary Admin": this.filterform.value.PrimaryAdmin.trim(),
      "Status": this.filterform.value.CompanyStatus.trim(),
    }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    let companylistdataid= "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' +'('+ this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    let status;
    if(this.filterform.value.CompanyStatus == "Enabled"){
      status = "1"
    } else if(this.filterform.value.CompanyStatus == "Disabled"){
      status = "0"
    }
    this.apibodycontent = {
    "id":"",
    "companyName":this.filterform.value.CompanyName.trim(),
    "companyId": companylistdataid,
    "companyStatus": status,
    "companyIsnNumber": ""  
    }
  }


  checkformvalue(){
    let formempty;
    if(this.filterform.value.CompanyName.trim()==="" && this.filterform.value.CompanyId.trim()==="" &&
  this.filterform.value.CompanyStatus.trim()==="" ){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

}
