<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>



<div>
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/isnlog.svg" alt="logs">
      <span class="heading">&nbsp;&nbsp;Logs</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <div class="tableheight">
    <table mat-table [dataSource]="dataSource" #logstable style="width: 100%" matSort>

        <ng-container matColumnDef="executedDate">
            <th mat-header-cell *matHeaderCellDef class="headerdata">
              <div class="status">
                <span class="spanstatus">Executed Date</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="group_tablecontent">{{element.executedDate | date: 'MM/dd/yyyy h:mm:ss a'}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="statusmessage">
            <th mat-header-cell *matHeaderCellDef class="headerdata">
              <div class="status">
                <span class="spanstatus">STATUS</span>
                &nbsp;
  
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="tablecontent"  >
                <div class="tablecontent" style="color:#069314;font-weight: bold;" *ngIf="element.statusmessage=='Success'">{{element.statusmessage}}</div>
                <div class="tablecontent" style="color:#da3131;font-weight: bold;" *ngIf="element.statusmessage=='Failed'">{{element.statusmessage}}</div>
              </div>
            </td>
          </ng-container>

     

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background-color: #143249"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

</div>
