import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AssignableContentComponent } from 'src/app/Administrator/assignable-content/assignable-content.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-addremovecoursefilter',
  templateUrl: './addremovecoursefilter.component.html',
  styleUrls: ['./addremovecoursefilter.component.scss'],
  providers: [DatePipe]
})
export class AddremovecoursefilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  category: any = [""];
  type: any = [""];
  groupapibodycontent: any;
  catalogId:any;

  loadspinner: any = false;
  apibodycontent: any;
  disablebutton:any;
  constructor(private datePipe: DatePipe,private formBuilder: FormBuilder, private apiService: ApiServiceService,public dialog: MatDialogRef<AssignableContentComponent>) {
    this.catalogId=localStorage.getItem('assignedCatalogId');
    this.filterform = this.formBuilder.group({
      title: [''],
      CompanyId: [''],
      CompanyType: [''],
      Category: ['']
    });
    this.apicallgetdefaultdropdown();
  }


  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'course';
    const endpoint2 = 'getowners/'  +  this.catalogId;
    this.apiService.getOwnerCatalog(endpoint1, endpoint2, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response;
        this.category = [
          { "categoryName": "eCourse" },
          { "categoryName": "Curriculum" },
        ]
        this.type = [
          {"typeName": "TNG" },
          {"typeName": "KNT" },
          {"typeName": "Video" },
          {"typeName": "Other" },
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['title'].setValue(data["title"]);
      this.filterform.controls['CompanyId'].setValue(data["owner"]);
      this.filterform.controls['CompanyType'].setValue(data["type"]);
      this.filterform.controls['Category'].setValue(data["category"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="title"){
          this.filterform.controls['title'].setValue("");
        }
        else if(clearfilteritemkey[i]==="owner"){
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if(clearfilteritemkey[i]==="type"){
          this.filterform.controls['CompanyType'].setValue("");
        }
        else if(clearfilteritemkey[i]==="category"){
          this.filterform.controls['Category'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  // on keyup enable filter button
  inputchangegroupname(value){
    this.dynamicEnableDisablebtn()
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
    
  }

  selectCompanyID(value) {
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else{
      // this.checkformvalue()
      this.disablebutton=false;
    }
  }

  selectCategory(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['Category'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      // this.checkformvalue();
      this.disablebutton=false;
    }
  }

  selectType(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['CompanyType'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      // this.checkformvalue();
      this.disablebutton=false;
    }
  }

  checkformvalue(){
    let formempty;
    if(this.filterform.value.Category.trim()==="" && this.filterform.value.CompanyId.trim()==="" &&
    this.filterform.value.CompanyType.trim()==="" && this.filterform.value.title.trim()===""){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let filterdata = {
      "type":this.filterform.value.CompanyType.trim(),
      "title": this.filterform.value.title.trim(),
      "category":this.filterform.value.Category.trim(),
      "owner": this.filterform.value.CompanyId.trim()
    }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    let companylistdataid= "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i] === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i];
      }
    }
    
    this.groupapibodycontent = {
      "id":"",
      "requirementId":"",
      "type": this.filterform.value.CompanyType.trim(),
      "title": this.filterform.value.title.trim(),
      "owner": companylistdataid,
      "category": this.filterform.value.Category.trim(),
      "catalogId":this.catalogId
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

 EnterSubmit(event){
  if(event.keyCode === 13){
    this.dynamicEnableDisablebtn();
   if(this.disablebutton===true){
    event.preventDefault();
   }
   else{
    event.preventDefault();
    this.Filter();
   }

  }
}

}
