<div class="filtermaincontainer">
  <div class="row headerrow row-padding">
    <div class="col-10">
      <span class="heading" >&nbsp;&nbsp;View Comments</span>
    </div>
    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row row-padding">
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname" >Version Number : {{version_number}}</mat-label>
      </div>
      </div>
    <div class="row row-padding">
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname" >Comments</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
            <textarea matInput class="textarea" formControlName="RequestorComments" readonly></textarea>
        </mat-form-field>
      </div>
      </div>

      <div class="row">

        <div class="col-12 Filterbtncol">
          <button mat-button id="btnfiltertermid" class="Filterbtn" (click)="cancelbtn()">
            <mat-icon class="filterbtnicon">done</mat-icon>
            Close
          </button>
        </div>
      </div>

      </form>

    </div>

