<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Login</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpLogin
            (keyup)="inputchangeLogin(inpLogin.value)" class="textinputusertablefilter" formControlName="Login">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpname (keyup)="inputchangeName(inpname.value)"
            class="textinputusertablefilter" formControlName="Name">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Portability ID</mat-label>
        <mat-form-field appearance="outline" id="formfieldPortabilityID" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpportableID
            (keyup)="inputchangeinpportableID(inpportableID.value)" class="textinputusertablefilter"
            formControlName="PortabilityID">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Emp ID</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" #inpEmpID (keyup)="inputchangeinpEmpID(inpEmpID.value)"
            class="textinputusertablefilter" formControlName="EmpID">
        </mat-form-field>

      </div>


      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Company (COID)</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="CompanyId" placeholder="Company (COID)" class="inputclassselect" panelClass="myPanelmatselectClassCOID"
            (selectionChange)="selectCompanyID($event.value, 'onchange')" disableOptionCentering  [disabled]="isDisabled">
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of companylistdata"
              [value]="item.companyName + ' ' +'('+ item.companyId + ')'">
              <div class="matoptiontxt">{{item.companyName}}&nbsp;({{item.companyId}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>


      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">User Role</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select #select formControlName="UserRole" placeholder="User Role" panelClass="myPanelmatselectClass"
            class="inputclassselect" multiple (selectionChange)=selectUserRole($event.value)
            disableOptionCentering>
            <div class="select-all">
              <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                (change)="toggleAllSelection()" class="selectall create_select"> &#160;All</mat-checkbox>
            </div>

            <mat-option *ngFor="let item of roleslist" [value]="item.roleName" class="create_select"
              (click)="optionClick()">
              <div class="matoptiontxt">{{item.roleName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>


      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Status" placeholder="Status" panelClass="myPanelmatselectClass"
            class="inputclassselect" (selectionChange)=selectStatus($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of statuslist" [value]="item.statusName">
              <div class="matoptiontxt">{{item.statusName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>


      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Groups</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Groups" placeholder="Groups" panelClass="myPanelmatselectClass"
            class="inputclassselect" (selectionChange)=selectGroups($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of groupslist" [value]="item.groupName">
              <div class="matoptiontxt">{{item.groupName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>


      <div class="col-6 col-md-4 inputcolumn">
        <mat-label class="labelname">Creation Date</mat-label><br>
        <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">
          <input matInput [matDatepicker]="picker" (dateChange)=changeCreationDate($event.value) (click)="picker.open()"
            [max]="today" style="cursor: pointer;" class="textinputusertablefilterdate" placeholder="mm/dd/yyyy"
            formControlName="CreationDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>


      <div class="col-6 col-md-4 inputcolumn">
        <mat-label class="labelname">Archive Date</mat-label><br>
        <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">
          <input matInput [matDatepicker]="Archivepicker" (dateChange)=changeArchiveDate($event.value)
            (click)="Archivepicker.open()" [max]="today" style="cursor: pointer;" class="textinputusertablefilterdate"
            placeholder="mm/dd/yyyy" formControlName="ArchiveDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="Archivepicker"> </mat-datepicker-toggle>
          <mat-datepicker #Archivepicker></mat-datepicker>
        </mat-form-field>
      </div>


      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Department</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" #inpDepartment (keyup)="inputchangeDepartment(inpDepartment.value)"
            class="textinputusertablefilter" formControlName="Department">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Job Position Title</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <!-- <mat-select formControlName="JobTitle" placeholder="Job Title" panelClass="myPanelmatselectClass"
            class="inputclassselect" (selectionChange)=selectJobTitle($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of jobTitles" [value]="item.jobTitle">
              <div class="matoptiontxt">{{item.jobTitle}}</div>
            </mat-option>
          </mat-select> -->
          <input matInput placeholder="Enter here" #JobTitle (keyup)="inputchangeDepartment(JobTitle.value)"
          class="textinputusertablefilter" formControlName="JobTitle">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Direct Manager</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="DirectManager" placeholder="Direct Manager" panelClass="myPanelmatselectClass"
            class="inputclassselect" (selectionChange)=selectDirectManager($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of managers" [value]="item.displayName">
              <div class="matoptiontxt">{{item.displayName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Evaluator ID</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="EvaluatorID" placeholder="Evaluator ID" panelClass="myPanelmatselectClass"
            class="inputclassselect" (selectionChange)=selectEvaluatorID($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of evaluators" [value]="item.displayName">
              <div class="matoptiontxt">{{item.displayName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">Email</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Email" #inpEmail (keyup)="inputchangeEmail(inpEmail.value)"
            class="textinputusertablefilter" formControlName="Email">

          <mat-error *ngIf="filterform.get('Email').hasError('pattern')" class="mat-error">
            Please enter a valid Email ID
          </mat-error>

        </mat-form-field>

      </div>

      <div class="col-6 col-md-4 inputcolumn">
        <mat-label id="usernameid" class="labelname">State</mat-label>

        <mat-form-field appearance="outline" id="formfieldstats" class="formfieldusertable">
          <mat-select matNativeControl id="stateid" formControlName="State" class="inputclassselect" (selectionChange)=selectstate($event.value) placeholder="Select State"
           panelClass="myPanelmatselectClass" disableOptionCentering>
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of statelist" [value]="item">
              {{item}}
            </mat-option>

          </mat-select>
        </mat-form-field>


    </div>


    <div class="col-6 col-md-2 inputcolumn" style="padding-right: 5px;">
      <mat-label id="usernameid" class="labelnamePhoneNumber">Phone Number</mat-label>
      <mat-form-field appearance="outline" id="countrycodeid" class="formfieldusertable">
        <mat-select [(value)]="PhoneNumbercode" panelClass="myPanelmatselectClass"
          class="textinputusertablefilterPhonecode" placeholder="code" [ngClass]="PhoneNumbercode"
          (selectionChange)="countryflagCodeonchange($event)" disableOptionCentering>
          <input placeholder="Search" id="inputpageno" class="input_matselect" (keyup)="onKey($event.target.value)">
          <mat-select-trigger>
            <div style="margin-top: 6%;">
              <img src='assets/images/countryflag/{{PhoneNumbercode}}.png' style="width: 22px;height:18px;">&nbsp;
              <span style="font-size:12px">+{{PhoneNumbercode}}</span>
            </div>
          </mat-select-trigger>
          <mat-option [value]="" class="none_style"> None </mat-option>
          <mat-option *ngFor="let item of countriesflaglist" [value]="item.Normal_code">
            <img [src]='item.flag' style="width:30px;height:20px">
            {{item.dial_code}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="countrycodeError" class="mat-error countrycodecss">
        Please select a country code
      </div>

    </div>

    <div class="col-6 col-md-4 inputcolumn">
      <mat-label id="phnumberid" class="labelname" style="color: white;">.</mat-label>
      <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
        <input matInput type="text" placeholder="Enter here" id="inpphno" #inpphno
          (keyup)="phnokeychange(inpphno.value, $event)" class="textinputusertablefilter" formControlName="PhoneNumber">
        <mat-error *ngIf="filterform.get('PhoneNumber').hasError('pattern')" class="mat-error">
          Enter valid phone number
        </mat-error>
      </mat-form-field>



    </div>
</div>

<div class="row">
  <div class="col-6 cancelbtncol"><br><br>
    <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      Cancel
    </button>
  </div>
  <div class="col-6 Filterbtncol"><br><br>
    <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Filter
    </button>
  </div>
</div>
</form>
</div>
