import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { AssignmentsComponent } from 'src/app/Learner/assignments/assignments.component';

@Component({
  selector: 'app-assignment-filter',
  templateUrl: './assignment-filter.component.html',
  styleUrls: ['./assignment-filter.component.scss']
})
export class AssignmentFilterComponent implements OnInit {
  filterform: FormGroup;
  categorylist: any =[""];
  typelist: any =[""];
  statuslist: any =[""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton:any;
  companylistdata: any = [""];
  loginId: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<AssignmentsComponent>) { 
  
    this.loginId = localStorage.getItem('loginId');
    this.filterform = this.formBuilder.group({
      title: [''],
      category: [''],
      type: [''],
      status: ['']
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }
  apicallgetdefaultdropdown() {
    this.loadspinner = true;
    // const endpoint1 = 'lookup';
    // this.apiService.Simpleget(endpoint1, 'GET')
    //   .subscribe((response) => {
        this.loadspinner = false;
       // this.companylistdata = response["companies"];
      
        this.typelist = 
        [
            { "status_id": 1, "type": "TNG" },
            { "status_id": 2, "type": "KNT" },
            { "status_id": 3, "type": "PEF" },
            { "status_id": 3, "type": "Video" },
            { "status_id": 3, "type": "Other" }
          ]
        
          this.categorylist = [
            { "categoryName": "eCourse" },
            { "categoryName": "Curriculum" },
            { "categoryName": "Exam" },
          ]
        this.statuslist = 
      [
          { "status_id": 5, "status": "In Progress" },
          { "status_id": 6, "status": "Not Started" }
        ]
        this.setdefaultdropdownvalues();
      // },
      //   (err) => {
      //     this.loadspinner = false;
      //    // console.log(err)

      //   });
  }
  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['title'].setValue(data["Title"]);
      this.filterform.controls['category'].setValue(data["Category"]);
      this.filterform.controls['type'].setValue(data["Type"]);
      this.filterform.controls['status'].setValue(data["Status"]);
  
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Title"){
          this.filterform.controls['title'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Category"){
          this.filterform.controls['category'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Type"){
          this.filterform.controls['type'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Status"){
          this.filterform.controls['status'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  inputchangeTitle(value) {
    this.dynamicEnableDisablebtn();
  }

  selecttype(value) {
    let formcontrolname = "type";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  selectcategory(value) {
    let formcontrolname = "category";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }
  selectstatus(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['status'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else{
      this.disablebutton=false;
    }
  }

    
  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
    let element = document.getElementById("LearnerAssignment-content")
    element.classList.remove('blur-content');
  }

  Filter(){
    
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      console.log(filterdata)
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }
    console.log(this.apibodycontent)
//this.save()
  }


  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  getbodycontent() {
    let filterdata = {
      "Title": this.filterform.value.title.trim(),
      "Category": this.filterform.value.category,
      "Type": this.filterform.value.type,
      "Status": this.filterform.value.status.trim(),
     
    }
    console.log("dcsdc")
    this.getapibodycontent();
    return filterdata

  }
  getapibodycontent() {
    let companylistdataid= "";
    console.log(this.companylistdata)
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' +'('+ this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        
        companylistdataid = this.companylistdata[i].companyId;
        console.log(companylistdataid)
      }
    }
   console.log(companylistdataid)
    this.apibodycontent = {
      
      "title": this.filterform.value.title.trim(),
      "category": this.filterform.value.category,
      "type": this.filterform.value.type,
      "courseStatus": this.filterform.value.status.trim(),
      "loginName": this.loginId,
"courseId":""
     
    }
  }

  checkformvalue(){
    let formempty;
    if(this.filterform.value.title.trim()==="" && this.filterform.value.category.trim()==="" &&
    this.filterform.value.type.trim()==="" && this.filterform.value.status.trim()==="" ){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

  dynamicEnableDisablebtnDrodown(value, formcontrolname) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls[formcontrolname].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
     
    }
  }

}
