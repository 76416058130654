import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { TasksComponent } from 'src/app/Administrator/tasks/tasks.component';

@Component({
  selector: 'app-equivalent',
  templateUrl: './equivalent.component.html',
  styleUrls: ['./equivalent.component.scss']
})
export class EquivalentComponent implements OnInit {
  loadspinner: boolean;
  title;
  user_data:any=[]
  selected_users:any=[];
  EquivalentForm: FormGroup;
  groupList: any = [];
  statusTypes=["Group-name1", "Group-name2","Group-name3","Group-name4","Group-name5"];
  groupreslist: any = [];
  operatorCounter: any =[];
  operatorOptions: Observable<string[]>[] = [];
  array: any = [];
  statuslist: any= []
  request: any = [];
  selectedData: { value: any; text: string; };
  isChecked: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string,public dialog: MatDialogRef<TasksComponent>,private formBuilder: FormBuilder) { 
    this.groupList =data['groupListData']
    // console.log(this.groupList)
    
    if(data['groupListData']!=undefined && data['groupListData']!=null){
      // console.log(this.groupList)
    }
  
    // for(let i=0;i<this.groupList.length;i++){
    //   for(let j=0;j<this.statusTypes.length;j++){
    //       if(){

    //       }
    //   }

    // }
    this.EquivalentForm = this.formBuilder.group({
    
    });
   
  }
  // types: any = [""];

  ngOnInit(): void {
   
   
  this.apicallgetdefaultdropdown();
  }
  
 
  group(name){
    this.groupreslist.push("Group"+name)
    // console.log(this.groupreslist)
      return "Group "+name
  }
  apicallgetdefaultdropdown() {

    this.statuslist = [
      // { "status_id": 1, "statusName": "Group1" },
      // { "status_id": 2, "statusName": "Group1" },
      // { "status_id": 3, "statusName": "Group" },
      // { "status_id": 4, "statusName": "Group4" },
    ]
    // console.log("fwrafw")
    // this.array.push("Active")

    for (let n = 0; n < this.groupList.length; n++) {
    // console.log("fwrafw")
    let value = n+1
    let data = { "status_id": n, "statusName": "Group"+ value }
    this.statuslist.push(data);
    this.operatorCounter.push({'groupName': "Group"+ value})

    this.EquivalentForm.addControl("group" + (n + 1), new FormControl({ value: [],}));
    // let preGroup = []
    // preGroup = JSON.parse(localStorage.getItem("group_list"))
    // console.log(JSON.parse(localStorage.getItem("group_list")))
    // for(let m=0;m<=preGroup.length;m++){
    // //   if(localStorage.getItem("group_list") != null &&  localStorage.getItem("group_list") != undefined &&  localStorage.getItem("group_list") != ''){
    // //     this.EquivalentForm.controls["group" + (n+1)].setValue(preGroup[m].userObj);
    // }
    // // console.log(preGroup[m].userObj)
    // }
    
      
      // this.defaultdata()
  }
  // this.operatorCounter = this.groupList;
  for(let i=1; i<=this.operatorCounter.length; i++){
    let data;
    let tempArray = []
    for(let j=1; j<=this.groupList.length; j++){
      // let count = j;
    data = { "status_id": j, "statusName": "Group"+j }
    if(i != j){
      tempArray.push(data)
    }
    }
    this.operatorCounter[i -1].statuslist = tempArray;
  }

  if(localStorage.getItem("group_list")){
  let preGroup = []
    preGroup = JSON.parse(localStorage.getItem("group_list"))
    // console.log(JSON.parse(localStorage.getItem("group_list")))
  let array = []
    for (let n = 0; n < this.groupList.length; n++) {
    for(let m=0;m<=preGroup.length;m++){
      if(localStorage.getItem("group_list") != null &&  localStorage.getItem("group_list") != undefined &&  localStorage.getItem("group_list") != ''){
        let groups = []
        groups.push(preGroup[m].equivalencyGroups)
        let grp;
        grp = preGroup[m].group
        // console.log(groups[0])
        // console.log(this.EquivalentForm.value)
        for(let j=0;j<groups.length;j++){
          this.EquivalentForm.controls[grp.toLowerCase()].setValue(groups[j]);
          // console.log( this.EquivalentForm.controls[grp.toLowerCase()].setValue(groups[j]))
        }
        // localStorage.removeItem("group_list")
    }
    // console.log(preGroup[m].userObj)
    }
  }
  
} else {
  let preGroup = []
    preGroup = JSON.parse(localStorage.getItem("group_list"))
    // console.log(JSON.parse(localStorage.getItem("group_list")))
  let array = []
    for (let n = 0; n < this.groupList.length; n++) {
    for(let m=0;m<=preGroup.length;m++){
      if(localStorage.getItem("group_list") != null &&  localStorage.getItem("group_list") != undefined &&  localStorage.getItem("group_list") != ''){
        let groups = []
        groups.push(preGroup[m].equivalencyGroups)
        let grp;
        grp = preGroup[m].group
        // console.log(groups[0])
        // console.log(this.EquivalentForm.value)
        for(let j=0;j<groups.length;j++){
          this.EquivalentForm.controls[grp.toLowerCase()].setValue(groups[j]);
          // console.log( this.EquivalentForm.controls[grp.toLowerCase()].setValue(groups[j]))
        }
        // localStorage.removeItem("group_list")
    }
    // console.log(preGroup[m].userObj)
    }
  }
  
}
}

  // defaultdata(){
  //   console.log("fad")
  //   for(let i=1; i<=this.operatorCounter.length; i++){
  //     this.EquivalentForm.controls["group"].setValue(this.array)
  //   }
    
  // }
  selectvalues(){
  
  }
  click(selected: boolean, sel_value,groupList,groupName,n,event) {
    console.log(selected);
    console.log(sel_value);
    let selectedGroupName:any;
    let d1=n+1;
   let d ="group"+d1;
    selectedGroupName=this.EquivalentForm.get(d).value
    // 
    if(selected == false){
    for(var i=0; i<this.groupList.length; i++){
      // 
      let value=i+1;
      let id="group"+value;
      let sel_data=sel_value;
      let sel_groupname=groupName;
      let gname="Group"+value
      let data=this.EquivalentForm.get(id).value;//selected value
      if(gname==sel_data){
        //if(this.groupList[i].groupName == )
        let temp_data:any=data;
        if(data.length>0){
        for(var k=0; k<data.length; k++){
         
          if(sel_groupname== data[k]){
            temp_data.splice(k, 1);
            if(temp_data.length!=0){
            //this.EquivalentForm.get(id).patchValue(temp_data);

            console.log(this.EquivalentForm.controls[id].patchValue(temp_data))
            let values =[]
             // this.EquivalentForm.get(id).patchValue(values) 
             this.EquivalentForm.controls[id].setValue(values)
              this.EquivalentForm.controls[id].patchValue(temp_data);
         
          console.log(this.EquivalentForm.value)
            }else{
              let values =[]
             // this.EquivalentForm.get(id).patchValue(values) 
             this.EquivalentForm.controls[id].setValue(values)
            }
          }
        }
      }else{
        let values =[]
       // this.EquivalentForm.get(id).patchValue(values)
       this.EquivalentForm.controls[id].setValue(values)
      }
      }
     
     
    
      
    }
  }else{
    for (let item of groupList) {
      for(let i=0;i<=groupList.length;i++){
      let array = []
      array.push(groupName)
      // console.log(array)
      let Name = item.statusName

    // this.EquivalentForm.controls.Name.setValue(array);
    // console.log(selectedGroupName.indexOf(Name))
    if (selectedGroupName.indexOf(Name) != -1 ) {
      // debugger
      // this.EquivalentForm.controls[Name.toLowerCase()].setValue(array)
    // console.log(Name)
    let grpname = Name.toLowerCase()
    // let data:any = this.EquivalentForm.controls.grpname.value 
    let data:any = this.EquivalentForm.get(grpname).value
    console.log(data['value'])
    if(data['value'] == undefined){
    data = data
    data.push(array[0])
    let data1 = data.filter((item, i, ar) => ar.indexOf(item) === i)
    console.log()
    this.EquivalentForm.controls[Name.toLowerCase()].setValue(data1.map(str => str.replace(/\s/g, '')))
    } else{
    console.log("inside else");
    console.log(this.EquivalentForm.controls[Name.toLowerCase()].setValue(array))
      this.EquivalentForm.controls[Name.toLowerCase()].setValue(array)
    }

    } else {
      //   console.log("gchgchg")
      // let removeGrp = this.EquivalentForm.controls[groupName.toLowerCase()].value
      // console.log(removeGrp)
      // console.log(this.EquivalentForm.value)
      // Object.keys(this.EquivalentForm.controls).forEach(key => {
      //  this.EquivalentForm.get(key).markAsDirty();
      //  console.log(key)
      //  if(selectedGroupName.indexOf(key) == -1){
      // console.log(this.EquivalentForm.value)
      //    let values =[]
      //    values = this.EquivalentForm.controls[key].value
      // console.log(this.EquivalentForm.controls[key].value)
      //    console.log(values)
      //    values.splice(removeGrp[0], 1);
      // console.log(values)
      //   this.EquivalentForm.controls[key].patchValue([values])
      // console.log(values)
      //  }
      
  // });
    // }
     
    // }
}
    }
  
    // let removeGrp = this.EquivalentForm.controls[Name.toLowerCase()].value
    //  console.log({removeGrp})
      // for(let item of this.EquivalentForm.value){
      //   console.log(item)
      // //  let groupValues = item.toLowerCase()
      // //  if()
      // }
  } 
  }
  }

  selectGroup(groupList,groupName,selectedGroupName:string[],event){
    // 
    // console.log(this.EquivalentForm.value)
// let selectedGroupName = this.EquivalentForm.get(groupName[0].toLowerCase()).value
console.log(selectedGroupName)

  }
  save(){
    var groupArr = new Array();
    var groupArrForReq = new Array();
    let rightArr = [];
    for(let i=1; i<=this.operatorCounter.length; i++){

      var groupObj = new Group();
      groupObj.group = "Group"+ i;
      groupObj.equivalencyGroups = this.EquivalentForm.get("group" + i).value;
      groupArr.push(groupObj);
      
      
      groupArrForReq.push({
        leftGroupName: "Group"+ i,
        rightGroupName: this.EquivalentForm.get("group" + i).value
    });
    }
    // }
    console.log(groupArr)
    var storedreqs = localStorage.setItem("group_list",JSON.stringify(groupArr))
    var storedreqs = localStorage.setItem("groupArrForReq",JSON.stringify(groupArrForReq))
  
  this.dialog.close('success');

  }

  close(){
    this.dialog.close();
    let element = document.getElementById("task-content")
    element.classList.remove('blur-content');
}
  
}

class Group{
  equivalencyGroups:any;
  group:any;
}