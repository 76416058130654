import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { CommondataserviceService } from "../../../Services/commondataservice.service";

@Component({
  selector: 'app-curriculafilter',
  templateUrl: './curriculafilter.component.html',
  styleUrls: ['./curriculafilter.component.scss'],
})
export class CurriculafilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  today = new Date();
  data_details: any;
  dataList: any;
  companyId: any;
  groupName: any;
  category: any = [""];
  type: any = [""];

  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>,private CommondataserviceService:CommondataserviceService) {
    // this.data_details = this.CommondataserviceService.getdetails();
    // this.groupName = (this.data_details['data2'])
    // console.log(this.groupName)
    //get data even after reload by not clicking view icon
    // if (this.data_details['data1'] === "" || this.data_details['data1'] === undefined || this.data_details['data1'] === null) {
    //   let resdata = localStorage.getItem('user_details');
    //   this.dataList = JSON.parse(resdata);
    //   this.companyId = this.dataList['data1'];

    // }
    // else {
    //   localStorage.setItem('user_details', JSON.stringify(this.data_details));
    //   this.companyId = this.data_details['data1'];

    // }
    this.filterform = this.formBuilder.group({
      title: [''],
      CompanyStatus: [''],
    });
    this.apicallgetdefaultdropdown();
    
  }

  ngOnInit(): void {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdataforcurri');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['title'].setValue(data["Title"]);
      this.filterform.controls['CompanyStatus'].setValue(data["Status"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkeyforcurri'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Title"){
          this.filterform.controls['title'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Status"){
          this.filterform.controls['CompanyStatus'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }
      }
    }
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    // this.loadspinner = true;
    // const endpoint1 = 'lookup';
    // this.apiService.Simpleget(endpoint1, 'GET')
    //   .subscribe((response) => {
        // this.loadspinner = false;
        // this.companylistdata = response["companies"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Not Started" },
          { "status_id": 4, "statusName": "In Progress" },
          { "status_id": 2, "statusName": "Completed" },
          { "status_id": 3, "statusName": "Failed" },
          { "status_id": 5, "statusName": "Passed" },
          { "status_id": 6, "statusName": "Expired" }
        ]
        this.setdefaultdropdownvalues();
  }


  // on keyup enable filter button
  inputchangegroupname(value){
   this.dynamicEnableDisablebtn()
  }

  inputchangegroupID(value){
  this.dynamicEnableDisablebtn()
  }


  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkeyforcurri'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdataforcurri = this.getbodycontent();
      localStorage.setItem('groupfilterdataforcurri', JSON.stringify(groupfilterdataforcurri));
      localStorage.removeItem('group_clearfilteritemkeyforcurri');
    }
    this.dialog.close({ method: 'close' })
  }



  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdataforcurri');
      localStorage.removeItem('group_chipdisplayfilterdataforcurri');
      localStorage.removeItem('group_clearfilteritemkeyforcurri');
      let groupfilterdataforcurri = this.getbodycontent();
      localStorage.setItem('groupfilterdataforcurri', JSON.stringify(groupfilterdataforcurri));
      this.dialog.close({ data: groupfilterdataforcurri, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let groupfilterdataforcurri = {
      "Title": this.filterform.value.title.trim(),
      "Status": this.filterform.value.CompanyStatus.trim(),
    }
    this.getapibodycontent();
    return groupfilterdataforcurri
  }

  getapibodycontent() {
    this.groupapibodycontent = {
      "title":this.filterform.value.title.trim(),
      "status":this.filterform.value.CompanyStatus.trim(),
    }
  }

  checkformvalue(){
    // console.log(this.filterform.value.Startdate)
    // console.log(this.filterform.value.Enddate)
    let formempty;
    if(this.filterform.value.title.trim()==="" && this.filterform.value.CompanyStatus.trim()===""){
        formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true ){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

  EnterSubmit(event){
    // console.log(event)
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  changeStartDate(event) {
  }
   changeEndDate(event) {
    
    if(event.value === null){
      this.disablebutton=true;
    }
    else{
     this.dynamicEnableDisablebtn();
    }
}
}