import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AssignmentsComponent } from 'src/app/Learner/assignments/assignments.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-nonscormcontentdisplay',
  templateUrl: './nonscormcontentdisplay.component.html',
  styleUrls: ['./nonscormcontentdisplay.component.scss']
})
export class NonscormcontentdisplayComponent implements OnInit {
  loadspinner: any = false;
  base64response12: string;
  function: any;
  showbutton:any=false;
  totalPages: number;
  page: number = 1;
  isLoaded: boolean = false;
  type:any;
  btntype:any;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private apiService: ApiServiceService, private sanitizer: DomSanitizer, public dialog: MatDialogRef<AssignmentsComponent>) {
    this.base64response12 = data['base64string'];
    this.function = data['function'];
    this.type = data['type'];
    this.btntype = data['btntype'];
    this.loadspinner = true;

      // show the submit button after one sec
    if (this.function === 'non-scormpdf') {
      setTimeout(() => {
        this.loadspinner = false;
        this.showbutton=true;
      }, 2000);
    }
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
  this.videoPlayer.nativeElement.play();
  }

  Submit() {
    this.dialog.close({ method: 'Submit' });
  
  }

  cancelbtn(){
    this.dialog.close({ method: 'close' });
  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.Submit();
    }
  }

  nextPage() {
    this.page += 1;
    document.getElementById('filtermaincontainerid').scrollIntoView();
  }

  previousPage() {
    this.page -= 1;
    document.getElementById('filtermaincontainerid').scrollIntoView();
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }


}
