import { Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AssignableContentComponent } from 'src/app/Administrator/assignable-content/assignable-content.component';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-add-user-for-curriculum',
  templateUrl: './add-user-for-curriculum.component.html',
  styleUrls: ['./add-user-for-curriculum.component.scss']
})
export class AddUserForCurriculumComponent implements OnInit {

  rem_data:any=[];
  sel_data:any=[];
  loadspinner: boolean;
  user_data:any=[];
  backup_users:any=[];
  backup_data:any=[];
  selected_users=[];
  selected_users_backup=[];
  backup_users1:any=[]
  courseId: any;
  curriculumiId: any;
  title: any;
  loginName: string;
  firstName: string;
  lastName: string;
  companyId: any;
  loginId: string;
  companyId1: any;
  pagesize: any;
  Rowperpage: any;
  sortby: any;
  totalitemlength: any;
  sortOrder: any;
  dataSource: any;
  ELEMENT_DATA: any = [];
  compId: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog,  public dialog: MatDialogRef<AssignableContentComponent>, private formBuilder: FormBuilder, public _Service:ApiServiceService,) {
  
    
    //this.curriculumiId= data['curriculumiId'];
    this.curriculumiId = localStorage.getItem("curriculumiId");
    this.loginId = localStorage.getItem('loginId');
    this.title =  localStorage.getItem("curriculumtitlePopup");
    this.companyId1= localStorage.getItem("ownerComp");
    this.totalitemlength= data['totalitemlength'];

  
    if(data["UsersList"]!= "" && data["UsersList"]!= undefined && data["UsersList"]!=null){
      for(var i =0; i< data["UsersList"].length; i++){
       data["UsersList"][i].isSelected= false;
      }
    }
    let tempdata= [];
    for(var i =0; i< data["UsersList"].length; i++){
      if(data["UsersList"][i].courseStatus == "Not Started" || data["UsersList"][i].courseStatus == "In Progress")
      {
        tempdata.push(data["UsersList"][i])
      }
    }
    this.selected_users=tempdata;
    this.backup_users= this.selected_users;
    this.backup_users1=this.selected_users_backup;
    
  }

  ngOnInit(): void {
    this.filterapipaginationcall();
  }

  close(){

    this.dialog.close();
    let element = document.getElementById("vieweditcurriculam-content") ||  document.getElementById("user-content")
    element.classList.remove('blur-content');

}
filterapipaginationcall() {
  // create bodu content for api call
  this.loadspinner = true;
  const endpoint1 = 'enrollment';
  const endpoint2 = 'search';
  this.pagesize = '1';
  this.Rowperpage = parseInt(this.totalitemlength) +1;
  this.sortby = "loginName";
  this.sortOrder = "ASC";
  let data={
    "courseId": this.curriculumiId,
    "companyId":"",
    "loginName":"",
    "displayName":"",
    "userStatus":"",
    "email":"",
    "managerName":"",
    "roleName":"",
    "groupName":"",
    "courseStatus":"",
    "enrollmentViewId":""
}

let itemPerPage = this.Rowperpage;
let body = {
  "filters": data
}
  // call api get response
  this._Service.Enrollment_filterpaginationpost('1', this.sortby, this.sortOrder, itemPerPage, endpoint1, endpoint2, body, 'POST')
  .subscribe((response) => {

  this.loadspinner = false;
  this.ELEMENT_DATA = response['enrolledUersList'];
  console.log(this.ELEMENT_DATA)
  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  this.ELEMENT_DATA.map(i => { i["isSelected"] = false });
  if(this.ELEMENT_DATA!= "" && this.ELEMENT_DATA!= undefined && this.ELEMENT_DATA!=null){
    for(var i =0; i< this.ELEMENT_DATA.length; i++){
      this.ELEMENT_DATA[i].isSelected= false;
    }
  }
  let tempdata= this.ELEMENT_DATA;


  this.selected_users=tempdata;
  this.backup_users= this.selected_users;
  this.listUserApi();

  },
  (err) => {
    this.loadspinner = false;
  //  console.log(err)

  });

}
// listUserApi(){
//   this.loadspinner = true;
//   const endpoint1 = 'users';
//   const endpoint2 = 'searchusersfilter';
//   let body = {
//     "filters": {
//       "firstName": "",
//       "lastName": "",
//       "loginName":"",
//       "displayName": "",
//       "email":"",
//       "companyId": "",
//       "empCode": "",
//       "jobPositionName": "",
//       "groupName": "",
//       "roleName":"",
//       "managerName":""
//       }
//       }

//       this._Service.manager_filterpost(endpoint1, endpoint2, body, 'POST')
//       .subscribe((response) => {
//       this.loadspinner = false;
//      let temp_data:any = response["userListFilter"];
//      this.listUsers(temp_data[0].companyId)
//     },
//     (err) => {
//       this.loadspinner = false;
//     });
//   }

listUserApi(){
  this.loadspinner = true;
  const endpoint1 = 'users';
  const endpoint2 = 'searchusersfilter';
    this.compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body;
    if (cacheddata === "System Administrator") {
      body = {
        "filters": {
          "firstName": "",
          "lastName": "",
          "loginName":"",
          "displayName": "",
          "email":"",
          "companyId": "",
          "empCode": "",
          "jobPositionName": "",
          "groupName": "",
          "roleName":"",
          "managerName":"",
          "statusName":""
          }
        }
    }
    else {
      body = {
        "filters": {
          "firstName": "",
          "lastName": "",
          "loginName":"",
          "displayName": "",
          "email":"",
          "companyId": this.compId,
          "empCode": "",
          "jobPositionName": "",
          "groupName": "",
          "roleName":"",
          "managerName":"",
          "statusName":""
          }
        }
    }

      this._Service.manager_filterpost(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
      this.loadspinner = false;
     let temp_data:any = response["userListFilter"];
  for(var i=0; i<temp_data.length; i++){
    temp_data[i].isSelected=false;
  }
  for(var i=0; i<temp_data.length; i++){
    for(var j=0; j<this.selected_users.length; j++){
      if(temp_data[i].loginName==this.selected_users[j].loginName){
         temp_data[i].isSelected=true;
       }
  }
 }
 temp_data = temp_data.filter(x => x.isSelected != true);

  this.user_data=temp_data;
  var setValue = this.user_data;
 // console.log(this.user_data, this.username)
  const dataRemoved = setValue.filter((el) => {
    return el.displayName !==  this.lastName +"," + this.lastName;
  });
  //console.log(dataRemoved);
  this.user_data= dataRemoved;
 this.loadspinner = false;
  this.backup_data=this.user_data;

  this.loadspinner = false;
},
(err) => {
  this.loadspinner = false;
});
}

    
itemSelected_leftbox(ev){
  let present=false;
  for(var k=0; k<this.sel_data.length; k++){
    if(ev.loginName==this.sel_data[k].loginName){
    present=true;
    this.sel_data.splice(k, 1);
  }
}
if(!present){
  this.sel_data.push(ev)
}

for(var l=0; l<this.backup_data.length; l++){
  if(!present){
  if(ev.loginName == this.backup_data[l].loginName){
    this.backup_data[l].isSelected=true;
  }
}else{
  if(ev.loginName == this.backup_data[l].loginName){
    this.backup_data[l].isSelected=false;
  }
}
}
for(var l=0; l<this.user_data.length; l++){
  if(!present){
  if(ev.loginName == this.user_data[l].loginName){
    this.user_data[l].isSelected=true;
  }
}else{
  if(ev.loginName == this.user_data[l].loginName){
    this.user_data[l].isSelected=false;
  }
}
}

}
itemSelected_rightbox(ev){

  let present=false;
  for(var k=0; k<this.rem_data.length; k++){
    if(ev.loginName==this.rem_data[k].loginName){
      present=true;
      this.rem_data.splice(k, 1);
    }
  }
  if(!present){
    this.rem_data.push(ev)
  }
  for(var l=0; l<this.backup_users.length; l++){
    if(!present){
    if(ev.loginName == this.backup_users[l].loginName){
      this.backup_users[l].isSelected=true;
    }
  }else{
    if(ev.loginName == this.backup_users[l].loginName){
      this.backup_users[l].isSelected=false;
    }
  }
  }
  for(var l=0; l<this.selected_users.length; l++){
    if(!present){
    if(ev.loginName == this.selected_users[l].loginName){
      this.selected_users[l].isSelected=true;
    }
  }else{
    if(ev.loginName == this.selected_users[l].loginName){
      this.selected_users[l].isSelected=false;
    }
  }
  }

  for(var l=0; l<this.selected_users_backup.length; l++){
    if(!present){
    if(ev.loginName == this.selected_users_backup[l].loginName){
      this.selected_users_backup[l].isSelected=true;
    }
  }else{
    if(ev.loginName == this.selected_users_backup[l].loginName){
      this.selected_users_backup[l].isSelected=false;
    }
  }
  }
}
add_users(){
  if(this.sel_data.length>0){

    for(var j=0; j<this.backup_data.length; j++){
      if(this.backup_data[j].isSelected){
        this.selected_users.push(this.backup_data[j])
        this.selected_users_backup.push(this.backup_data[j])
      }
    }
    
    
    this.user_data=this.backup_data;
    this.user_data = this.user_data.filter(x => x.isSelected != true);
    this.sel_data=[];
    for(var m=0; m<this.backup_data.length; m++){
      for(var n=0; n<this.selected_users.length; n++){
        if(this.selected_users[n].loginName==this.backup_data[m].loginName){
          this.backup_data[m].isSelected=true;
        }
      }
    }
    
    this.backup_data = this.backup_data.filter(x => x.isSelected != true);
    this.user_data=this.backup_data;
    for(var j=0; j<this.selected_users.length; j++){
      this.selected_users[j].isSelected=false;
    }
    this.backup_users=this.selected_users;
    //setting the serach value
    const name:any =  document.getElementById('sid1');
    name.value = "";
    }
}

remove_user(){
  
  if(this.rem_data.length>0){

    for(var j=0; j<this.backup_users.length; j++){
      if(this.backup_users[j].isSelected){
        this.user_data.push(this.backup_users[j])
      }
    }
  
  this.selected_users=this.backup_users;
  this.selected_users = this.selected_users.filter(x => x.isSelected != true);
  this.selected_users_backup = this.selected_users_backup.filter(x => x.isSelected != true);

  this.rem_data=[];
  for(var m=0; m<this.backup_users.length; m++){
    for(var n=0; n<this.user_data.length; n++){
      if(this.user_data[n].loginName==this.backup_users[m].loginName){
        this.backup_users[m].isSelected=true;
      }
    }
  }
  }
  
  
  this.backup_users = this.backup_users.filter(x => x.isSelected != true);
  this.selected_users=this.backup_users;
  for(var j=0; j<this.user_data.length; j++){
    this.user_data[j].isSelected=false;
  }
  this.backup_data=this.user_data;
    const name:any =  document.getElementById('sid2');
    name.value = "";
}
search_left(ev){
  
  this.user_data=this.backup_data;
  let filterValue=ev;
  this.user_data= this.user_data.filter(option => ( option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase()) ));   }
   search_right(ev){

  
    this.selected_users_backup=this.backup_users1;
    let filterValue=ev;
    this.selected_users_backup= this.selected_users_backup.filter(option => ( option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase()) ));
      }
  create_user(){ 
    this.loadspinner=true;
    let endpoint='/enrollment/adduserscurriculum/' +  this.curriculumiId; 
    let user_list:any=[];

    if(this.backup_users.length>0){
      for(var i=0; i<this.backup_users.length; i++){
        user_list.push(this.backup_users[i].loginName)
      }
    }


    let body={
    
      "enrolledUsersList":user_list
    }
    this._Service.addwithdraw_enrolledCurriculum(endpoint, body, 'POST')
.subscribe((response) => {
  this.loadspinner=false;
  if(response["message"]=== 'success'){
    let element = document.getElementById("user-content")
    element.classList.add('blur-content')
    this.dialogopen.open(DialogpopupComponent, {
      width: '550px',
      disableClose: true,
      data:
      {
        from: "popup-success",
       // navigateTo: "edit-user";
      successmsg: "Hurray!!",
      successmsgbody:'User enrolled successfully ',
      imagelogo:"assets/images/thumbsup.svg"
      },
      position: {
        top: '25vh'
      }
    });
    this.dialog.close('success');
   }
   else{
    this.loadspinner = false;

  let data = {
    status: response["message"]
  };
}
},
(err) => {
  this.loadspinner = false;
 //  console.log(err)

 });
  }
  

}
