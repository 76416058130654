<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">



      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Login</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID1 (keyup)="inputchangeID1(inpID1.value)" class="textinputmedium"
            formControlName="Login">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">First name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID2 (keyup)="inputchangeID2(inpID2.value)" class="textinputmedium"
            formControlName="Firstname">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Last Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID3 (keyup)="inputchangeID3(inpID3.value)" class="textinputmedium"
            formControlName="LastName">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-6 inputcolumn" >
        <mat-label id="usernameid" class="labelname">Company (COID)</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="CompanyId" placeholder="Company (COID)" class="inputClass" panelClass="myPanelmatselectClassCOID"
           (selectionChange)=selectCompanyID($event.value) disableOptionCentering [disabled]="isDisabled">
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of companylistdata" [value]="item.companyName + ' ' +'('+ item.companyId + ')'">
              <div class="matoptiontxt">{{item.companyName}}&nbsp;({{item.companyId}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>




      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Completion date range</mat-label><br>
        <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">

          <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" [max]="today" style="cursor: pointer;" class="textinputdate" placeholder="mm/dd/yyyy"
          readonly>
            <input matStartDate placeholder="mm/dd/yyyy" formControlName="start" (dateChange)="startChange($event)" style="cursor: pointer;" readonly>
            <input matEndDate placeholder="mm/dd/yyyy" formControlName="end" (dateChange)="endChange($event)" style="cursor: pointer;" readonly>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

      </div>
      <div class="col-6 col-md-6 inputcolumn" >
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Status" placeholder="Status" class="inputClass" panelClass="myPanelmatselectClassCOID"
           (selectionChange)=selectStatus($event.value) disableOptionCentering [disabled]="isDisabled">
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of statuslist" [value]="item.statusName">
              <div class="matoptiontxt">{{item.statusName}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

  </div>



  <div class="row">
    <div class="col-6 cancelbtncol"><br><br>
      <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-6 Filterbtncol"><br><br>
      <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Filter
      </button>
    </div>
  </div>

</form>


  </div>

