import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { CommondataserviceService } from "../../../Services/commondataservice.service";

@Component({
  selector: 'app-proctorfilter',
  templateUrl: './proctorfilter.component.html',
  styleUrls: ['./proctorfilter.component.scss']
})
export class ProctorfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  today = new Date();
  data_details: { data1: any; data2: any; data3: any; };
  companyId: any;
  dataList: any;

  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>, private CommondataserviceService:CommondataserviceService) {
    this.data_details = this.CommondataserviceService.getdetails();
    //get data even after reload by not clicking view icon
    // if (this.data_details['data1'] === "" || this.data_details['data1'] === undefined || this.data_details['data1'] === null) {
    //   let resdata = localStorage.getItem('user_details');
    //   this.dataList = JSON.parse(resdata);
    //   this.companyId = this.dataList['data1'];
    // }
    // else {
    //   localStorage.setItem('user_details', JSON.stringify(this.data_details));
    //   this.companyId = this.data_details['data1'];
    // }

    this.filterform = this.formBuilder.group({
      requirementName: [''],
      name: [''],
      taskName: [''],
      login: [''],
      empId: [''],
      status: [''],
    });
    this.apicallgetdefaultdropdown();
  
  }

  ngOnInit(): void {
  }

  changeCreationDate(value) {
    this.dynamicEnableDisablebtn();
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    // this.filterform.controls['CompanyId'].setValue(this.companyId)
    
  }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['requirementName'].setValue(data["Requirement Name"]);
      this.filterform.controls['login'].setValue(data["Login"]);
      this.filterform.controls['taskName'].setValue(data["Task Name"])
      this.filterform.controls['name'].setValue(data["Name"])
      this.filterform.controls['empId'].setValue(data["Emp ID"]);
      this.filterform.controls['status'].setValue(data["Status"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
      
         if(clearfilteritemkey[i]==="Group Name"){
          this.filterform.controls['taskName'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
   
        this.statuslist = [
          { "status_id": 1, "statusName": "Not Started" },
          { "status_id": 2, "statusName": "In Progress" }
        ]
        this.setdefaultdropdownvalues();
     
  }


  // on keyup enable filter button
  inputchangegroupname(value){
   this.dynamicEnableDisablebtn()
  }

  inputchangegroupID(value){
  this.dynamicEnableDisablebtn()
  }

  selectCompanyID(value) {
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      // this.filterform.controls['CompanyId'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['login'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }



  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let groupfilterdata = {
      "Requirement Name": this.filterform.value.requirementName.trim(),
      "Task Name": this.filterform.value.taskName.trim(),
      "Login": this.filterform.value.login.trim(),
      "Emp ID": this.filterform.value.empId.trim(),
      "Name": this.filterform.value.name.trim(),
      "Status": this.filterform.value.status.trim(),
    }
    this.getapibodycontent();
    return groupfilterdata
  }

  getapibodycontent() {
 let compId = localStorage.getItem('procCompId')
    this.groupapibodycontent = {
      "id":"",
      "loginName": this.filterform.value.login.trim(),
      "displayName":this.filterform.value.name.trim(),
      "empId": this.filterform.value.empId.trim(),
      "companyId":compId,
      "taskId":"",
      "requirementName": this.filterform.value.requirementName.trim(),
      "courseId":"",
      "taskName": this.filterform.value.taskName.trim(),
      "status": this.filterform.value.status.trim(),
      "proctorEnabled":"1",
      "lockUnlockData":""
    }
  }

  checkformvalue(){
    let formempty;
    if(this.filterform.value.requirementName.trim()==="" && this.filterform.value.taskName.trim()==="" && this.filterform.value.login.trim()===""  && this.filterform.value.name.trim()==="" && this.filterform.value.empId.trim()==="" && this.filterform.value.status.trim()===""){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

  selectStatus(value) {
    let formcontrolname = "Status";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  dynamicEnableDisablebtnDrodown(value, formcontrolname) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls[formcontrolname].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
      // if any phcode and ph field any empty disable button
      // if ((this.PhoneNumbercode != "" && this.filterform.value.PhoneNumber.trim() === "") ||
      //   (this.PhoneNumbercode === "" && this.filterform.value.PhoneNumber.trim() != "")) {
      //   this.disablebutton = true;
      // }
    }
  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }
}