import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiServiceService } from '../../../../Services/api-service.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ScaleratingComponent } from 'src/app/Popups/assignablecontent/scalerating/scalerating.component';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit {

  public selectTabIndex: any;
  loadspinner: any;
  formssettings: FormGroup;
  performancerating = ['2 Scale Rating', '3 Scale Rating', '4 Scale Rating', '5 Scale Rating'];
  settings_data: any;
  scalerating_val: any;
  savebutton: boolean = true;
  cancelbutton: boolean = true;
  A_C_FORMS_SETTINGS_EDIT: any;
  sharedForm:any='0'
  constructor(private formBuilder: FormBuilder, private apiservice: ApiServiceService, public dialog: MatDialog,) {
    if(localStorage.getItem("formsharedFlagtocompany") != null && localStorage.getItem("formsharedFlagtocompany") != 'null' && localStorage.getItem("formsharedFlagtocompany") != undefined && localStorage.getItem("formsharedFlagtocompany") != 'undefined'){
      this.sharedForm=localStorage.getItem("formsharedFlagtocompany")
    }
    this.formssettings = this.formBuilder.group({
      numberingformat: ['1'],
      assignmentscore: ['Passed/Failed'],
      performancerating: ['2 Scale Rating'],
      allowfrommarked: ['Yes'],
      evaluatorsignature: ['Required'],
      evaluateesignature: ['Required'],
      evaluateeimage: ['Required']
    })

    this.formssettings.disable();

  }

  ngOnInit(): void {
    this.setdefaultpermission();
    this.selectTabIndex = localStorage.getItem("forms-selectTabIndex");

    if (this.selectTabIndex == undefined) {
      this.selectTabIndex = 1;
    }
    this.loadspinner = true;

    this.default_load();

  }

  setdefaultpermission(){
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
    console.log(Permissiongroupdata)
    let login_role = localStorage.getItem("loginRole");

    if (login_role == 'System Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["System Administrator"]).length != 0) {
        this.A_C_FORMS_SETTINGS_EDIT=Permissiongroupdata["rolesPermissions"]["System Administrator"]["A_C_FORMS_SETTINGS_EDIT"];
     }
    }
    else if (login_role == 'Primary Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Primary Administrator"]).length != 0) {
        this.A_C_FORMS_SETTINGS_EDIT=Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["A_C_FORMS_SETTINGS_EDIT"];
      }
     }
     else if (login_role == 'Company Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Company Administrator"]).length != 0) {
        this.A_C_FORMS_SETTINGS_EDIT=Permissiongroupdata["rolesPermissions"]["Company Administrator"]["A_C_FORMS_SETTINGS_EDIT"];
      }
    }
    else if (login_role == 'Manager') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Manager"]).length != 0) {
      }
      this.A_C_FORMS_SETTINGS_EDIT=Permissiongroupdata["rolesPermissions"]["Manager"]["A_C_FORMS_SETTINGS_EDIT"];
    }

    else if (login_role == 'Proctor') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Proctor"]).length != 0) {
      }
    }
    else if (login_role == 'Learner') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Learner"]).length != 0) {
      }
    }

    }

  enable_edit(){
    document.getElementById("view_active").style.display = "none";
    document.getElementById("edit_active").style.display = "block";
    this.formssettings.enable();
    this.savebutton = false;
    this.cancelbutton = false;
    document.getElementById("formsettings_btn").style.display = "flex";
    this.loadspinner = true;
    this.default_load();
  }

  disable_edit(){
    document.getElementById("edit_active").style.display = "none";
    document.getElementById("view_active").style.display = "block";
    this.formssettings.disable();
    this.savebutton = true;
    this.cancelbutton = true;
    document.getElementById("formsettings_btn").style.display = "none";

  }

  default_load() {

    let endpoint1 = "pef/fetchFormSettings/";
    let body = {
      "formId": localStorage.getItem("formId"),
      "version": localStorage.getItem("version")
    }

    //console.log(".................", body);

    this.apiservice.save_pef_settings(endpoint1, body, 'POST').subscribe((response) => {

      this.settings_data = response;

    console.log(".................", this.settings_data.formSettings.stepsPerformanceRating);


    if(this.settings_data.pefResponse.id != null){

      var dataToStore = JSON.stringify(this.settings_data.formSettings.stepsPerformanceRating);
      localStorage.setItem('default_scalerating', dataToStore);

      let rating;

      if(this.settings_data.formSettings.stepsPerformanceRating.ratingScale === null){
        rating = "2 Scale Rating";
      } else {
        rating = this.settings_data.formSettings.stepsPerformanceRating.ratingScale;
      }

      let assignment_scoring;
      if(this.settings_data.formSettings.assignmentScoring === "Pass/Failed"){
        assignment_scoring = "Passed/Failed";
      } else {
        assignment_scoring = this.settings_data.formSettings.assignmentScoring;
      }




      if (this.settings_data.pefResponse.id != null) {
        this.formssettings.patchValue({
          numberingformat: this.settings_data.formSettings.numberFormat,
          assignmentscore: assignment_scoring,
          performancerating: rating,
          allowfrommarked: this.settings_data.formSettings.failedSteps,
          evaluatorsignature: this.settings_data.formSettings.evaluatorSign,
          evaluateesignature: this.settings_data.formSettings.evaluateeSign,
          evaluateeimage: this.settings_data.formSettings.evaluateeImage,
        })
      }

      this.loadspinner = false;



    } else {

      this.formssettings = this.formBuilder.group({
        numberingformat: ['1'],
        assignmentscore: ['Passed/Failed'],
        performancerating: ['2 Scale Rating'],
        allowfrommarked: ['Yes'],
        evaluatorsignature: ['Required'],
        evaluateesignature: ['Required'],
        evaluateeimage: ['Required']
      })

      this.loadspinner = false;

    }




    });


  }



  save_settings() {

    this.loadspinner = true;


    let scale_rating = this.formssettings.controls.performancerating.value;
    let ratingval;
  //  console.log("............", this.scalerating_val);

    if (this.scalerating_val != "" && this.scalerating_val != undefined) {
      ratingval = this.scalerating_val.data;
    } else {
      ratingval = [{
        "labelCode": 1,
        "labelTitle": "Pass",
        "passingCriteria": false
      },
      {
        "labelCode": 2,
        "labelTitle": "Fail",
        "passingCriteria": true
      }
      ];
    }

    let body = {
      "formId": localStorage.getItem("formId"),
      "version": localStorage.getItem("version"),
      "formSettings": {
        "numberFormat": this.formssettings.controls.numberingformat.value,
        "assignmentScoring": this.formssettings.controls.assignmentscore.value,
        "failedSteps": this.formssettings.controls.allowfrommarked.value,
        "evaluatorSign": this.formssettings.controls.evaluatorsignature.value,
        "evaluateeSign": this.formssettings.controls.evaluateesignature.value,
        "evaluateeImage": this.formssettings.controls.evaluateeimage.value,
        "stepsPerformanceRating": {
          "ratingScale": this.formssettings.controls.performancerating.value,
          "ratings": ratingval
        }
      }
    }

    console.log("................", body);

    const endpoint1 = 'pef/formsettings/';

    this.apiservice.save_pef_settings(endpoint1, body, 'POST').subscribe((response) => {
      var res;
      res = response;
      localStorage.setItem("formSettingId", res.formSettingId);
      this.loadspinner = false;
      if (res.message === "Success") {
        this.disable_edit();
        this.loadspinner = false;
        document.getElementById("success_block1").style.display = "flex";
        setTimeout(() => {
          document.getElementById("success_block1").style.display = "none";
        }, 5000);
      }
    },
      (err) => {
        this.loadspinner = false;
      });
  }


  scale_popup() {

    let scale_val = this.formssettings.controls.performancerating.value;
    let popup_height;
    let popup_topspace;

    if (scale_val == "2 Scale Rating") {
      popup_height = "355px";
      popup_topspace = '27vh';
    } else if (scale_val == "3 Scale Rating") {
      popup_height = "405px";
      popup_topspace = '25vh';
    } else if (scale_val == "4 Scale Rating") {
      popup_height = "450px";
      popup_topspace = '20vh';
    } else if (scale_val == "5 Scale Rating") {
      popup_height = "510px";
      popup_topspace = '15vh';
    }

    let element = document.getElementById("user-content")
    element.classList.add('blur-content')
    const dialogRef = this.dialog.open(ScaleratingComponent, {
      width: '530px',
      height: popup_height,
      disableClose: true,
      data:
      {
        title: scale_val,
        from: "delete"
      },
      backdropClass: 'deletePopup',
      position: {
        top: popup_topspace
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.scalerating_val = result;
      if(this.scalerating_val.method != "cancel"){
        if(result.data != undefined) {
          this.save_settings();
         }
      }      
      let element = document.getElementById("user-content")
      element.classList.remove('blur-content');
    });


  }

  cancel() {
    event.preventDefault();
    let element = document.getElementById("course-content")
    element.classList.add('blur-content')
    const dialogRef = this.dialog.open(DialogpopupComponent, {
      width: '530px',
      height: '330px',
      disableClose: true,
      data: {
        from: 'settings-cancel'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      let element = document.getElementById("course-content")
      element.classList.remove('blur-content');
    });
    // this.CreateForms.reset();
  }

}
