<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

   <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">

      <div class="col-12 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Course ID </mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID0 (keyup)="inputchangeID0(inpID0.value)" class="textinputmedium"
            formControlName="CourseID">
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Course Title</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID1 (keyup)="inputchangeID1(inpID1.value)" class="textinputmedium"
            formControlName="CourseTitle">
        </mat-form-field>
      </div>
      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Owner / Company ID</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="CompanyId" placeholder="Company (COID)" class="inputClass" panelClass="myPanelmatselectClassCOID"
            (selectionChange)=selectCompanyID($event.value) disableOptionCentering [disabled]="isDisabled">
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of companylistdata" [value]="item.companyName + ' ' +'('+ item.companyId + ')'">
              <div class="matoptiontxt">{{item.companyName}}&nbsp;({{item.companyId}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Primary Admin</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpgroupID (keyup)="inputchangePrimaryAdmin(inpgroupID.value)" class="inputClassText"
            formControlName="PrimaryAdmin">
        </mat-form-field>
      </div>-->

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Category</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Category" placeholder="Category" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectCategory($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of category" [value]="item.categoryName">
              <div class="matoptiontxt">{{item.categoryName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

       <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Type</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="CompanyType" placeholder="Type" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectType($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of type" [value]="item.typeName">
              <div class="matoptiontxt">{{item.typeName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

       <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="CompanyStatus" placeholder="Status" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectStatusName($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of statuslist" [value]="item.statusName">
              <div class="matoptiontxt">{{item.statusName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      

       <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Creation Date Range</mat-label><br>
        <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">
          <mat-date-range-input [rangePicker]="picker1" (click)="picker1.open()" style="cursor: pointer;" placeholder="mm/dd/yyyy" class="textinputusertablefilterdate" >
            <input matStartDate matInput (dateChange)=changeStartDateCreation($event) style="cursor: pointer;" placeholder="mm/dd/yyyy" formControlName="CreationStartdate" readonly>
            <input matEndDate matInput (dateChange)=changeEndDateCreation($event) style="cursor: pointer;" placeholder="mm/dd/yyyy" formControlName="CreationEnddate" readonly>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"> </mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>

      </div>

       <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Last updated Date Range</mat-label><br>
        <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">
          <mat-date-range-input [rangePicker]="picker2" (click)="picker2.open()" style="cursor: pointer;" placeholder="mm/dd/yyyy" class="textinputusertablefilterdate" >
            <input matStartDate matInput (dateChange)=changeStartDateLastUpdate($event) style="cursor: pointer;" placeholder="mm/dd/yyyy" formControlName="LastUpdateStartdate" readonly>
            <input matEndDate matInput (dateChange)=changeEndDateLastUpdate($event) style="cursor: pointer;" placeholder="mm/dd/yyyy" formControlName="LastUpdateEnddate" readonly>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker2"> </mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>
        </mat-form-field>

      </div>

       <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Created By</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="CreatedBy" placeholder="Created By" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectCreatedBy($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of createdBy" [value]="item.displayName + ' ' +'('+ item.loginName + ')'">
              <div class="matoptiontxt">{{item.displayName}} ({{item.loginName}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

       <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Modified By</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="ModifiedBy" placeholder="Modified By" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectModifiedBy($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of modifiedBy" [value]="item.displayName + ' ' +'('+ item.loginName + ')'">
              <div class="matoptiontxt">{{item.displayName}} ({{item.loginName}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Catalogs</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="assignCatalog" placeholder="Select Catalog" class="inputClass" panelClass="myPanelClass" multiple (selectionChange)=selectModifiedBy($event.value)
          disableOptionCentering>
            <mat-option value="None" class="create_select" (click)="catalog_none()">  None </mat-option>
            <mat-option class="create_select" *ngFor="let item of assgnCatlog" (click)="catalog_other()" [value]="item">
              <div class="matoptiontxt">  {{item.catalogName}} </div>
            </mat-option>
          </mat-select>
          <!-- <mat-select matNativeControl formControlName="assignCatalog"
            placeholder="Select Catalog" multiple panelClass="myPanelClass" disableOptionCentering>
            <mat-option value="None" class="create_select" (click)="catalog_none()">  None </mat-option>
            <mat-option *ngFor="let item of assgnCatlog" (click)="catalog_other()" [value]="item" class="create_select">
            {{item.catalogName}} 
            </mat-option>
            </mat-select> -->
        </mat-form-field>
      </div>

     
      

       <!-- <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Custom Field</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Customfield" placeholder="Custom Field" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectCustomfield($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of customField" [value]="item.customFieldName">
              <div class="matoptiontxt">{{item.customFieldName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

     

  </div>



  <div class="row">
    <div class="col-6 cancelbtncol"><br><br>
      <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-6 Filterbtncol"><br><br>
      <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Filter
      </button>
    </div>
  </div>

</form>


  </div>

