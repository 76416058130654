import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';

@Component({
  selector: 'app-viewcoursecomment',
  templateUrl: './viewcoursecomment.component.html',
  styleUrls: ['./viewcoursecomment.component.scss']
})
export class ViewcoursecommentComponent implements OnInit {
  filterform: FormGroup;
  from:any;
  comment:any;
  version_number:any='';
  constructor(public dialog: MatDialogRef<CompanyManagementComponent>, @Inject(MAT_DIALOG_DATA) public data: string,private formBuilder: FormBuilder,) {
    this.filterform = this.formBuilder.group({
      RequestorComments: [''],
    });
    this.from=data['from'];
    this.comment=data['comment'];
    this.version_number=data['version']
    this.filterform.controls['RequestorComments'].setValue(this.comment);
   
  }

  ngOnInit(): void {
  }

  cancelbtn(){
    this.dialog.close();
  }

}
