import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-exampoolfilter',
  templateUrl: './exampoolfilter.component.html',
  styleUrls: ['./exampoolfilter.component.scss']
})
export class ExampoolfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  from:any;
  managerName:any;
  isDisabled: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string,private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.from=data['from'];
    this.managerName=data['managerName'];
    this.filterform = this.formBuilder.group({
      examPoolName: [''],
      owner: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit() {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Manager_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);     
      this.filterform.controls['examPoolName'].setValue(data["examPoolName"]);
      this.filterform.controls['owner'].setValue(data["owner"]);
      // this.selectCompanyID(data["Company (COID)"]);
      this.clearfilterfunction();
    }
  
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')';
      //let data= compId;
      this.filterform.controls['owner'].setValue(data);
      this.isDisabled=true;
      this.disablebutton=false;
    }
    else {
      this.isDisabled=false;
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
         if (clearfilteritemkey[i] === "examPoolName") {
          this.filterform.controls['examPoolName'].setValue("");
        } else if (clearfilteritemkey[i] === "owner") {
          this.filterform.controls['owner'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }


  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          // console.log(err)

        });
  }


  inputchangeLogin(value) {
    this.dynamicEnableDisablebtn()
  }
  inputchangeName(value) {
    this.dynamicEnableDisablebtn()
  }

  inputchangeEmpID(value) {
    this.dynamicEnableDisablebtn()
  }

  inputchangeEmail(value) {
    this.dynamicEnableDisablebtn()
  }

  selectCompanyID(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['owner'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
      // change selected name to ID to call api
      // let companylistdataid = "";
      // for (let i = 0; i < this.companylistdata.length; i++) {
      //   if (this.companylistdata[i].examPoolName + ' ' + '(' + this.companylistdata[i].owner + ')' === value) {
      //     companylistdataid = this.companylistdata[i].owner;
      //   }
      // }
      // // on select Company (COID) -- get all other dropdown data ---- api call
      // this.loadspinner = true;
      // const endpoint1 = 'lookup';
      // const queryparameter1 = companylistdataid;
      // this.apiService.getwithparam(queryparameter1, endpoint1, 'GET')
      //   .subscribe((response) => {
      //     this.loadspinner = false;
      //     // this.JobPosition = response["jobTitles"];
      //   },
      //     (err) => {
      //       this.loadspinner = false;
      //       //  console.log(err)

      //     });
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('Manager_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('Manager_filterdata');
      localStorage.removeItem('Manager_chipdisplayfilterdata');
      localStorage.removeItem('Manager_clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }


  getbodycontent() {
    let CompanyID="";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID="";
    }
    else{
     CompanyID=this.filterform.value.owner
    }
    let filterdata = {
      "examPoolName": this.filterform.value.examPoolName.trim(),
      "owner": CompanyID,
     }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    // let companylistdataid = "";
    // for (let i = 0; i < this.companylistdata.length; i++) {
    //   if (this.companylistdata[i].companyName + ' ' + '('+ this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
    //     companylistdataid = this.companylistdata[i].companyId;
    //   }
    // }
    let companylistdataid= "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' +'('+ this.companylistdata[i].companyId + ')' === this.filterform.value.owner) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    if(this.from==='Manager'){
    this.apibodycontent = {
      "examPoolName": this.filterform.value.examPoolName.trim(),
      "owner": companylistdataid,
    }
  }

  else if(this.from==='ViewManager'){
    this.apibodycontent = {
      "examPoolName": this.filterform.value.examPoolName.trim(),
      "owner":companylistdataid,
    }
  }
  }


  checkformvalue() {
    let formempty;
    if ( this.filterform.value.examPoolName.trim() === "" && this.filterform.value.owner.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

}
