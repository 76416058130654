import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExamManagementComponent } from 'src/app/Administrator/exam-management/exam-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-addremovequestions',
  templateUrl: './addremovequestions.component.html',
  styleUrls: ['./addremovequestions.component.scss']
})
export class AddremovequestionsComponent implements OnInit {
  loadspinner: any;
  disablebutton = false;
  displayedColumns: string[] = ['POOL NAME', 'TOTAL NUMBER OF QUESTIONS', 'SELECT QUESTIONS'];
  dataSource: any;
  ELEMENT_DATA: any = [];
  user_ids = ["id1", "id2", "id3", "id4"];
  totalitemlength: any;
  indexfirst: any;
  indexlast: any;
  totalpages: any;
  pagesize: any;
  Rowperpage: any;
  sortby: any;
  sortOrder: any;
  pageSizeOptions: any = [];
  pagecountform = new FormControl();
  filterValue: any = '';
  questPoolName: any;
  isDisabledselectedQuestions: any=true;
  pageview: any;
  examId: any;
  @ViewChild(MatAutocompleteTrigger) matAutocomplete: MatAutocompleteTrigger;
  searchinput: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog1: MatDialog, public dialog: MatDialogRef<ExamManagementComponent>) {
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
    this.questPoolName = data['questPoolName'];
    if (this.questPoolName == undefined || this.questPoolName == "") {
      this.questPoolName = null;
    }
    this.pageview = data['pageview'];
    this.examId = data['examId'];
    this.pagesize = '1';
    this.Rowperpage = "10";
    this.sortby = "questPoolName";
    this.sortOrder = "ASC";
    this.pageSizeOptions = [
      { id: '10', value: '10' },
      { id: '25', value: '25' },
      { id: '50', value: '50' },
      { id: '100', value: '100' },
    ];

    let compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body
    if (cacheddata === "System Administrator") {
      body = {
        "companyId": "",
        "examId": this.examId,
      }
    }
    else {
      body = {
        "companyId": compId,
        "examId": this.examId,
      }
    }
    this.filterapipaginationcall(body)
  }

  ngOnInit(): void {

  }

  // grouptable -- pagination -- input
  updateManualPageinput(value, totalpagesarray) {
    if (value > totalpagesarray) {
      $("#pagination_grouptable").val(totalpagesarray);
      value = totalpagesarray;
    }
    this.matAutocomplete.closePanel();
    if (parseInt(value) >= 1 && parseInt(value) <= totalpagesarray) {
      this.pagesize = value;
      this.checkfilteredchipCache();
    }
    else {
      this.pagesize = totalpagesarray;
      this.checkfilteredchipCache();
    }
  }

  // grouptable -- pagination
  updateManualPage(value) {
    this.pagesize = value;
    this.checkfilteredchipCache();
  }

  // Table - Row per page
  updateRowManualPage(value) {
    this.Rowperpage = value;
    this.pagesize = '1';
    this.pagecountform.setValue(this.pagesize);
    this.checkfilteredchipCache();

  }




  onPaginateChange(arrowdirection) {
    let pagesizenumber: any;
    if (arrowdirection === "arrowright") {
      pagesizenumber = parseInt(this.pagesize) + 1;
      this.pagesize = pagesizenumber.toString();
      this.checkfilteredchipCache();
    }
    else if (arrowdirection === "arrowleft") {
      pagesizenumber = parseInt(this.pagesize) - 1;
      this.pagesize = pagesizenumber.toString();
      this.checkfilteredchipCache();
    }
  }

  arrow_upward(datatype, data, id) {
    this.sortby = data;
    this.sortOrder = "ASC";
    this.sortorderCSS(id);
    this.checkfilteredchipCache();
  }


  arrow_downward(datatype, data, id) {
    this.sortby = data;
    this.sortOrder = "DESC";
    this.sortorderCSS(id);
    this.checkfilteredchipCache();
  }

  sortorderCSS(id) {
    for (var i = 0; i < this.user_ids.length; i++) {
      if (id == this.user_ids[i]) {
        let elemt = document.getElementById(id);
        elemt.style.color = '#eaa22c'
      } else {
        let elemt1 = document.getElementById(this.user_ids[i]);
        elemt1.style.color = "white";
      }
    }
  }

  cancelbtn() {
    this.dialog.close();
  }
  save() {
    let body = [];
    for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
      if (this.ELEMENT_DATA[i]["selectedQuestions"] != 0 || this.ELEMENT_DATA[i]["selectedQuestions"] != "") {
        body.push(this.ELEMENT_DATA[i])
      }
    }

    this.loadspinner = true;
    let endpoint = 'exams/addremovequestion/save';

    let apibody = {
      "examId": this.examId,
      "addRemoveQuestionPoolList": body
    }

    console.log(apibody)
    this.apiService.getpoollist(endpoint, apibody, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        if (response["message"] === 'Success') {
          //if(this.pageId==1){
          this.dialog.close('success');

        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
        }
      },
        (err) => {
          this.loadspinner = false;

        });
  }

  EnterSubmit(event) {
    let compId = localStorage.getItem('procCompId');
    if (event.keyCode === 13) {
      let body = {
        "companyId": compId,
        "questPoolName": this.searchinput,
        "examId": this.examId,
      }
      this.filterapipaginationcall(body);
    }
  }

  inputchangeLogin(searchinput) {
    this.searchinput = searchinput.trim();
    if(this.searchinput == ""){
      this.search(searchinput);
    }
  }

  blurTitkeweight(value, id, index) {
    let a = parseInt(value.trim());
    if ((isNaN(a))) {
      this.ELEMENT_DATA[index].selectedQuestions = 0;

    }

  }

  inputchangeinpTitleWeight(value, id, index) {
    // console.log(id);
    let p: any;
    let q: any;
    // for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
        p = this.ELEMENT_DATA[index]['selectedQuestions'];
        q = parseInt(this.ELEMENT_DATA[index]['totalNumbeOfQuestions']);
        console.log(q)
        // if value typed > 100 put dialogue
        let a = parseInt(value.trim());
        if ((isNaN(a))) {
        }
        else {
          if (a > q) {
            this.commondialogue(id, index, q)
          }
        // }
    }
  }


  commondialogue(id, index, q) {
    let data = {
      status: "Total Questions should not be more than " + q
    };
    const dialogRef = this.dialog1.open(DialogpopupComponent, {
      width: '550px',
      autoFocus: false,
      // height: '350px',
      data: {
        from: 'error-popup',
        name: data
      },
      disableClose: true,
      position: {
        // top: '40vh'
        // left: '50%'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // $('#' + id).val(0);
      this.ELEMENT_DATA[index].selectedQuestions = 0;
      // console.log(this.ELEMENT_DATA)
    });
  }

  search(searchdata) {
    let searchinput =searchdata.trim();
    let compId = localStorage.getItem('procCompId');
    if (searchinput === "") {
      let body = {
        "companyId": "",
        "questPoolName": "",
        "examId": this.examId,
      }
      this.filterapipaginationcall(body);
    }
    let body = {
      "companyId": compId,
      "questPoolName": searchinput,
      "examId": this.examId,
    }
    this.filterapipaginationcall(body);
  }


  checkfilteredchipCache() {
    let compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body
    if (cacheddata === "System Administrator") {
      body = {
        "companyId": "",
        "examId": this.examId,
      }
    }
    else {
      body = {
        "companyId": compId,
        "examId": this.examId,
      }
    }
    // this.save()
    this.filterapipaginationcall(body);


  }
  filterapipaginationcall(data) {
    // create bodu content for api call
    this.loadspinner = true;
    const endpoint1 = 'exams/addremovequestion';
    const endpoint2 = 'view';
    let pageNo = this.pagesize;
    let sortby = this.sortby;
    let sortOrder = this.sortOrder;
    let itemPerPage = this.Rowperpage;
    let body = data
    this.apiService.exam_addremovequestion(pageNo, sortby, sortOrder, itemPerPage, endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        this.ELEMENT_DATA = response['addRemoveQuestionFilterData'];
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.ELEMENT_DATA.map(i => { i["isSelected"] = false });

        if (this.pageview === "View") {
          this.isDisabledselectedQuestions = true;
        }
        else {
          this.isDisabledselectedQuestions = false;
        }
        setTimeout(() => {
          this.pagecountform.setValue(this.pagesize);
          this.totalitemlength = response['data']['totalrows'];
          this.totalpages = response['data']['totalpages'];
          this.pagesize = this.pagesize;
          this.Rowperpage = this.Rowperpage;
          this.indexfirst = ((parseInt(this.pagesize) - 1) * parseInt(this.Rowperpage)) + 1;
          this.indexlast = parseInt(this.pagesize) * parseInt(this.Rowperpage) > this.totalitemlength ? this.totalitemlength : parseInt(this.pagesize) * parseInt(this.Rowperpage);
          this.customCSS();
        });

      },
        (err) => {
          this.loadspinner = false;
          //  console.log(err)

        });
  }

  customCSS() {
    if (parseInt(this.pagesize) === 1) {
      $('#paginationarrowleftid').css("color", '#c9c7c7');   // disable
      $('#paginationarrowrightid').css("color", '#696969'); // enable
      $('#paginationarrowleftid').css("pointer-events", 'none');
      $('#paginationarrowrightid').css("pointer-events", 'auto');
      if (this.totalpages === 1) {
        $('#paginationarrowleftid').css("color", '#c9c7c7');
        $('#paginationarrowrightid').css("color", '#c9c7c7');
        $('#paginationarrowleftid').css("pointer-events", 'none');
        $('#paginationarrowrightid').css("pointer-events", 'none');
      }

    }
    else if (parseInt(this.pagesize) === this.totalpages) {
      $('#paginationarrowleftid').css("color", '#696969');
      $('#paginationarrowrightid').css("color", '#c9c7c7');
      $('#paginationarrowleftid').css("pointer-events", 'auto');
      $('#paginationarrowrightid').css("pointer-events", 'none');
    }
    else {
      $('#paginationarrowleftid').css("color", '#696969');
      $('#paginationarrowrightid').css("color", '#696969');
      $('#paginationarrowleftid').css("pointer-events", 'auto');
      $('#paginationarrowrightid').css("pointer-events", 'auto');
    }
  }


}
