

<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>
<br>
  <div class="acknowledgement">
    <div class="row title">
      EnergyU Terms & Conditions
    </div>
 <hr>
 <div class="content">
   <!-- <div class="subtitle">
     Posted By - {{postedby}} on {{posteddate}}
   </div> -->
   <div class="scroll-content">
 <div class="content-text " id="description1">
  
 </div>
 <!-- <div class="subtitle">
Company Info
 </div> -->
 <div class="subtitle">
  {{companyName}}
   </div>


 <div class="content-text " id="description2">
  
 </div>
 </div>
 <div class="confirmbtn-cls" *ngIf="needAcceptance==true">
  
    <div class="col-12 col-xl-12" style="text-align: center;margin-bottom:10px"><br>
      <mat-checkbox class="termspopup-checkbox" [(ngModel)]="accept" (change)="checkterm($event.checked)">
      </mat-checkbox>
      <span class="checkboxlabel"> &nbsp;I agree to the terms & conditions</span>
     
    </div>
    <button class="confirmbtn" id="btntermid" type="submit" (click)="confirm()" disabled>
      SUBMIT
  </button>
 </div>
 <div class="confirmbtn-cls" *ngIf="needAcceptance==false">
  
 
  <button class="confirmbtn"  type="submit" (click)="confirm()" >
    SUBMIT
</button>
</div>

 <div *ngIf="termsconditions_list.length>1" class="footer">
  {{itemNumber+1}} out of {{termsconditions_list.length}}
   </div>
</div>
  </div>




