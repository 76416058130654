import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import {CommondataserviceService} from 'src/app/Services/commondataservice.service'
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-assignremovecourse',
  templateUrl: './assignremovecourse.component.html',
  styleUrls: ['./assignremovecourse.component.scss']
})
export class AssignremovecourseComponent implements OnInit {
menuTitle: any;
displayedColumnsGrouplist: string[] = ['chechbox','title','category','type'];
groupdataSource: any;
groupELEMENT_DATA: any = [];
group_todeletefilterdata: any = [];
group_filterdata: any = {};
group_filterbodydata: any;
group_hidbenclearbtn: any = true;
group_masterSelected: boolean = false;
group_checkedList: any = [];

public selectTabIndex: any;
totalitemlength: any;
indexfirst: any;
indexlast: any;
totalpages: any;
pagesize: any;
Rowperpage: any;
sortby: any;
sortOrder: any;
searchvalue:any='';
//  pagecountform: FormGroup;
loadspinner: any;
pageSizeOptions: any;
// group_ids = ["id1", "id2", "id3", "id4"];
group_ids = ["idc1", "idc2", "id3", "id4", "id5", "id6"];

pagecountform = new FormControl();
@ViewChild(MatAutocompleteTrigger) matAutocomplete: MatAutocompleteTrigger;

selectall: any;
excludedata: any;
excludelist:any=[];
excluded_data:any=[];


role: any;
catalogId:any;
companyId: any;
temp_data: any = [];
searchfiltervalue: any = '';
selectedTotalRecord:any;
catalogName:any='';
constructor(private activatedRoute: ActivatedRoute, public dialog: MatDialog, private formBuilder: FormBuilder, private router:Router,
 private headerservice: HeaderComponent, private apiService: ApiServiceService,public dialogclose: MatDialogRef<UserComponent>) {
 this.menuTitle = this.activatedRoute.snapshot.paramMap.get('menuTitle');
this.role= localStorage.getItem("loginRole");
this.catalogId=localStorage.getItem('assignedCatalogId');
this.catalogName=localStorage.getItem("catalogname")
 this.pagesize = '1';
 this.Rowperpage = "10";
 this.sortby = "title";
 this.sortOrder = "ASC";
 this. group_todeletefilterdata=[];
 this.pageSizeOptions = [
   { id: '10', value: '10' },
   { id: '25', value: '25' },
   { id: '50', value: '50' },
   { id: '100', value: '100' },
 ];

}

ngOnInit() {
  this.get_assignedCourselist();
}

get_assignedCourselist(){
  this.pagesize = '1';
  this.Rowperpage = "10";
  this.sortby = "title";
  this.sortOrder = "ASC";
  this.loadspinner = true;
  const endpoint1 = 'course';
  const endpoint2 = 'assigncoursescurforms';
  let pageNo = this.pagesize;
  let sortby = this.sortby;
  let sortOrder = this.sortOrder;
  let itemPerPage = this.Rowperpage;
  let body = {
    "filters":  {
    "id":"",
    "requirementId":"",
    "type":"",
    "title":this.searchvalue,
    "category":"",
    "owner":"",
    "catalogId":this.catalogId
     } 
  }
  // this.apiService.assignableCourseViewTable(pageNo, sortby, sortOrder, itemPerPage, endpoint1, endpoint2, body, 'POST')
  this.apiService.getallassignedcourse(endpoint1, endpoint2, body, 'POST')
  .subscribe((response) => {
    this.loadspinner = false;
    this.temp_data = response['courseCurFormList'];
    this.selectedTotalRecord=response['data'].totalrows;
    this.Group_cacheddatadisplaychip();
  },
    (err) => {
      this.loadspinner = false;
    });

}

// on load display chip if cached data present
Group_cacheddatadisplaychip() {
 // on relead check if existing chips selected to display on UI
 let data = localStorage.getItem("group_chipdisplayfilterdata");
 if (data === null || data === undefined || data === "") {

 } else {
   this.group_hidbenclearbtn = false;
   this.group_filterdata = JSON.parse(data);
 }
 // if filter selected call api --- else call default api
 var group_filterapibodycontent;
 try {
   group_filterapibodycontent = JSON.parse(localStorage.getItem("group_filterapibodycontent"));
   if (group_filterapibodycontent === null || group_filterapibodycontent === undefined || group_filterapibodycontent === "") {
    this.loaddefaultpaginationapi();
   }
   else {
     this.group_filterapipaginationcall(group_filterapibodycontent)
   }
 } catch (e) {
   group_filterapibodycontent = {};    // set default value if localStorage parsing failed
 }

}

// default load first method
loaddefaultpaginationapi() {
  let data:any={};
  this.companyId=localStorage.getItem("companyId");
  if(this.role=='System Administrator'){
    data={
      "id":"",
      "requirementId":"",
      "type":"",
      "title":this.searchvalue,
      "category":"",
      "owner":"",
      "catalogId":""
    }
  }else{
    data={
      "id":"",
      "requirementId":"",
      "type":"",
      "title":this.searchvalue,
      "category":"",
      "owner":this.companyId,
      "catalogId":""
    }
  }
this.group_filterapipaginationcall(data)

}


// post --- on click sort after filter
group_filterapipaginationcall(data) {
  // create bodu content for api call
  this.loadspinner = true;
  const endpoint1 = 'course'
  const endpoint2 = 'viewcoursescurriculumforms';
  let pageNo = this.pagesize;
  let sortby = this.sortby;
  let sortOrder = this.sortOrder;
  let itemPerPage = this.Rowperpage;
  let body = {
    "filters": data
  }
  // call api get response
  this.apiService.assignRemoveCourse(pageNo, sortby, sortOrder, itemPerPage, endpoint1, endpoint2, body, 'POST')
  .subscribe((response) => {
  this.loadspinner = false;
  // this.groupELEMENT_DATA = response['courseCurFormList'];
  let value=response['courseCurFormList'];
  let totalRecord=response['data'].totalrows;
  if(totalRecord==this.selectedTotalRecord){
    this.group_masterSelected = true;
  }
  value.map(i => { i["isSelected"] = false });
  this.group_checkedList=this.temp_data;
  for(var i=0; i<this.temp_data.length; i++){
    for(var j=0; j<value.length; j++){
      if(this.temp_data[i].requirementId==value[j].requirementId){
         value[j].isSelected=true;
       }
    }
    }
    this.groupELEMENT_DATA = value;
  this.groupdataSource = new MatTableDataSource<any>(this.groupELEMENT_DATA);
  console.log("this.groupdataSource",this.groupdataSource);
  // this.groupELEMENT_DATA.map(i => { i["isSelected"] = false });
  if (this.groupELEMENT_DATA.length > 0) {
    this.Commoncheckboxfunction();
  } else {
    this.group_masterSelected = false;
  }
  
  
  
  setTimeout(() => {
    this.pagecountform.setValue(this.pagesize);
    this.totalitemlength = response['data']['totalrows'];
    this.totalpages = response['data']['totalpages'];
    this.pagesize = this.pagesize;
    this.Rowperpage = this.Rowperpage;
    this.indexfirst = ((parseInt(this.pagesize) - 1) * parseInt(this.Rowperpage)) + 1;
    this.indexlast = parseInt(this.pagesize) * parseInt(this.Rowperpage) > this.totalitemlength ? this.totalitemlength : parseInt(this.pagesize) * parseInt(this.Rowperpage);
    this.customCSS();
  }, 1000);
  },
  (err) => {
  this.loadspinner = false;
  });
  
}



group_filterapipaginationcall1(data) {
  // create bodu content for api call
  this.loadspinner = true;
  const endpoint1 = 'course'
  const endpoint2 = 'viewcoursescurriculumforms';
  this.pagesize="1";
  let pageNo = this.pagesize;
  let sortby = this.sortby;
  let sortOrder = this.sortOrder;
  let itemPerPage = this.Rowperpage;
  let body = {
    "filters": data
  }
  // call api get response
  this.apiService.assignRemoveCoursewithoutparams(pageNo, sortby, sortOrder, itemPerPage, endpoint1, endpoint2, body, 'POST')
  .subscribe((response) => {
  this.loadspinner = false;
  // this.groupELEMENT_DATA = response['courseCurFormList'];
  let value=response['courseCurFormList'];
  let totalRecord=response['data'].totalrows;
  if(totalRecord==this.selectedTotalRecord){
    this.group_masterSelected = true;
  }
  // value.map(i => { i["isSelected"] = false });
  // this.group_checkedList=this.temp_data;
  // for(var i=0; i<this.temp_data.length; i++){
  //   for(var j=0; j<value.length; j++){
  //     if(this.temp_data[i].requirementId==value[j].requirementId){
  //        value[j].isSelected=true;
  //      }
  //   }
  //   }
    this.groupELEMENT_DATA = value;
  this.groupdataSource = new MatTableDataSource<any>(this.groupELEMENT_DATA);
  console.log("this.groupdataSource",this.groupdataSource);
  // this.groupELEMENT_DATA.map(i => { i["isSelected"] = false });
  if (this.groupELEMENT_DATA.length > 0) {
    this.Commoncheckboxfunction();
  } else {
    this.group_masterSelected = false;
  }
  
  
  
  setTimeout(() => {
    this.pagecountform.setValue(this.pagesize);
    this.totalitemlength = response['data']['totalrows'];
    this.totalpages = response['data']['totalpages'];
    this.pagesize = this.pagesize;
    this.Rowperpage = this.Rowperpage;
    this.indexfirst = ((parseInt(this.pagesize) - 1) * parseInt(this.Rowperpage)) + 1;
    this.indexlast = parseInt(this.pagesize) * parseInt(this.Rowperpage) > this.totalitemlength ? this.totalitemlength : parseInt(this.pagesize) * parseInt(this.Rowperpage);
    this.customCSS();
  }, 1000);
  },
  (err) => {
  this.loadspinner = false;
  });
  
}

// on pagination change check if any filter item present

checkfilteredchipCache(){
  
 if (this.groupELEMENT_DATA.length > 0) {
   var group_filterapibodycontent;
   try {
     group_filterapibodycontent = JSON.parse(localStorage.getItem("group_filterapibodycontent"));
     if (group_filterapibodycontent === null || group_filterapibodycontent === undefined || group_filterapibodycontent === "") {
       this.grouplistPaginationApicall();
     }
     else {
       this.group_filterapipaginationcall(group_filterapibodycontent)
     }
   } catch (e) {
     group_filterapibodycontent = {};    // set default value if localStorage parsing failed
   }
 }
}

// grouptable -- pagination -- input
grouplistupdateManualPageinput(value, totalpagesarray){
 if(value > totalpagesarray){
   $("#pagination_grouptable").val(totalpagesarray);
   value = totalpagesarray;
 }
 this.matAutocomplete.closePanel();
 if (parseInt(value) >=1 && parseInt(value) <=totalpagesarray) {
   this.pagesize = value;
   this.checkfilteredchipCache();
 }
 else{
   this.pagesize = totalpagesarray;
   this.checkfilteredchipCache();
 }
}

// grouptable -- pagination
grouplistupdateManualPage(value) {
 this.pagesize = value;
 this.checkfilteredchipCache();
}

// Table - Row per page
grouplistupdateRowManualPage(value) {
 this.Rowperpage = value;
 this.pagesize = '1';
 this.pagecountform.setValue(this.pagesize);
 this.checkfilteredchipCache();

}

grouplistonPaginateChange(arrowdirection) {
 let pagesizenumber: any;
 if (arrowdirection === "arrowright") {
   pagesizenumber = parseInt(this.pagesize) + 1;
   this.pagesize = pagesizenumber.toString();
   this.checkfilteredchipCache();
 }
 else if (arrowdirection === "arrowleft") {
   pagesizenumber = parseInt(this.pagesize) - 1;
   this.pagesize = pagesizenumber.toString();
   this.checkfilteredchipCache();
 }
}

Grouplistarrow_upward(datatype, data, id) {
 this.sortby = data;
 this.sortOrder = "ASC";
 this.sortorderCSS(id);
 this.checkfilteredchipCache();
}


Grouplistarrow_downward(datatype, data, id) {
 this.sortby = data;
 this.sortOrder = "DESC";
 this.sortorderCSS(id);
 this.checkfilteredchipCache();
}

sortorderCSS(id){
 for (var i = 0; i < this.group_ids.length; i++) {
   if (id == this.group_ids[i]) {
     let elemt = document.getElementById(id);
     elemt.style.color = '#eaa22c'
   } else {
     let elemt1 = document.getElementById(this.group_ids[i]);
     elemt1.style.color = "white";
   }
 }
}


// simple sort -- on load
grouplistPaginationApicall() {
  this.loadspinner = true;
  let data:any={}
  if(this.role=='System Administrator'){
    data={
          "id":"",
          "requirementId":"",
          "type":"",
          "title":this.searchvalue,
          "category":"",
          "owner":"",
          "catalogId":""
      }
  }else{
    data={
          "id":"",
          "requirementId":"",
          "type":"",
          "title":this.searchvalue,
          "category":"",
          "owner":this.companyId,
          "catalogId":""
    }
  }
  this.group_filterapipaginationcall(data)
  }

customCSS() {
  
 if (parseInt(this.pagesize) === 1) {
   $('#paginationarrowleftid1').css("color", '#c9c7c7');   // disable
   $('#paginationarrowrightid1').css("color", '#696969'); // enable
   $('#paginationarrowleftid1').css("pointer-events", 'none');
   $('#paginationarrowrightid1').css("pointer-events", 'auto');
   if (this.totalpages === 1) {
     $('#paginationarrowleftid1').css("color", '#c9c7c7');
     $('#paginationarrowrightid1').css("color", '#c9c7c7');
     $('#paginationarrowleftid1').css("pointer-events", 'none');
     $('#paginationarrowrightid1').css("pointer-events", 'none');
   }

 }
 else if (parseInt(this.pagesize) === this.totalpages) {
   $('#paginationarrowleftid1').css("color", '#696969');
   $('#paginationarrowrightid1').css("color", '#c9c7c7');
   $('#paginationarrowleftid1').css("pointer-events", 'auto');
   $('#paginationarrowrightid1').css("pointer-events", 'none');
 }
 else {
   $('#paginationarrowleftid1').css("color", '#696969');
   $('#paginationarrowrightid1').css("color", '#696969');
   $('#paginationarrowleftid1').css("pointer-events", 'auto');
   $('#paginationarrowrightid1').css("pointer-events", 'auto');
 }
}

// group check item functionality

Commoncheckboxfunction(){
if (this.group_masterSelected === false) {
if (this.group_checkedList.length > 0) {
 for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
   for (var j = 0; j < this.group_checkedList.length; j++) {
     if (this.selectall === false) {
       if (this.group_checkedList[j].requirementId == this.groupELEMENT_DATA[i].requirementId) {
         this.groupELEMENT_DATA[i].isSelected = true;
       }
     }
     else if (this.selectall === true) {
       this.groupELEMENT_DATA[i].isSelected = true;
       for (var k = 0; k < this.excludelist.length; k++) {
         if (this.excludelist[k].requirementId == this.groupELEMENT_DATA[i].requirementId) {
           this.groupELEMENT_DATA[i].isSelected = false;
         }
       }
 
     }
     else {
       if (this.group_checkedList[j].requirementId == this.groupELEMENT_DATA[i].requirementId) {
         this.groupELEMENT_DATA[i].isSelected = true;
       }
     }

   }
 }
}

}
else if (this.group_masterSelected === true) {
for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
this.groupELEMENT_DATA[i].isSelected = true;
this.group_checkUncheckAll();
}
}
}


group_checkUncheckAll() {
 if (this.group_checkedList.length > 0 && !this.group_masterSelected) {
   for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
     this.groupELEMENT_DATA[i].isSelected = this.group_masterSelected;

     for (var j = 0; j < this.group_checkedList.length; j++) {
       this.group_checkedList=[];
       this.selectall = false; 
     }
   }
 } else {
   for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
     this.groupELEMENT_DATA[i].isSelected = this.group_masterSelected;
     this.selectall = true;
     this.excluded_data=[];
     this.excludelist=[];
   }
 }
 this.group_getCheckedItemList();
}

group_isitemSelected(element) {
 if(element.isSelected===false){
   this.excludelist.push(element);
 }else if(element.isSelected===true && this.selectall===true){
   this.excludelist = this.excludelist.filter(function(item) {
    return item !== element 
   })
 }
 // set isChexked flag true/false
 this.groupELEMENT_DATA.every(function (item: any) {
   return item.isSelected == element.isSelected;
 })

 // check if content is selected -- if selected set variable true
 let present = true;
 for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
   if (!this.groupELEMENT_DATA[i].isSelected) {
     present = false;
   }
 }
 if (present) {
   // this.group_masterSelected = true;
 } else {
   this.group_masterSelected = false;
 }
 this.group_getCheckedItemList();
}

group_getCheckedItemList() {
 for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
   let present = false;
   for (var j = 0; j < this.group_checkedList.length; j++) {
     if (this.group_checkedList[j].requirementId == this.groupELEMENT_DATA[i].requirementId) {
       present = true;
       if (!this.groupELEMENT_DATA[i].isSelected) {
         this.group_checkedList.splice(j, 1);
       }
     }
   }
   if (this.groupELEMENT_DATA[i].isSelected) {
     if (!present) {
       this.group_checkedList.push(this.groupELEMENT_DATA[i]);
     }
   }
 }
}

save(){
  this.loadspinner = true;
  const endpoint1 = 'course'
  const endpoint2 = 'assigncoursecatalogs/' +  this.catalogId;
  let assignedCourses_list:any=[];
  let selectAll: any;
  // if(this.group_checkedList.length>0){
    if (this.group_checkedList.length != 0) {
      if (this.group_masterSelected === true) {
        assignedCourses_list = [];
        selectAll = true;
      }else{
        for(var i=0; i<this.group_checkedList.length; i++){
          assignedCourses_list.push({
            requirementId: this.group_checkedList[i].requirementId,
            category: this.group_checkedList[i].category
        });
        selectAll = false;
        }
      }
  }else{
    selectAll = false;
  }
  // }
  let body = {
    "selectAll":selectAll,
    "filterData":this.searchfiltervalue,
    "courseCurFormListData": assignedCourses_list
  }
  // call api get response
  this.apiService.assignRemoveCourseSave(endpoint1, endpoint2, body, 'PATCH')
  .subscribe((response) => {
  this.loadspinner = false;
  if(response["message"]=== 'success'){
    this.cancelbtn();
    // this.navigateassignCourse();
   }
   else{
    this.loadspinner = false;
    let data = {
      status: response["message"]
    };
    this.dialog.open(DialogpopupComponent, {
      width: '550px',
      autoFocus: false,
      // height: '350px',
      data: {
        from: 'error-popup',
        name: data
    },
      disableClose: true,
      position: {
        top: '40vh'
        // left: '50%'
      }
    });
  }
  },
  (err) => {
    this.loadspinner = false;
   });
}

cancelbtn() {
  // if filterdata not present --- on first load cancel - clear data
  this.dialogclose.close({ method: 'close' })
}

navigateassignCourse(){
  this.dialogclose.close({ method: 'close' })
  let data = "Assignable Content > Assigned Course Lists";
  this.router.navigate(['AssignableContent/assignedCourse'], { queryParams: { menuTitle: 'AssignableContent' } });
  localStorage.setItem("selectTabIndexName", 'Assigned Course Lists');
  localStorage.setItem("selectTabBreadCrumbs", data);
  setTimeout(() => {
    this.headerservice.updateHeaderBreadcrumb("Assigned Course Lists", data);
  }, 500);
}

search(searchinput) {
  let data:any={};
  this.companyId=localStorage.getItem("companyId");
  if(this.role=='System Administrator'){
    data={
      "id":"",
      "requirementId":"",
      "type":"",
      "title":searchinput,
      "category":"",
      "owner":"",
      "catalogId":""
    }
  }else{
    data={
      "id":"",
      "requirementId":"",
      "type":"",
      "title":searchinput,
      "category":"",
      "owner":this.companyId,
      "catalogId":""
    }
  }
  this.group_filterapipaginationcall1(data)
}

clearTable(searchinput){
  this.searchfiltervalue=searchinput.trim();
  if(searchinput === ""){
  let data:any={};
  this.companyId=localStorage.getItem("companyId");
  if(this.role=='System Administrator'){
    data={
      "id":"",
      "requirementId":"",
      "type":"",
      "title":searchinput,
      "category":"",
      "owner":"",
      "catalogId":""
    }
  }else{
    data={
      "id":"",
      "requirementId":"",
      "type":"",
      "title":searchinput,
      "category":"",
      "owner":this.companyId,
      "catalogId":""
    }
  }
    this.group_filterapipaginationcall1(data)
  }
}

EnterSubmit(event){
  if(event.keyCode === 13){
    this.search(this.searchfiltervalue);
    event.preventDefault();
  }
}

}
