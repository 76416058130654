import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ExamManagementComponent } from 'src/app/Administrator/exam-management/exam-management.component';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';


@Component({
  selector: 'app-questionlistfilter',
  templateUrl: './questionlistfilter.component.html',
  styleUrls: ['./questionlistfilter.component.scss']
})
export class QuestionlistfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  isDisabled: boolean;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<ExamManagementComponent>) {
    this.filterform = this.formBuilder.group({
      QuestionID: [''],
      QuestionText: [''],
      CompanyId: [''],
      QuestionPool: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['QuestionID'].setValue(data["Question ID"]);
      this.filterform.controls['QuestionText'].setValue(data["Question Text"]);
      this.filterform.controls['CompanyId'].setValue(data["Owner"]);
      this.filterform.controls['QuestionPool'].setValue(data["Question Pool"]);
      this.clearfilterfunction();
    }
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      this.isDisabled=true;
      this.disablebutton=false;
    }
    else{
      this.isDisabled=false;
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Owner"){
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Question Pool"){
          this.filterform.controls['QuestionPool'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Question Text"){
          this.filterform.controls['QuestionText'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Question ID"){
          this.filterform.controls['QuestionID'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    let endpoint2="exams/questionpool/pooldetails";
    let compId=localStorage.getItem('procCompId');
    let body={
      "companyId":compId
      }
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.loadspinner = true;
        this.apiService.questionPool(endpoint2, body,'GET')
        .subscribe((response) => {
          this.statuslist  = response;
          this.loadspinner = false;
        },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
        // this.statuslist = [
        //   { "status_id": 1, "statusName": "Active" },
        //   { "status_id": 2, "statusName": "Inactive" }
        // ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
  }


  // on keyup enable filter button
  inputchangegroupname(value){
   this.dynamicEnableDisablebtn()
  }

  inputchangegroupID(value){
  this.dynamicEnableDisablebtn()
  }

  selectCompanyID(value) {
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['QuestionPool'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }


  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let CompanyID="";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID="";
    }
    else{
     CompanyID=this.filterform.value.CompanyId
    }
    let groupfilterdata = {
      "Question ID": this.filterform.value.QuestionID.trim(),
      "Question Text": this.filterform.value.QuestionText.trim(),
      "Question Pool": this.filterform.value.QuestionPool.trim(),
      "Owner": CompanyID,
    }
    this.getapibodycontent();
    return groupfilterdata

  }

  getapibodycontent() {
    let companylistdataid= "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '('+ this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    this.groupapibodycontent = {
      "questId": this.filterform.value.QuestionID.trim(),
      "questText": this.filterform.value.QuestionText.trim(),
      "questPoolName": this.filterform.value.QuestionPool.trim(),
      "questOwner": companylistdataid,
    }
  }

  checkformvalue(){
    let formempty;
    if(this.filterform.value.QuestionID.trim()==="" && this.filterform.value.QuestionText.trim()==="" &&
    this.filterform.value.CompanyId.trim()==="" && this.filterform.value.QuestionPool.trim()==="" ){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }
}
