import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
import * as $ from 'jquery';
@Component({
  selector: 'app-termsconditionspopup',
  templateUrl: './termsconditionspopup.component.html',
  styleUrls: ['./termsconditionspopup.component.scss']
})
export class TermsconditionspopupComponent implements OnInit {
  loadspinner:any;
  termsconditions_list:any=[];
  termsconditionId:any='';
  itemNumber=0;
  accept=false;
  companyName: any;
  needAcceptance:any;
   constructor(@Inject(MAT_DIALOG_DATA) public data: string, private apiService: ApiServiceService, public dialog: MatDialog) {
     
     this.termsconditions_list=data['termsconditions_list'];
     this.termsconditionId=this.termsconditions_list[this.itemNumber].termConditionId;

  setTimeout(()=>{
    document.getElementById("description1").innerHTML = this.termsconditions_list[this.itemNumber].termsconditions;
    document.getElementById("description2").innerHTML = this.termsconditions_list[this.itemNumber].companytermsconditions;
    this.needAcceptance=this.termsconditions_list[this.itemNumber].acceptFlag;
    this.companyName = this.termsconditions_list[this.itemNumber].companyName;
    setTimeout(()=>{
      (<HTMLInputElement>document.getElementById("btntermid")).disabled = true;
      $('#btntermid').css('opacity', '0.2');
      $('#btntermid').css('background-color', '#143249');
      $('#btntermid').css('cursor', 'not-allowed');
    },200)
    
  }, 1000)

    }

    ngOnInit(): void {
    
    }

    confirm(){
    
      if(this.itemNumber>(this.termsconditions_list.length-1)){
        localStorage.removeItem("user_loggedIN_terms")
        this.dialog.closeAll()
      }else{
        this.submit()
        
      }
     
    }
  
    submit(){
    this.loadspinner = true;
    let loginId= localStorage.getItem('loginId');
    //let loginId= "Allen";
    const endpoint1 = 'announcement/submit/'+loginId;
    
    let body={
      "termConditionId":this.termsconditions_list[this.itemNumber].termConditionId,
      "submitResult":true
  }
    this.apiService.confirm_Acknowledgement(endpoint1, body, 'POST')
      .subscribe((response) => {
        
        if(this.itemNumber<(this.termsconditions_list.length-1)){
          this.itemNumber=this.itemNumber+1;
          this.needAcceptance=this.termsconditions_list[this.itemNumber].acceptFlag;
          this.accept=false;
          setTimeout(()=>{

         
          if(this.needAcceptance == true){
          (<HTMLInputElement>document.getElementById("btntermid")).disabled = true;
          $('#btntermid').css('opacity', '0.2');
          $('#btntermid').css('background-color', '#143249');
          $('#btntermid').css('cursor', 'not-allowed');
          }
           document.getElementById("description1").innerHTML = this.termsconditions_list[this.itemNumber].termsconditions;
           document.getElementById("description2").innerHTML = this.termsconditions_list[this.itemNumber].companytermsconditions;
          }, 200);
           }else{
             this.dialog.closeAll()
           }
             this.loadspinner = false;
       },
       (err) => {​​​
        this.loadspinner = false;
        }​​​
       );
  }

  TermServices() {
    const dialogRef = this.dialog.open(DialogpopupComponent, {
      // width: '60vw',
      height: '70vh',
      panelClass: 'my-class',
      autoFocus: false,
      disableClose: true,
      position: {
        top: '10vh'
        // left: '50%'
      },
      data: {
        from:'terms-conditions'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
     });
  }

  
  // check box checked
  checkterm(event) {
    if (event === true) {
      (<HTMLInputElement>document.getElementById("btntermid")).disabled = false;
      $('#btntermid').css('opacity', '1.0');
      $('#btntermid').css('background-color', '#eca427');
      $('#btntermid').css('cursor', 'pointer');
    }
    else {
      (<HTMLInputElement>document.getElementById("btntermid")).disabled = true;
      $('#btntermid').css('opacity', '0.2');
      $('#btntermid').css('background-color', '#143249');
      $('#btntermid').css('cursor', 'not-allowed');
    }

  }
}
