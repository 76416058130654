import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-questionpoolfilter',
  templateUrl: './questionpoolfilter.component.html',
  styleUrls: ['./questionpoolfilter.component.scss']
})
export class QuestionpoolfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  from:any;
  managerName:any;
  isDisabled: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string,private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.from=data['from'];
    this.managerName=data['managerName'];
    this.filterform = this.formBuilder.group({
      // Login: [''],
      Name: [''],
      CompanyId: [''],
      // EmpID: [''],
      // Email: ['', [Validators.pattern('(?!.{51})[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-zA-Z]{2,3}')]],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit() {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Evaluator_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      // this.filterform.controls['Login'].setValue(data["Login"]);
      // this.filterform.controls['Name'].setValue(data["Name"]);
      // this.filterform.controls['CompanyId'].setValue(data["Company (COID)"]);
      // this.selectCompanyID(data["Company (COID)"]);
      // this.filterform.controls['EmpID'].setValue(data["EMP ID"]);
      // this.filterform.controls['Email'].setValue(data["Email"]);
      this.filterform.controls['Name'].setValue(data["QuestionPoolName"]);
      this.filterform.controls['CompanyId'].setValue(data["Owner"]);
      this.clearfilterfunction();
    }
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      this.isDisabled=true;
      this.disablebutton=false;
    }
    else{
      this.isDisabled=false;
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Evaluator_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        // if (clearfilteritemkey[i] === "Login") {
        //   this.filterform.controls['Login'].setValue("");
        // }
        if (clearfilteritemkey[i] === "QuestionPoolName") {
          this.filterform.controls['Name'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Owner") {
          this.filterform.controls['CompanyId'].setValue("");
        }
        // else if (clearfilteritemkey[i] === "EMP ID") {
        //   this.filterform.controls['EmpID'].setValue("");
        // }
        // else if (clearfilteritemkey[i] === "Email") {
        //   this.filterform.controls['Email'].setValue("");
        // }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }


  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          // console.log(err)

        });
  }


  inputchangeLogin(value) {
    this.dynamicEnableDisablebtn()
  }
  inputchangeName(value) {
    this.dynamicEnableDisablebtn()
  }

  inputchangeEmpID(value) {
    this.dynamicEnableDisablebtn()
  }

  inputchangeEmail(value) {
    this.dynamicEnableDisablebtn()
  }

  selectCompanyID(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
      // change selected name to ID to call api
      let companylistdataid = "";
      for (let i = 0; i < this.companylistdata.length; i++) {
        if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === value) {
          companylistdataid = this.companylistdata[i].companyId;
        }
      }
      // on select Company (COID) -- get all other dropdown data ---- api call
      this.loadspinner = true;
      const endpoint1 = 'lookup';
      const queryparameter1 = companylistdataid;
      this.apiService.getwithparam(queryparameter1, endpoint1, 'GET')
        .subscribe((response) => {
          this.loadspinner = false;
          // this.JobPosition = response["jobTitles"];
        },
          (err) => {
            this.loadspinner = false;
            //  console.log(err)

          });
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Evaluator_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('Evaluator_filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('Evaluator_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('Evaluator_filterdata');
      localStorage.removeItem('Evaluator_chipdisplayfilterdata');
      localStorage.removeItem('Evaluator_clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('Evaluator_filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }


  getbodycontent() {
    let CompanyID="";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID="";
    }
    else{
     CompanyID=this.filterform.value.CompanyId
    }
    let filterdata = {
      // "Login": this.filterform.value.Login.trim(),
      // "Name": this.filterform.value.Name.trim(),
      // "Company (COID)": this.filterform.value.CompanyId.trim(),
      // "EMP ID": this.filterform.value.EmpID.trim(),
      // "Email": this.filterform.value.Email.trim(),

      "questPoolId":"",
      "QuestionPoolName":this.filterform.value.Name.trim(),
      "Owner": CompanyID,
      "questDescription":"",
      "totalNumberOfQuestions":""
    }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    let companylistdataid = "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    if(this.from==='Manager'){
    this.apibodycontent = {
      // "loginName": this.filterform.value.Login.trim(),
      // "name": this.filterform.value.Name.trim(),
      // "companyId": companylistdataid,
      // "empId": this.filterform.value.EmpID.trim(),
      // "email": this.filterform.value.Email.trim(),

      "questPoolId":"",
      "questPoolName": this.filterform.value.Name.trim(),
      "questPoolOwner": companylistdataid,
      "questDescription":"",
      "totalNumberOfQuestions":""
    }
  }

  else if(this.from==='ViewManager'){
    this.apibodycontent = {
      // "loginName": this.filterform.value.Login.trim(),
      // "displayName": this.filterform.value.Name.trim(),
      // "companyId": companylistdataid,
      // "empCode": this.filterform.value.EmpID.trim(),
      // "email": this.filterform.value.Email.trim(),
      // "managerName":this.managerName

      "questPoolId":"",
      "questPoolName": this.filterform.value.Name.trim(),
      "questPoolOwner": companylistdataid,
      "questDescription":"",
      "totalNumberOfQuestions":""
    }
  }
  }


  checkformvalue() {
    let formempty;
    if ( this.filterform.value.Name.trim() === "" && this.filterform.value.CompanyId.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

}
