import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-taskfilter',
  templateUrl: './taskfilter.component.html',
  styleUrls: ['./taskfilter.component.scss']
})
export class TaskfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  tasklist: any = [""];
  loginId: string;
  totalRows: any;
  uniqueTaskListName: any = [];
  TaskListName: any = [];

  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      TasklistName: [''],
      TaskName:[''],
      Status:[''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call

    this.loadspinner = true;
    const endpoint1 = 'task/tasklist';
    const endpoint2 = 'search';
 
    // this.loginId= localStorage.getItem('loginId');
    // let data={
    //   'loginName':this.loginId
    // }
    // let body = {
    //   "filters": data
    // }
    this.apiService.Learnertask_Tasklikstname(endpoint1,endpoint2,'POST')
      .subscribe((response) => {
        this.loadspinner = false;
     
        this.totalRows = response["data"].totalrows;
        console.log(this.totalRows)
        let pageNo = "1";
        let pageSize = this.totalRows
        this.apicallgettasklistname(pageNo,pageSize)
        this.statuslist = [
          { "status_id": 1, "statusName": "Qualified" },
          { "status_id": 2, "statusName": "Not Qualified" },
          { "status_id": 3, "statusName": "Suspended" },
        ]
        // this.tasklist = [
        //   { "status_id": 1, "tasklistName": "Kinder Morgan" },
        //   { "status_id": 2, "tasklistName": "Vectoran" },
        // ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
  }
  apicallgettasklistname(pageNo,pageSize){
    const endpoint1 = 'task/tasklist';
    const endpoint2 = 'search';
    this.apiService.Learnertask_getTaskListName(endpoint1,endpoint2,pageNo,pageSize,'POST')
      .subscribe((response) => {
        this.tasklist = response["taskList"];
        for(let item of this.tasklist){
          this.TaskListName.push(item.taskListName) 
        }
        this.uniqueTaskListName = this.TaskListName.filter((item, i, ar) => ar.indexOf(item) === i);
      })
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['TasklistName'].setValue(data["Task List Name"]);
      this.filterform.controls['TaskName'].setValue(data["Task Name"]);
      this.filterform.controls['Status'].setValue(data["Status"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Task List Name"){
          this.filterform.controls['TasklistName'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Task Name"){
          this.filterform.controls['TaskName'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Status"){
          this.filterform.controls['Status'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  inputchange0(value){
    this.dynamicEnableDisablebtn()
    }

    selectStatusName(value) {
      if(value==="" || value === undefined || value===null){
        // if none select set value as ''
        this.filterform.controls['Status'].setValue("");
        this.dynamicEnableDisablebtn()
      }
      else{
        this.disablebutton=false;
      }
    }
    selectTasklistName(value){
      if(value==="" || value === undefined || value===null){
        // if none select set value as ''
        this.filterform.controls['TasklistName'].setValue("");
        this.dynamicEnableDisablebtn()
      }
      else{
        this.disablebutton=false;
      }
    }
    cancelbtn() {
      // if filterdata not present --- on first load cancel - clear data
      let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
      if (data === "" || data === undefined || data === null) {
      }
      else {
        let groupfilterdata = this.getbodycontent();
        localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
        localStorage.removeItem('group_clearfilteritemkey');
      }
      this.dialog.close({ method: 'close' })
    }

    EnterSubmit(event){
      if(event.keyCode === 13){
       //this.Filter();
       this.dynamicEnableDisablebtn();
       if(this.disablebutton===true){
        event.preventDefault();
       }
       else{
        event.preventDefault();
        this.Filter();
       }

      }
    }


    Filter() {
      if (this.filterform.valid) {
        localStorage.removeItem('groupfilterdata');
        localStorage.removeItem('group_chipdisplayfilterdata');
        localStorage.removeItem('group_clearfilteritemkey');
        let groupfilterdata = this.getbodycontent();
        localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
        this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
      }

    }

    getbodycontent() {
      let groupfilterdata = {
        "Task List Name": this.filterform.value.TasklistName.trim(),
        "Task Name": this.filterform.value.TaskName.trim(),
        "Status": this.filterform.value.Status.trim(),
      }
      this.getapibodycontent();
      return groupfilterdata

    }

    getapibodycontent() {
      let loginName = localStorage.getItem('loginId')
      this.groupapibodycontent = {
        "taskListName": this.filterform.value.TasklistName.trim(),
        "taskName": this.filterform.value.TaskName.trim(),
        "status": this.filterform.value.Status.trim(),
        "loginName":loginName
      }
    }

    checkformvalue(){
      let formempty;
      if(this.filterform.value.TasklistName.trim()==="" && this.filterform.value.TaskName.trim()==="" && this.filterform.value.Status.trim()===""){
        formempty=true;
      }
      else {
        formempty = false;
      }
      return formempty;
    }

    dynamicEnableDisablebtn(){
      let checkformfield= this.checkformvalue();
      if(checkformfield===true){
        this.disablebutton=true;
      }
      else{
        this.disablebutton=false;
      }
    }
}


