<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol" style="margin-bottom: 14px;position: relative;">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label id="usernameid" class="labelname">Title</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpgroupname (keyup)="inputchangeID1(inpgroupname.value)" class="inputClassText"
            formControlName="Title">
        </mat-form-field>
      </div>

      <!-- <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Form ID</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpgroupID (keyup)="inputchangeID2(inpgroupID.value)" class="inputClassText"
            formControlName="FormID">
        </mat-form-field>

      </div> -->

      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Completion Date</mat-label><br>
        <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">

          <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" [max]="today" style="cursor: pointer;" class="textinputdate" placeholder="mm/dd/yyyy"
          readonly>
            <input matStartDate placeholder="mm/dd/yyyy" formControlName="start" (dateChange)="startChange($event)" style="cursor: pointer;" readonly>
            <input matEndDate placeholder="mm/dd/yyyy" formControlName="end" (dateChange)="endChange($event)" style="cursor: pointer;" readonly>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Category</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Category" placeholder="Category" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectCategory($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of category" [value]="item.categoryName">
              <div class="matoptiontxt">{{item.categoryName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Type</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="CompanyType" placeholder="Type" class="inputClass" panelClass="myPanelmatselectClass"
          (selectionChange)=selectType($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of type" [value]="item.typeName">
              <div class="matoptiontxt">{{item.typeName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Owner</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Owner" placeholder="Owner" panelClass="myPanelmatselectClass" class="inputClass"
          (selectionChange)=selectowner($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of companylistdata" [value]="item.companyName + ' ' +'('+ item.companyId + ')'">
              <div class="matoptiontxt">{{item.companyName}}&nbsp;({{item.companyId}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Status" placeholder="Status" panelClass="myPanelmatselectClass" class="inputClass"
          (selectionChange)=selectStatusName($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of statuslist" [value]="item.statusName">
              <div class="matoptiontxt">{{item.statusName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Shared Records</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="sharedRec" placeholder="Status" panelClass="myPanelmatselectClass" class="inputClass"
          (selectionChange)=selectSharedRec($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of sharedRec" [value]="item.statusName">
              <div class="matoptiontxt">{{item.statusName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </div>

      
     


      <!-- <div class="col-6 col-md-6 inputcolumn">
        <mat-checkbox labelPosition="after" formControlName="ISTEMPLATE"
        id="changepassword_checkbox" style="margin-left: -10px;"
        (change)=selectcheckbox($event.checked) class="course-checkbox">
        <span style="font-weight: bold;">Is Template</span>
        </mat-checkbox>
        </div> -->

      <div class="row">
        <div class="col-6 cancelbtncol"><br><br>
          <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
            <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
            Cancel
          </button>
        </div>
        <div class="col-6 Filterbtncol"><br><br>
          <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
            <mat-icon class="filterbtnicon">done</mat-icon>
            Filter
          </button>
        </div>
      </div>

      </form>
