import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-addtaskfilter',
  templateUrl: './addtaskfilter.component.html',
  styleUrls: ['./addtaskfilter.component.scss']
})
export class AddtaskfilterComponent implements OnInit {
  filterform: FormGroup;
  disablebutton: any;
  loadspinner: any = false;
  grouplist: any = [];
  roleslist: any = [];
  companylistdata:any=[];
  groupapibodycontent: any;
  allSelected = false;
  statuslist:any;
  @ViewChild('select') select: MatSelect;
  courseId:any;
  groupslist:any;
  managers:any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
//console.log(this.courseId)
    this.filterform = this.formBuilder.group({
      taskName: [''],
      taskListName: [''],
    });
    //this.apicallgetdefaultdropdown();
    this.setdefaultdropdownvalues()
  }


  ngOnInit(): void {
  }
  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');

    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['taskName'].setValue(data["Task Name"]);
      this.filterform.controls['taskListName'].setValue(data["Task List Name"]);
 
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Task Name"){
          this.filterform.controls['taskName'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Task List Name"){
          this.filterform.controls['taskListName'].setValue("");
        }
      
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }


   // on keyup enable filter button
  inputchangeCompanyName(value){
    this.dynamicEnableDisablebtn();
  }

  inputchangePrimaryAdmin(value){
    this.dynamicEnableDisablebtn();
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }


  Filter(){
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, apibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  getbodycontent() {
    let groupfilterdata = {
      "Task Name": this.filterform.value.taskName.trim(),
      "Task List Name": this.filterform.value.taskListName.trim(),
  
    }
    this.getapibodycontent();
    return groupfilterdata

  }

  getapibodycontent() {
    let companylistdataid= "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' +'('+ this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyID) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    this.groupapibodycontent = {
      "taskName": this.filterform.value.taskName.trim(),
      "taskListName": this.filterform.value.taskListName.trim(),
    }
  }


  checkformvalue(){
    let formempty;
    if(this.filterform.value.taskName.trim()==="" && this.filterform.value.taskListName.trim()==="" ){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

}
