import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ExamManagementComponent } from 'src/app/Administrator/exam-management/exam-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-exams-filter',
  templateUrl: './exams-filter.component.html',
  styleUrls: ['./exams-filter.component.scss']
})
export class ExamsFilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton:any;
  isDisabled: boolean;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<ExamManagementComponent>) {
    this.filterform = this.formBuilder.group({
      ExamTitle: [''],
      ExamID: [''],
      ExamPool: [''],
      Owner: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }


  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    let endpoint2="exams/exampool/getexampooldetails?companyId=";
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.loadspinner = true;
        this.apiService.SimplegetExammanagement(endpoint2,'GET')
        .subscribe((response) => {
          this.statuslist  = response["examPoolDetails"];
          this.loadspinner = false;
        },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
        // this.statuslist = [
        //   { "status_id": 1, "statusName": "Active" },
        //   { "status_id": 2, "statusName": "Inactive" },
        //   { "status_id": 3, "statusName": "Past Due" },
        //   { "status_id": 4, "statusName": "In Collection" },
        //   { "status_id": 5, "statusName": "Cancelled - Non Payment" },
        //   { "status_id": 6, "statusName": "Cancelled - By Request" }
        // ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['ExamTitle'].setValue(data["Exam Title"]);
      this.filterform.controls['ExamID'].setValue(data["Exam ID"]);
      this.filterform.controls['ExamPool'].setValue(data["Exam Pool"]);
      this.filterform.controls['Owner'].setValue(data["Owner"]);
      this.clearfilterfunction();
    }
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['Owner'].setValue(data);
      this.isDisabled=true;
      this.disablebutton=false;
    }
    else{
      this.isDisabled=false;
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Exam Title"){
          this.filterform.controls['ExamTitle'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Exam ID"){
          this.filterform.controls['ExamID'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Exam Pool"){
          this.filterform.controls['ExamPool'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Owner"){
          this.filterform.controls['Owner'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  selectCompanyID(value) {
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['Owner'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else{
      this.disablebutton=false;
    }
  }

  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['ExamPool'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else{
      this.disablebutton=false;
    }
  }

   // on keyup enable filter button
  inputchangeCompanyName(value){
    this.dynamicEnableDisablebtn();
  }

  inputchangePrimaryAdmin(value){
    this.dynamicEnableDisablebtn();
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }


  Filter(){
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  getbodycontent() {

    let CompanyID="";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID="";
    }
    else{
     CompanyID=this.filterform.value.Owner.trim()
    }
    let filterdata = {
      "Exam Title": this.filterform.value.ExamTitle.trim(),
      "Exam ID": this.filterform.value.ExamID.trim(),
      "Exam Pool": this.filterform.value.ExamPool.trim(),
      "Owner": CompanyID,
    }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    let companylistdataid= "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' +'('+ this.companylistdata[i].companyId + ')' === this.filterform.value.Owner) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    this.apibodycontent = {
      "examName": this.filterform.value.ExamTitle.trim(),
      "owner": companylistdataid,
      "examId": this.filterform.value.ExamID.trim(),
      "examPoolName": this.filterform.value.ExamPool.trim(),
    }
  }


  checkformvalue(){
    let formempty;
    if(this.filterform.value.ExamTitle.trim()==="" && this.filterform.value.ExamID.trim()==="" &&
    this.filterform.value.ExamPool.trim()==="" && this.filterform.value.Owner.trim()==="" ){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }



}
