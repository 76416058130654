<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol" style="margin-bottom: 14px;position: relative;">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label id="usernameid" class="labelname">Task List Name</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="TasklistName" placeholder="Task List Name" panelClass="myPanelmatselectClass" class="inputClass"
          (selectionChange)=selectTasklistName($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of uniqueTaskListName" [value]="item">
              <div class="matoptiontxt">{{item}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

       <div class="col-12 col-md-12 inputcolumn">
        <mat-label id="usernameid" class="labelname">Task Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inp0 (keyup)="inputchange0(inp0.value)" class="inputClassText"
            formControlName="TaskName">
        </mat-form-field>

      </div>

       <div class="col-12 col-md-12 inputcolumn">
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="Status" placeholder="Status" panelClass="myPanelmatselectClass" class="inputClass"
          (selectionChange)=selectStatusName($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of statuslist" [value]="item.statusName">
              <div class="matoptiontxt">{{item.statusName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="row">
      <div class="col-6 cancelbtncol"><br><br>
        <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-6 Filterbtncol"><br><br>
        <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Filter
        </button>
      </div>
    </div>
  </form>
  </div>
