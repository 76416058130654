import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  loginapiUrl, setpassapiUrl, environment, filterservice, usertable, createuserUrl, grouplistTable, importUrl, uploadurl, companylistMngmnt, managerlist,
  license, courseurl, externaltraininglist, enrollmentlist, companynamevalidate, assignableList, createcourse, courseProperties, curriculum, updatecurriclumUrl, assignments, assignmentList, formlist,
  launch, createforms, pefsettings, announcement, task, learnertask, createtasklist, launchlink, curriculasequesnce, launch_callback_serverurl, evaluatorlist, evaluatoraddremove, calendarview, groupstastsexporturl, assignmentlistexporturl, tasklistnameexporturl, scorm_importcourse,filepeekurl, activeuserlistprinturl, groupStatsprinturl, qrcodeinfo,
  transcriptprinturl, requirementRule, taskrule, delete_groupurl, curriculumprinturl, enrollmentprinturl, learnerAssignmentprinturl, userListprinturl, companyListprinturl, reportlink, jobPositionprinturl, learnertaskprinturl, learnerviewtaskurl, mergeUserurl, homeCounturl, homeAnnouncementurl, isntransferurl, cataloglist, generatePortability, sharedRecCompanyId, companylogo,
  ExamManagement,formlistimport,DemoLogin,extnltrningdnldcsvtemplate,extnltrningdnldcsvinstruction,formsdnldcsvtemplate,formsdnldcsvinstruction,
  Tasksdnldcsvtemplate,Tasksdnldcsvinstruction,usersdnldcsvtemplate,usersdnldcsvinstruction,evaluation, MEA_user_QRcode
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  loginapiUrl = loginapiUrl;
  DemoLogin = DemoLogin;
  setpassapiUrl = setpassapiUrl;
  usertable = usertable;
  filterservice = filterservice;
  createuserUrl = createuserUrl;
  grouplistTable = grouplistTable;
  importUrl = importUrl;
  companylistMngmnt = companylistMngmnt;
  evaluatorlist = evaluatorlist;
  evaluatoraddremove = evaluatoraddremove;
  uploadurl = uploadurl;
  courseurl = courseurl;
  externaltrainingurl = externaltraininglist;
  enrollmentlist = enrollmentlist;
  userdetails_data;
  user_page;
  managerlist = managerlist;
  license = license;
  companydetails;
  assignableList = assignableList;
  companynamevalidate = companynamevalidate;
  createcourse = createcourse
  courseProperties = courseProperties;
  curriculum = curriculum;
  updatecurriclumUrl = updatecurriclumUrl;
  fetchcurriclumUrl = updatecurriclumUrl;
  assignedlistTable = createcourse;
  assignments = assignments;
  assignmentList = assignmentList;
  formlist = formlist;
  launch = launch;
  createforms = createforms;
  pefsettings = pefsettings;
  formTemplateList = createforms;
  announcement = announcement;
  addcategoryurl = announcement;
  editcategoryurl = announcement;
  updatecategoryurl = announcement;
  task = task;
  learnertask = learnertask;
  createtasklist = createtasklist
  launchlink = launchlink;
  curriculasequesnce = curriculasequesnce;
  launch_callback_serverurl = launch_callback_serverurl;
  calendarview = calendarview;
  groupstastsexporturl = groupstastsexporturl;
  assignmentlistexporturl =assignmentlistexporturl;
  tasklistnameexporturl = tasklistnameexporturl;
  scorm_importcourse = scorm_importcourse;
  filepeekurl=filepeekurl;
  activeuserlistprinturl = activeuserlistprinturl;
  groupStatsprinturl = groupStatsprinturl;
  qrcodeinfo=qrcodeinfo;
  transcriptprinturl = transcriptprinturl;
  requirementRule =requirementRule;
  taskrule=taskrule;
  delete_groupurl = delete_groupurl;
  curriculumprinturl = curriculumprinturl;
  enrollmentprinturl = enrollmentprinturl;
  learnerAssignmentprinturl = learnerAssignmentprinturl;
  userListprinturl = userListprinturl;
  companyListprinturl = companyListprinturl;
  reportlink = reportlink;
  jobPositionprinturl = jobPositionprinturl;
  learnertaskprinturl = learnertaskprinturl;
  learnerviewtaskurl = learnerviewtaskurl;
  mergeUserurl = mergeUserurl;
  homeCounturl = homeCounturl;
  homeAnnouncementurl = homeAnnouncementurl;
  isntransferurl = isntransferurl;
  cataloglist = cataloglist;
  generatePortability = generatePortability;
  sharedRecCompanyId = sharedRecCompanyId;
  companylogo = companylogo;
  ExamManagement=ExamManagement;
  formlistimport=formlistimport;
  extnltrningdnldcsvtemplate=extnltrningdnldcsvtemplate;
  extnltrningdnldcsvinstruction=extnltrningdnldcsvinstruction;
  formsdnldcsvtemplate=formsdnldcsvtemplate;
  formsdnldcsvinstruction=formsdnldcsvinstruction;
  Tasksdnldcsvtemplate=Tasksdnldcsvtemplate;
  Tasksdnldcsvinstruction=Tasksdnldcsvinstruction;
  usersdnldcsvtemplate=usersdnldcsvtemplate;
  usersdnldcsvinstruction=usersdnldcsvinstruction;
  evaluation=evaluation;
  MEA_user_QRcode=MEA_user_QRcode;
  //url='https://dev.changetomorrow.org/changetomorrow';

  // Header
  headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Strict-Transport-Security', 'max-age=31536000; includeSubDomains; preload')
    .set('Content-Security-Policy', 'self')
    .set('X-Frame-Options', 'SAMEORIGIN')
    .set('X-Content-Type-Options', 'nosniff')
    .set('Referrer-Policy', 'no-referrer, strict-origin-when-cross-origin')
    .set('Permissions-Policy', 'self')
    .set('Accept', "application/octet-stream")
  constructor(public http: HttpClient,) {
    // console.log(environment.production);
    // console.log(environment.message);
  }


  // simple get
  get(url) {
    return this.http.get(url);
  }

  // get with queryparameter and header
  getReposSample(queryparameterid: string, endpoints: string) {
    let params = new HttpParams()
      .set('userid', queryparameterid)
    return this.http.get(this.loginapiUrl + '/' + endpoints, { params, 'headers': this.headers });
  }

  // login
  postLogin(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.loginapiUrl + '/' + endpoint, body, { 'headers': this.headers });
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.loginapiUrl + '/' + endpoint, body, { 'headers': this.headers });
  }

  put(url: string, endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.delete(this.loginapiUrl + '/' + endpoint1 + '/' + endpoint2, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.setpassapiUrl + '/' + endpoint, body, { 'headers': this.headers });
  }

  // setpassuri

  delete_user(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.delete(this.setpassapiUrl + '/' + endpoint1 + '/' + endpoint2, reqOpts);
  }

  patchsetPass(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.setpassapiUrl + '/' + endpoint, body, { 'headers': this.headers });
  }


  searchpermissions_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body,reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.setpassapiUrl + '/' + endpoint1 + '/' + endpoint2 + params,body, { 'headers': this.headers });
  }


  searchpermissions_filterpost(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.setpassapiUrl + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  searchpermissions_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.setpassapiUrl + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  Managepermissions_delete(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.delete(this.setpassapiUrl + '/' + endpoint1 + '/' + endpoint2, reqOpts);
  }

  //reset-password in edit user
  resetpassword(endpoint: string, body: any, reqOpts?: any) {
    //  console.log("resetapi");
    return this.http.patch(this.setpassapiUrl + '/' + endpoint, body, { 'headers': this.headers });
  }
  createrolepermission(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.createuserUrl + '/' + endpoint, body, { 'headers': this.headers });
  }

  updaterolepermission(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.createuserUrl + '/' + endpoint, body, { 'headers': this.headers });
  }

  validaterolepermission(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.createuserUrl + '/' + endpoint, body, { 'headers': this.headers });
  }

  get_rolepermissioninfo(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.createuserUrl + '/' + endpoint1, { 'headers': this.headers });
  }
  // usertable

  usertablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.usertable + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }

  filterpost(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.usertable + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.usertable + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }


  // usertable- filterservice
  Simpleget(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.filterservice + '/' + endpoint1, { 'headers': this.headers });
  }
  getwithparam(queryparameter1: string, endpoint1: string, reqOpts?: any) {
    let params = new HttpParams()
      .set('companyId', queryparameter1)
    return this.http.get(this.filterservice + '/' + endpoint1, { params, 'headers': this.headers });
  }

  getjobpositionlist(endpoint1: string, body: any, reqOpts?: any) {
    return this.http.post(this.task + '/' + endpoint1, body, { 'headers': this.headers });
  }
  
  // create user
  createuser(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.createuserUrl + '/' + endpoint, body, { 'headers': this.headers });
  }



  user_details(data, page) {
    this.userdetails_data = data;
    this.user_page = page;
    localStorage.setItem("pagedetails_loginname", this.userdetails_data);
    localStorage.setItem("pagedetails_page", this.user_page);
  }

  details_from_userpage() {

    var loginname = this.userdetails_data;
    var page = this.user_page;

    if (loginname == undefined && page == undefined) {
      loginname = localStorage.getItem("pagedetails_loginname");
      page = localStorage.getItem("pagedetails_page");
    }

    return { loginname, page };

  }

  edit_user(endpoint: string, body: any, reqOpts?: any) {

    return this.http.patch(this.createuserUrl + '/' + endpoint, body);
  }


  view_user(endpoint: string, reqOpts?: any) {

    return this.http.get(this.createuserUrl + '/' + endpoint, { 'headers': this.headers });
  }








  download(endpoint1: string, endpoint2: string, ID, type) {
    //  console.log(this.importUrl + '/' + endpoint1 + '/' +endpoint2 + '/'+ID, )
    return this.http.get(this.importUrl + '/' + endpoint1 + ID, { responseType: 'text' as 'json' });
  }

  userpatch(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.patch(this.createuserUrl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
  }

  // Grouplist Table

  group_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.grouplistTable + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }

  group_filterpost(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.grouplistTable + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  group_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.grouplistTable + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  delete_group(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.delete(this.grouplistTable + '/' + endpoint1 + '/' + endpoint2, reqOpts);
  }

  grouppatch(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.patch(this.grouplistTable + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
  }

  groupstatslist_tablepost(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.grouplistTable + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }
  create_group(endpoint1, body, type) {
    return this.http.post(this.grouplistTable + '/' + endpoint1, body, { 'headers': this.headers });
  }
  get_groupdata(endpoint1, endpoint2, endpoint3, type) {
    return this.http.get(this.grouplistTable + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3);
  }
  groupdata_sort(endpoint1, endpoint2, endpoint3, endpoint4, type) {
    return this.http.get(this.grouplistTable + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3 + '?sortOrder=' + endpoint4);
  }
  update_group(endpoint1, endpoint2, body, type) {
    return this.http.patch(this.grouplistTable + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
  }
  //add/remove users

  add_remove_user(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.setpassapiUrl + '/' + endpoint, body, { 'headers': this.headers });
  }

  // Company Mngmnt

  company_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.companylistMngmnt + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }

  company_filterpost(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.companylistMngmnt + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  assigneduser_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.evaluation + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  assigneduser_filter(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.evaluation + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  company_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.companylistMngmnt + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }


  createcompany(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.companylistMngmnt + '/' + endpoint, body, { 'headers': this.headers })
  }

  view_company(endpoint: string, reqOpts?: any) {
    return this.http.get(this.companylistMngmnt + '/' + endpoint, { 'headers': this.headers });
  }

  edit_company(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.companylistMngmnt + '/' + endpoint, body);
  }

  formdataPost(endpoint: string, formData: any, reqOpts?: any) {
    return this.http.post(this.companylistMngmnt + '/' + endpoint, formData)
  }

  companypatch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.companylistMngmnt + '/' + endpoint, body);
  }

  badgescanpropertiesaPost(endpoint1: string, body: any, reqOpts?: any) {
    return this.http.post(this.companylistMngmnt + '/' + endpoint1, body, { 'headers': this.headers });
  }

  badgescanpropertiesaget(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.companylistMngmnt + '/' + endpoint1, { 'headers': this.headers });
  }


  //validate login ID
  validate_loginId(endpoint: string, reqOpts?: any) {
    return this.http.get(this.createuserUrl + '/' + endpoint);
  }

  //evaluator

  evaluator_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.evaluatorlist + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }
  evaluator_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.evaluatorlist + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }

  evaluator_filterpost(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.evaluatorlist + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }


  //add/remove evaluators

  add_remove_evaluator(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.evaluatoraddremove + '/' + endpoint, body, { 'headers': this.headers });
  }
  // Manager

  manager_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.managerlist + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }

  manager_filterpost(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.managerlist + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  manager_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.managerlist + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }
  get_usersdata(endpoint1, endpoint2, type) {
    return this.http.get(this.managerlist + '/' + endpoint1 + '/' + endpoint2);
  }

  get_datausers(endpoint1, endpoint2, type) {
    return this.http.get(this.enrollmentlist + '/' + endpoint1 + '/' + endpoint2);
  }
  //change manager

  change_manager(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.setpassapiUrl + '/' + endpoint, body, { 'headers': this.headers });
  }


  get_manager(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.managerlist + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }


  //get company details
  company_details(data) {
    // console.log("..........", data);
    localStorage.setItem('testObject', JSON.stringify(data));
    var retrievedObject = localStorage.getItem('testObject');
    this.companydetails = JSON.parse(retrievedObject);
  }

  //get company details
  companydetails_val() {
    return this.companydetails;
  }


  //view company - User status
  viewcompany_userstatus(endpoint: string, reqOpts?: any) {
    return this.http.get(this.companylistMngmnt + '/' + endpoint);
  }

  viewcompany_bages(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.get(this.companylistMngmnt + '/' + endpoint1 + '/' + endpoint2);
  }

  //view company - user stats - active users
  userstats_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, api_type, reqOpts?: any) {

    if (api_type == 'Active' || api_type == 'Inactive' || api_type == 'Suspended' || api_type == 'Evaluator') {
      let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage;

      return this.http.post(this.usertable + '/' + endpoint1 + '/' + endpoint2 + params, body, { 'headers': this.headers });

    } else if (api_type == 'Licensed' || api_type == 'Proctor' || api_type == 'Manager' || api_type == 'Company Administrator' || api_type == 'Archived') {
      let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage;

      return this.http.post(this.managerlist + '/' + endpoint1 + '/' + endpoint2 + params, body, { 'headers': this.headers });
    }
  }

  // license

  license_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.license + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }


  license_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.license + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }
  //External Training

  External_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, endpoint3: string, body, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.externaltrainingurl + '/' + endpoint1 + "/" + endpoint2 + "/" + endpoint3 + params, body, { 'headers': this.headers });
  }
  //assignment list unde user

  assignmentList_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.assignmentList + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }
  viewassignmenteCourse(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
    return this.http.post(this.assignmentList + '/' + endpoint1 + '/' + endpoint2 ,  body, { 'headers': this.headers });
  }

  viewassignmentCurriculam(pageNo: any, itemPerPage: any, curriculumId:any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo +  '&pageSize=' + itemPerPage + '&curriculumId=' + curriculumId
    return this.http.get(this.assignmentList + '/' + endpoint1 + "/" + endpoint2 + params, { 'headers': this.headers });
  }

  viewassignmenttask(taskId:any,pageNo: any, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?taskId=' + taskId+ '&pageNumber=' + pageNo +  '&pageSize=' + itemPerPage
    return this.http.get(this.assignmentList + '/' + endpoint1 + "/" + endpoint2 + params, { 'headers': this.headers });
  }

  viewassignmentexam( endpoint1: string, endpoint2: string, examId:any,loginName: any, reqOpts?: any) {
    let params = '?examId=' + examId+ '&loginName=' + loginName
    return this.http.get(this.assignmentList + '/' + endpoint1 + "/" + endpoint2 + params, { 'headers': this.headers });
  }




  //license-req
  license_number(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.license + '/' + endpoint, body, { 'headers': this.headers });
  }

  //approve/deny license
  approve_license(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.license + '/' + endpoint, body, { 'headers': this.headers });
  }
  //validate company Name
  validate_companyname(endpoint: string, reqOpts?: any) {
    return this.http.get(this.companynamevalidate + '/' + endpoint, { 'headers': this.headers });
  }
  //assignable content list
  assignableList_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.assignableList + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }


  assignableList_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.assignableList + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  Nonscormmethod(endpoint1: string, body: any, reqOpts?: any) {
    return this.http.patch(this.assignableList + '/' + endpoint1, body,{ 'headers': this.headers });
  }

  //delete assignable content list
  delete_assignable(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.patch(this.assignableList + '/' + endpoint1 + '/' + endpoint2, reqOpts);
  }
  //Enrollment list
  Enrollment_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.enrollmentlist + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  // course
  get_coursedata(endpoint1, endpoint2, type) {
    return this.http.get(this.createcourse + '/' + endpoint1 + '/' + endpoint2);
  }

  //assign/withdraw enrolled users course
  addwithdraw_enrolled(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.enrollmentlist + '/' + endpoint, body, { 'headers': this.headers });
  }



  enrollmentlist_filterpost(endpoint: string, reqOpts?: any) {
    //return this.http.get(this.enrollmentlist + '/' + endpoint, { 'headers': this.headers });
    return this.http.get(endpoint, { 'headers': this.headers });
  }

  //course properties
  get_course_properties(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.get(this.courseProperties + '/' + endpoint1 + '/' + endpoint2, { 'headers': this.headers });
  }

  save_properties(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.courseProperties + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  //course revision
  get_revision_list(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.courseProperties + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  save_course_revision(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.patch(this.courseProperties + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  // //create curriculum
  // create_curriculum(endpoint: string, body: any, reqOpts?: any) {
  //   return this.http.post(curriculum+'/'+endpoint,  body, { 'headers': this.headers });
  // }

  //curriculum
  get_curriculumdata(endpoint1, endpoint2, type) {
    return this.http.get(this.fetchcurriclumUrl + '/' + endpoint1 + '/' + endpoint2);
  }
  //assign-withdraw title get data
  assign_withdraw_title_getdata(endpoint, type) {
    return this.http.get(this.curriculum + '/' + endpoint);
  }
  //assign-withdraw title get data
  assign_withdraw_title(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.curriculum + '/' + endpoint, body, { 'headers': this.headers });

  }
  // curriculam requirements
  curriculamrequirementpatch(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.patch(this.curriculum + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
  }

  // curriculamrequirementpost(pageNo: any, sort: string, itemPerPage: any, endpoint1: string, endpoint2: string, body, reqOpts?: any) {
  //   let params = '?pageNumber=' + pageNo + '&sort=' + sort + '&pageSize=' + itemPerPage
  //   return this.http.get(this.curriculamrequirements + '/' + endpoint1 + "/" + endpoint2  + params, body, { 'headers': this.headers });
  // }

  curriculamrequirementpost(pageNo: any, sort: string, itemPerPage: any, endpoint1: string, endpoint2: string, body, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sort=' + sort + '&pageSize=' + itemPerPage
    return this.http.get(this.curriculum + '/' + endpoint1 + "/" + endpoint2 + params, { 'headers': this.headers });
  }


  //get curriculum properties
  get_curriculum_properties(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.curriculum + '/' + endpoint1, { 'headers': this.headers });
  }

  //save curriculum properties
  save_curriculum_properties(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.curriculum + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  //assigned list
  assigned_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.assignedlistTable + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }
  //assign-withdraw enrolled users for curriculum
  addwithdraw_enrolledCurriculum(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.enrollmentlist + '/' + endpoint, body, { 'headers': this.headers });
  }

     //assignment details
   get_assignment_details(endpoint1, endpoint2, body, type) {
    //return this.http.get(this.assignments + '/' + endpoint1 + '/' + endpoint2);
    return this.http.post(this.assignments + '/' + endpoint1 + '/' + endpoint2 , body, { 'headers': this.headers });
  }



  get_getcurricula_details(endpoint1, endpoint2, type) {
    return this.http.get(this.curriculum + '/' + endpoint1 + '/' + endpoint2);
  }

  //curriculum
  get_curricula_list(pageNo: any, pagesize: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNo=' + pageNo + '&pageSize=' + pagesize
    return this.http.post(this.curriculum + '/' + endpoint1 + '/' + endpoint2 + params, body, { 'headers': this.headers });
  }
  	
  delete_curriculam(endpoint1: string, endpoint2: string, body:any, reqOpts?: any) {
    return this.http.patch(this.curriculum + '/' + endpoint1 + '/' + endpoint2, body);
  }

  // get assignent details for learner module
  get_assignment_detail(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.createcourse + '/' + endpoint1, { 'headers': this.headers });
  }

  //learner's task
  learner_task(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.learnertask + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }


  //create job position
  create_jobposition(endpoint1, body, type) {
    return this.http.post(this.learnertask + '/' + endpoint1, body, { 'headers': this.headers });
  }

  //update jobposition
  update_jobposition(endpoint1, endpoint2, body, type) {
    return this.http.patch(this.learnertask + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
  }

  get_positiondata(endpoint1, endpoint2, endpoint3) {
    return this.http.get(this.learnertask + '/' + endpoint1 + '/' + endpoint2);
  }
  positiondata_sort(endpoint1, endpoint2, endpoint3, endpoint4, type) {
    return this.http.get(this.learnertask + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3 + '?sortOrder=' + endpoint4);
  }
  jobpositiondata_sort(endpoint1, endpoint2, endpoint3, type) {
    return this.http.get(this.learnertask + '/' + endpoint1 + '/' + endpoint2 + '?sortOrder=' + endpoint3);
  }
  // Company Mngmnt

  formlist_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.formlist + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }


  formlist_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.formlist + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  delete_Forms(endpoint1: string, endpoint2: string, FormID:any,Version:any, reqOpts?: any) {
    let params = '?formId=' + FormID + '&version=' + Version
    return this.http.delete(this.formlistimport + '/' + endpoint1 + '/' + endpoint2 + params, reqOpts);
  }

  delete_training(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.delete(this.externaltrainingurl + '/' + endpoint1 + '/' + endpoint2, reqOpts);
  }

  get_training(endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.get(this.externaltrainingurl + '/' + endpoint1 + '/' + endpoint2, { 'headers': this.headers });
  }


  // launch com
  // usertable- filterservice
  launchget(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.launch + '/' + endpoint1, { 'headers': this.headers });
  }

  //save pef settings
  save_pef_settings(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.pefsettings + '/' + endpoint, body, { 'headers': this.headers });
  }
  viewForm(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.formlist + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
  }

  createFromTemplate(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, endpoint3: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.formTemplateList + '/' + endpoint1 + "/" + endpoint2 + "/" + endpoint3 + params, body, { 'headers': this.headers });
  }

  archived_paginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, endpoint3: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.formlist + '/' + endpoint1 + "/" + endpoint2 + "/" + endpoint3 + params, body, { 'headers': this.headers });
  }

  Transcriptpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.assignmentList + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }


  Transript_viewcompletionrecord(body: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    return this.http.post(this.assignmentList + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
  }

  // Announcement

  Announcement_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.announcement + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }

  Announcement_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.announcement + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  Announcement_categorytablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?sort=' + sortby + ',' + sorttype + '&pagenumber=' + pageNo + '&pageSize=' + itemPerPage
    return this.http.post(this.announcement + '/' + endpoint1 + '/' + endpoint2 + params, body, { 'headers': this.headers });
  }


  Announcement_Simpleget(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.announcement + '/' + endpoint1, { 'headers': this.headers });
  }

  //get announcement categories
  get_announcement_categories(endpoint1: string, body:any, reqOpts?: any) {
    return this.http.post(this.announcement + '/'+ endpoint1, body, { 'headers': this.headers });
  }

  //get api
  get_api(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.announcement + '/' + endpoint1, { 'headers': this.headers });
  }
  //create announcement
  create_announcement(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.announcement + '/' + endpoint, body, { 'headers': this.headers });
  }
  //update announcement
  update_announcement(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.announcement + '/' + endpoint, body, { 'headers': this.headers });
  }
  //get groups based on the company selection in audience
  get_audience_groups(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.announcement + '/' + endpoint, body, { 'headers': this.headers });
  }

  get_acknowledgementlist(endpoint1: string, reqOpts?: any) {
    return this.http.get(this.announcement + '/' + endpoint1, { 'headers': this.headers });
  }

  //confirm acknowledgement
  confirm_Acknowledgement(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.announcement + '/' + endpoint, body, { 'headers': this.headers });
  }
  //

  // Task

  Task_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.task + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  }

  Task_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.task + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  Addremoveget(endpoint1, endpoint2, type) {
    return this.http.get(this.task + '/' + endpoint1 + '/' + endpoint2, { 'headers': this.headers });
  }

  add_remove_usertask(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.task + '/' + endpoint, body, { 'headers': this.headers });
  }

  create_tasklist(endpoint1, endpoint2, body, type) {
    return this.http.post(this.createtasklist + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
  }

  // Assignremoveexistingjobposition( {
  //   let params = '?jobPositionId=' + this.jobPostionId
  //   return this.http.post(this.task + '/' + endpoint1 + '/' + endpoint2 + params, { 'headers': this.headers });
  // }

  getwitAssignremoveexistingjobpositionhparam(jobPostionId:any, endpoint1: string, endpoint2: string, reqOpts?: any) {
    let params = new HttpParams()
      .set('jobPositionId',jobPostionId)
    return this.http.get(this.task +'/' + endpoint1 + '/' + endpoint2, { params, 'headers': this.headers });
  }




  create_category(endpoint1, endpoint2, endpoint3, body, type) {
    return this.http.post(this.addcategoryurl + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3, body, { 'headers': this.headers });
  }
  Edit_category(endpoint1, endpoint2, endpoint3, type) {
    return this.http.get(this.editcategoryurl + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3, { 'headers': this.headers });
  }
  update_category(endpoint1, endpoint2, endpoint3, body, type) {
    return this.http.patch(this.updatecategoryurl + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3, body, { 'headers': this.headers });
  }

  get_courselink(endpoint1, body, type) {
    return this.http.post(this.launchlink + endpoint1, body, { 'headers': this.headers });
  }
  update_coursestatus(endpoint1, type) {
    return this.http.get(this.launchlink + endpoint1);
  }
  get_reportlink(endpoint1,endpoint2, type) {
    return this.http.get(this.reportlink + endpoint1 + '/' + endpoint2 );
  }

  get_sequenceinfo(endpoint1, endpoint2, type) {
    return this.http.get(this.curriculasequesnce + '/' + endpoint1 + '/' + endpoint2);
  }

  TermsandConditionslist_post(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.announcement + '/' + endpoint1 + '/' + endpoint2 + params, body, { 'headers': this.headers });
  }

  TermsandConditions_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.announcement + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  //Create Task Admin Module
  viewTask(endpoint1: string, endpoint2: string, endpoint3: string, type) {
    return this.http.get(this.task + '/' + endpoint1 + "/" + endpoint2 + "/" + endpoint3, { 'headers': this.headers });
  }

  // Learner task
  Learnertask_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
    return this.http.post(this.assignmentList + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
  }

  // export content

  company_export(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.companylistMngmnt + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }

  license_export(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
    return this.http.post(this.license + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
   //add--task

 add_task(endpoint: string, body: any, reqOpts?: any) {
  return this.http.post(this.task + '/' + endpoint ,  body, { 'headers': this.headers });
}

tasklistvie_api(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
  return this.http.post(this.task + '/' + endpoint1 + "/" + endpoint2 + "/"  + params, body, { 'headers': this.headers });
}

calendar_view(endpoint: string, loginname: any, startdate: any, enddate: any){

  return this.http.get(this.calendarview + '/' + endpoint + '/' + loginname + '?startDate=' + startdate + '&endDate=' + enddate, { 'headers': this.headers });

  }
  groupsats_export(endpoint1,endpoint2,endpoint3, body, type) {
    return this.http.post(this.groupstastsexporturl + '/' + endpoint1 + '/' + endpoint2+ '/' + endpoint3, body, { 'headers': this.headers,responseType: 'text' as 'json'});
  }
  assignmentlist_export(endpoint1,endpoint2, body, type) {
    return this.http.post(this.assignmentlistexporturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers,responseType: 'text' as 'json'});
  }
  Learnertask_Tasklikstname( endpoint1: string, endpoint2: string, reqOpts?: any){
    return this.http.post(this.tasklistnameexporturl + '/' + endpoint1 + "/" + endpoint2,{ 'headers': this.headers });
  }
  Learnertask_getTaskListName( endpoint1: string, endpoint2: string,pageNo: any,itemPerPage: any, reqOpts?: any){
    let params = '?pageNumber=' + pageNo +'&pageSize=' + itemPerPage
    return this.http.post(this.tasklistnameexporturl + '/' + endpoint1 + "/" + endpoint2 + "/"  + params,{ 'headers': this.headers });
  }
  activeUser_print(endpoint1: string, endpoint2: string, queryparameter1: string, body: any, reqOpts?: any){
    return this.http.post(this.activeuserlistprinturl + '/' + endpoint1 + '/' + endpoint2 + '/' + queryparameter1, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
  groupStats_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
    return this.http.post(this.groupStatsprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
  viewGroupUsers_print(endpoint1: string, endpoint2: string, queryparameter1: string, body: any, reqOpts?: any){
    return this.http.post(this.groupStatsprinturl + '/' + endpoint1 + '/' + endpoint2 + '/' + queryparameter1, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
  transcript_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
    return this.http.post(this.transcriptprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
  curricula_print(pageNo: any, pagesize: any, endpoint1: string, endpoint2: string, body:any, reqOpts?: any) {
    return this.http.post(this.curriculumprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
    }
  enrollment_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
    return this.http.post(this.enrollmentprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
  learnerAssignment_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
    return this.http.post(this.learnerAssignmentprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
  usersList_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
    return this.http.post(this.userListprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
  CompanyList_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
    return this.http.post(this.companyListprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }






  //qrcode info get
QRcodeinfo_get(endpoint1: string, body, reqOpts?: any) {
  return this.http.post(this.qrcodeinfo + '/' + endpoint1, body, { 'headers': this.headers });
}

 // formstructure
 formstructure(endpoint: string, body) {
  return this.http.post(formlist + endpoint, body, { 'headers': this.headers });
}

// get_reqrule(endpoint1: string, type) {
//   return this.http.get(this.requirementRule + endpoint1 );
// }

// create_taskRule( endpoint1: string, endpoint2: string, body, reqOpts?: any){

//    return this.http.post(this.taskrule  + endpoint1  + endpoint2 , body, { 'headers': this.headers });
//  }

//  update_taskRule( endpoint1: string, endpoint2: string, body, reqOpts?: any){

//    return this.http.put(this.taskrule  + endpoint1  + endpoint2 , body, { 'headers': this.headers });
//  }

//  get_taskrule(endpoint1: string, type) {
//   return this.http.get(this.taskrule + endpoint1 );
// }

// get_taskrequirements(endpoint1: string, type) {
//   return this.http.get(this.task + endpoint1 );
// }

deleteTaskGroup(endpoint1: string, endpoint2: string,body,type) {
  //  this.http.delete(this.delete_groupurl + '/' + endpoint1 + '/' + endpoint2,body);
   return this.http.request('DELETE', this.delete_groupurl + '/' + endpoint1 + '/' + endpoint2, {
    headers:this.headers,
    body: body
  });
}
getcourseaddtask(endpoint1: string, endpoint2: string,type) {
  return this.http.get(this.task + '/' + endpoint1 + "/" + endpoint2);
}
getTaskRequirement(endpoint1: string, endpoint2: string,type) {
  return this.http.get(this.task + '/' + endpoint1 + "/" + endpoint2);
}

getTaskRequirement_post(endpoint1: string, body: any, type) {
  return this.http.post(this.task + '/' + endpoint1, body,  { 'headers': this.headers });
}
 //task Rules Requirement
 create_requirementRule( endpoint1: string, body, reqOpts?: any){

  return this.http.post(this.requirementRule  + endpoint1 , body, { 'headers': this.headers });
}

get_reqrule(endpoint1: string, type) {
  return this.http.get(this.requirementRule + endpoint1 );
}

create_taskRule( endpoint1: string, endpoint2: string, body, reqOpts?: any){

   return this.http.post(this.taskrule  + endpoint1  + endpoint2 , body, { 'headers': this.headers });
 }

 update_taskRule( endpoint1: string, endpoint2: string, body, reqOpts?: any){

   return this.http.put(this.taskrule  + endpoint1  + endpoint2 , body, { 'headers': this.headers });
 }

 get_taskrule(endpoint1: string, type) {
  return this.http.get(this.taskrule + endpoint1 );
}

get_taskrequirements(endpoint1: string, type) {
  return this.http.get(this.task + endpoint1 );
}

get_requirementstask(endpoint1: string, type) {
  return this.http.get(this.requirementRule + endpoint1 );
}

get_reqprerequisites(endpoint1: string, type) {
  return this.http.get(this.task + endpoint1 );
}

getCompanyId(endpoint1: string,endpoint2: string, type){
  return this.http.get(this.assignments + '/' + endpoint1 + "/" + endpoint2);
}
proctor_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
  return this.http.post(this.assignments + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
}

proctorpatch(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  return this.http.patch(this.assignments + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}

learnerJobPosition_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.jobPositionprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
}

learnerTaskList_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.learnertaskprinturl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
}

learnerviewtask_print(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.learnerviewtaskurl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers, responseType: 'text' as 'json' });
}

mergeuser(endpoint1,endpoint2, body, type) {
  return this.http.patch(this.mergeUserurl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers,responseType: 'text' as 'json'});
}
get_taskruleinfo(endpoint1: string, reqOpts?:any){
  return this.http.get(this.assignmentList + '/' + endpoint1 );
}

getHomepageCount(endpoint1: string,endpoint2: string,body, type){
  return this.http.post(this.homeCounturl + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
}

getHomeAnnouncement(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.homeAnnouncementurl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}

isnTranferpatch(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  return this.http.patch(this.isntransferurl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}

isnTranfer_tablepost(pageNo: any, itemPerPage: any, sorttype: string, sortby: string, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&pageSize=' + itemPerPage + '&sortDirection=' + sorttype + '&sortBy=' + sortby
  return this.http.post(this.isntransferurl + '/' + endpoint1 + '/' + endpoint2 + params, body, { 'headers': this.headers });
}

filterisnpaginationpost(pageNo: any, itemPerPage: any, sorttype: string, sortby: string, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&pageSize=' + itemPerPage + '&sortDirection=' + sorttype + '&sortBy=' + sortby
  return this.http.post(this.isntransferurl + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
}

isnTranfer_logs(endpoint1: string,endpoint2: string,queryparameter: string, type){
  return this.http.get(this.isntransferurl + '/' + endpoint1 + "/" + endpoint2 + '/' + queryparameter);
}

isnfilterpost(endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  return this.http.post(this.isntransferurl + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}

add_remove_task_filter(endpoint1, endpoint2, body: any,reqOpts?: any) {
  return this.http.post(this.task + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}

create_catalog(endpoint1, endpoint2, queryparameter: string, body, type) {
  return this.http.post(this.cataloglist + '/' + endpoint1 + '/' + endpoint2 + '/' + queryparameter, body, { 'headers': this.headers });
}

cataloglist_tablepost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
  return this.http.post(this.cataloglist + '/' + endpoint1 + '/' + endpoint2 + params, body, { 'headers': this.headers });
}

update_catalog(endpoint1, endpoint2, endpoint3, body, type) {
  return this.http.patch(this.cataloglist + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3, body, { 'headers': this.headers });
}

view_singleCatalog(endpoint1, endpoint2, endpoint3, type) {
  return this.http.get(this.cataloglist + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3, { 'headers': this.headers });
}


delete_Announcement(endpoint1: string, endpoint2: string, reqOpts?: any) {
  return this.http.delete(this.announcement + '/' + endpoint1 + '/' + endpoint2, reqOpts);
}

assignRemoveCourse(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
  return this.http.post(this.cataloglist + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
}

assignRemoveCoursewithoutparams(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  return this.http.post(this.cataloglist + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
}

assignableCourseViewTable(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
  return this.http.post(this.cataloglist + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
}

getallassignedcourse(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.cataloglist + '/' + endpoint1 + "/" + endpoint2, body, { 'headers': this.headers });
}

assignRemoveCourseSave(endpoint: string, endpoint2: string, body: any, reqOpts?: any) {
  return this.http.patch(this.cataloglist + '/' + endpoint + "/" + endpoint2, body, { 'headers': this.headers });
}
getOwnerCatalog(endpoint1: string,  endpoint2: string, reqOpts?: any) {
  return this.http.get(this.cataloglist + '/' + endpoint1 + "/" + endpoint2, { 'headers': this.headers });
}

delete_catalog(endpoint1: string, endpoint2: string, reqOpts?: any) {
  return this.http.delete(this.cataloglist + '/' + endpoint1 + '/' + endpoint2, reqOpts);
}

generate_Portability(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.generatePortability + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}
generate_EvaluatorID(endpoint1: string, endpoint2: string, reqOpts?: any){
  return this.http.post(this.generatePortability + '/' + endpoint1 + '/' + endpoint2, { 'headers': this.headers });
}
SimplegetforShareRecords(endpoint1: string,type) {
  return this.http.get(this.sharedRecCompanyId + '/' + endpoint1, { 'headers': this.headers });
}
shareRecords(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.sharedRecCompanyId + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}
managePasswords(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.patch(this.companynamevalidate + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}
getShareRecords(endpoint1: string,endpoint2: string,type) {
  return this.http.get(this.evaluatoraddremove + '/' + endpoint1 + endpoint2, { 'headers': this.headers });
}

deleteJobPosition(endpoint1: string, endpoint2: string, queryparameter: string, reqOpts?: any) {
  let params = '?jobPositionId=' + queryparameter
  return this.http.delete(this.task + '/' + endpoint1 + '/' + endpoint2 + params, reqOpts);
}

deleteViewTask(endpoint1: string, endpoint2: string, queryparameter: string, reqOpts?: any) {
  let params = '?taskId=' + queryparameter
  return this.http.delete(this.task + '/' + endpoint1 + '/' + endpoint2 + params, reqOpts);
}

deleteTask(endpoint1: string, endpoint2: string, queryparameter: string, reqOpts?: any) {
  let params = '?taskListId=' + queryparameter
  return this.http.delete(this.task + '/' + endpoint1 + '/' + endpoint2 + params, reqOpts);
}

save_company_customattibutes(endpoint, body){
  return this.http.patch(this.companylistMngmnt + '/' + endpoint, body);
}

// Exam Management
SimplegetExammanagement(endpoint1: string, reqOpts?: any) {
  return this.http.get(this.ExamManagement + '/' + endpoint1, { 'headers': this.headers });
}
ExamManagement_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, endpoint2: string, body: any, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
  return this.http.post(this.ExamManagement + '/' + endpoint1 + "/" + endpoint2 + params, body, { 'headers': this.headers });
}

getpoollist(endpoint1: string, body:any, reqOpts?: any) {
  return this.http.post(this.ExamManagement + '/' + endpoint1, body, { 'headers': this.headers });
}
  questionPool(endpoint: string, body: any, reqOpts?: any) {
  return this.http.post(this.ExamManagement + '/' + endpoint, body, { 'headers': this.headers });
}
delete_Questions(endpoint1: string, endpoint2: string, reqOpts?: any) {
  return this.http.delete(this.ExamManagement + '/' + endpoint1 + '/' + endpoint2, reqOpts);
}
create_question(endpoint: string, body: any, reqOpts?: any) {
  return this.http.post(this.ExamManagement + '/' + endpoint, body, { 'headers': this.headers });
}

getExamPool(endpoint1: string,  endpoint2: string,  endpoint3: string, queryparameter: string, reqOpts?: any) {
  let params = '?companyId=' + queryparameter
  return this.http.get(this.ExamManagement + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3 + params, { 'headers': this.headers });
}

create_exam(endpoint1, endpoint2, endpoint3, queryparameter: string, body, type) {
  return this.http.post(this.ExamManagement + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3 + '/' + queryparameter, body, { 'headers': this.headers });
}

edit_exam(endpoint1, endpoint2, endpoint3, queryparameter: string, body, type) {
  return this.http.patch(this.ExamManagement + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3 + '/' + queryparameter, body, { 'headers': this.headers });
}

delete_exam(endpoint1: string, endpoint2: string, reqOpts?: any) {
  return this.http.delete(this.ExamManagement + '/' + endpoint1 + '/' + endpoint2, reqOpts);
}

get_examData(endpoint1, endpoint2, endpoint3, type) {
  return this.http.get(this.ExamManagement + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3);
}


pool_save(endpoint, body){
  return this.http.post(this.ExamManagement + '/' + endpoint, body);
}

pool_view(endpoint){
  return this.http.get(this.ExamManagement + '/' + endpoint, { 'headers': this.headers });
}

pool_edit(endpoint, body){
  return this.http.patch(this.ExamManagement + '/' + endpoint, body);
}

pool_delete(endpoint){
  return this.http.delete(this.ExamManagement + '/' + endpoint, { 'headers': this.headers } );
}

exampool_filterpaginationpost(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string, body: any, reqOpts?: any) {
  let params = '?pageNumber=' + pageNo + '&sortBy=' + sortby + '&sortDirection=' + sorttype + '&pageSize=' + itemPerPage
  return this.http.post(this.ExamManagement + '/' + endpoint1 + "/" +  params, body, { 'headers': this.headers });
}

exam_addremovequestion(pageNo: any, sortby: string, sorttype: string, itemPerPage: any, endpoint1: string,endpoint2:string, body: any, reqOpts?: any) {
  return this.http.post(this.ExamManagement + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}
get_viewexamdata(endpoint1, endpoint2, queryparameter: string, type) {
  return this.http.get(this.ExamManagement + '/' + endpoint1 + '/' + endpoint2 + '/' + queryparameter, { 'headers': this.headers });
}

edit_question(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.ExamManagement + '/' + endpoint, body, { 'headers': this.headers });
}
get_questioninfo(endpoint1: string, reqOpts?: any) {
  return this.http.get(this.ExamManagement + '/' + endpoint1, { 'headers': this.headers });
}

getcataloglist(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.createcourse + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers });
}

clear_examapi(endpoint1: string, body: any, reqOpts?: any){
  return this.http.post(this.ExamManagement + '/' + endpoint1 , body, { 'headers': this.headers });
}

exam_view(endpoint){
  return this.http.get(this.ExamManagement + '/' + endpoint, { 'headers': this.headers });
}

exam_save(endpoint, body){
  return this.http.post(this.ExamManagement + '/' + endpoint, body);
}

//share contet to companies
sharetocompanycontent(endpoint, body, type){
  return this.http.post(this.assignments + '/' + endpoint, body);
}

//share form to companies
sharetocompanyform(endpoint, body, type){
  return this.http.post(this.formlist + '/' + endpoint, body);
}

//share catalog to companies
sharetocompanycatalog(endpoint, body, type){
  return this.http.post(this.assignments + '/' + endpoint, body);
}

//share tasklist to companies
sharetocompanytasklist(endpoint, body, type){
  return this.http.post(this.task + '/' + endpoint, body);
}

//content to companies shared companies list
contenttocompany_selcompanies(endpoint, body, type){
  return this.http.post(this.assignments + '/' + endpoint, body);
}

//catalog to companies shared companies list
catalogtocompany_selcompanies(endpoint, body, type){
  return this.http.post(this.assignments + '/' + endpoint, body);
}

//form to companies shared companies list
formtocompany_selcompanies(endpoint, body, type){
  return this.http.post(this.formlist + '/' + endpoint, body);
}
//task to companies shared companies list
tasktocompany_selcompanies(endpoint1: string, reqOpts?: any) {
  return this.http.get(this.task + '/' + endpoint1, { 'headers': this.headers });
}
downloadimportuser(endpoint1: string, reqOpts?: any) {
  return this.http.get(this.generatePortability + '/' + endpoint1, { 'headers': this.headers });
}
downloadimportexternaltraining(endpoint1: string, reqOpts?: any) {
  return this.http.get(this.externaltrainingurl + '/' + endpoint1, { 'headers': this.headers });
}
downloadimportforms(endpoint1: string, reqOpts?: any) {
  return this.http.get(this.formlistimport + '/' + endpoint1,   { 'headers': this.headers});
}
downloadimporttask(endpoint1: string, reqOpts?: any) {
  return this.http.get(this.tasklistnameexporturl + '/' + endpoint1, { 'headers': this.headers });
}

//report services
get_report_users(endpoint1: string, reqOpts?: any) {
  return this.http.get(this.reportlink + '/' + endpoint1, { 'headers': this.headers });
}

scheduleReport(endpoint, body, type){
  return this.http.post(this.reportlink + '/' + endpoint, body);
}

postDemoLogin(endpoint: string, body: any, reqOpts?: any) {
  return this.http.post(this.DemoLogin + '/' + endpoint, body, { 'headers': this.headers });
}

patchVerifySignup(endpoint: string, body: any, reqOpts?: any) {
  return this.http.patch(this.DemoLogin + '/' + endpoint, body, { 'headers': this.headers });
}

gettasklist(endpoint1: string) {
  return this.http.get(this.task + '/' + endpoint1, { 'headers': this.headers });
}

update_tasklist(endpoint1, body, type) {
  return this.http.patch(this.task + '/' + endpoint1, body, { 'headers': this.headers });
}

// evaluation

getevaluation(endpoint1: string, endpoint2: string, body: any, reqOpts?: any){
  return this.http.post(this.evaluation + '/' + endpoint1 + '/' + endpoint2, body, { 'headers': this.headers,responseType: 'text' as 'json' });
}

}
