<div class="container" id="imag-upload-container">
    <!-- <div class="row" style="margin-top: 5%;">
        <div class="text-center col-md-12">
            <input type="file" (change)="fileChangeEvent($event)" />
        </div>
    </div> -->
    <div *ngIf="edit==true">
      <div class="row headerrow">
        <div class="col-10">
          <img src="assets/images/upload-image.svg" alt="filter">
          <span class="heading">&nbsp;&nbsp;Upload Image</span>
        </div>
    
        <div class="col-2 cleariconcol">
          <mat-icon class="clearicon" alt="clear" (click)="close()">clear</mat-icon>
        </div>
      </div>
     <div class="row" style="margin-top: 5%;min-height:450px" >
        <!-- <div class="text-center col-md-8" *ngIf="from=='server'">
            <h5>Crop Image</h5>
            <image-cropper 
            [imageURL]="imageChangedEvent" 
            [maintainAspectRatio]="true" 
            [aspectRatio]="4 / 4"
            [resizeToWidth]="256" 
            format="png" 
            (imageCropped)="imageCropped($event)" 
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" 
            (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div> -->
        <div class="text-center col-md-8" >
            <!-- <h5>Crop Image</h5> -->
            <image-cropper 
            [imageBase64]="imageChangedEvent" 
            [maintainAspectRatio]="true" 
            [aspectRatio]="4 / 4"
            [resizeToWidth]="256" 
            format="png" 
            (imageCropped)="imageCropped($event)" 
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" 
            (loadImageFailed)="loadImageFailed()"></image-cropper>
            <button mat-button  class="Filterbtn" (click)="scale()">
              Scale To Fit</button>
        </div>
        <div class="text-center col-md-4">
            <!-- <h5>Preview</h5> -->
            <div class="crop-container">
            <img  [src]="croppedImage" />
          </div>
        </div>
    </div> 
     <div class="row footerrow">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-0 col-xs-12 margin"></div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 cancel-btn-col margin"><br>
          <button mat-button  class="cancel-btn " (click)="cancel_image()" >
            <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
            Cancel</button>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 Filterbtncol margin"><br>
          <button mat-button  class="Filterbtn" (click)="save_image()">
            <mat-icon class="filterbtnicon">done</mat-icon>
            Save</button>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-0 col-xs-12 margin"></div>
      </div> 
      </div>
<div class="view-container" *ngIf="edit!=true">
      <div class="row headerrow">
        <div class="col-10">
          <img src="assets/images/upload-image.svg" alt="filter">
          <span class="heading">&nbsp;&nbsp;Upload Image</span>
        </div>
    
        <div class="col-2 cleariconcol">
          <mat-icon class="clearicon" alt="clear" (click)="close()">clear</mat-icon>
        </div>
      </div>
    <div class="row img-container">
        <img  *ngIf="prev_image != ''" [src]="prev_image" />
        <img *ngIf="prev_image==''" src="../../../../assets/images/upload-img.svg" style="width: 256px" >
    </div>
    
      <div class="row footerrow">
        <div class="col-6 margin" >
            <span>
                <button  *ngIf="prev_image != ''"  mat-icon-button class="usertablebtn" style="width:45px" (click)="edit_image()">
                  <img src="assets/images/edit_list.svg" alt="Edit" title="Edit">
                </button>
                <button  *ngIf="prev_image == ''"  mat-icon-button class="usertablebtn" style="width:45px" [disabled]="true">
                  <img src="assets/images/edit_list.svg" alt="Edit" title="Edit">
                </button>
             </span>
             <span>
                <button    mat-icon-button class="addphoto" 
                (click)="fileInput.click()">
                  <img src="assets/images/small-cam.svg" alt="Add New Image" title="Add New Image"><span>Add New Image</span> <input hidden (change)="onFileSelect($event)" #fileInput type="file" id="file" accept="image/*">
                </button>
             </span>
           
        </div>
    
        <div class="col-6 cleariconcol margin">
            <span>
              <button mat-icon-button *ngIf="prev_image != ''" class="deletebtn"  (click)="delete()">
                <img width="22px" src="assets/images/delete.svg" alt="Delete" title="Delete">
              </button>
                <button *ngIf="prev_image == ''" mat-icon-button class="deletebtn" [disabled]="true" >
                  <img width="22px" src="assets/images/delete.svg" alt="Delete" title="Delete">
                </button>
             </span>
        </div>
      </div>
</div>
</div>