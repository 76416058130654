import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-assignmentslaunch',
  templateUrl: './assignmentslaunch.component.html',
  styleUrls: ['./assignmentslaunch.component.scss']
})
export class AssignmentslaunchComponent implements OnInit {
  loadspinner:any;
  data:any
  urlSafe: SafeResourceUrl;
  constructor(@Inject(MAT_DIALOG_DATA) public resdata: string,private apiService: ApiServiceService, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log(this.resdata['regid'])
   //https://v6qh9su372.execute-api.us-east-1.amazonaws.com/dev/scorm/course/registrations/stellarb-course123456678/launchLink
    this.loadspinner = true;
   // const endpoint1 = 'scorm/course/registrations/'+ this.resdata['regid']+'/launchLink';
    const endpoint1 = 'scorm/course/registrations/'+ 'stellarb-course123456678'+'/launchLink';
    this.apiService.launchget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.data = response["link"];
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.data);
      },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
        document.getElementById('butExit').addEventListener('mouseover',function(){

          alert('exit is called')

       });
  }
}
