
<div>

    <div class="vertical-nav" id="sidebar" style="background-color: #143249; z-index: 8;" *ngIf="screenwidth>1269" >
      <app-sidebar [data]="menuTitle"></app-sidebar>
    </div>




    <!-- Start Page content holder  -->
    <div class="page-content" id="content">
      <app-header [data]="menuTitle" (searchInputvalue)='searchInput($event)' (selectcompanylistvalue)='SelectCompanylist($event)'> </app-header>


    <router-outlet ></router-outlet>
    </div>
  </div>


