import { Component, EventEmitter, OnInit, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from '../../../Services/api-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import domtoimage from 'dom-to-image';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  
  name;
  course;
  completiondate;
  companyname;

  

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<CertificateComponent>, public formBuilder: FormBuilder) {  

    this.name = data['name'];
    this.course=data['course'];
    this.completiondate=data['completiondate'];
    this.companyname=data['companyname'];




  }



  ngOnInit(): void {  }

  
  close() {
    this.dialog.close({ method: 'close' })
  }

  closeDialog() {
    this.dialog.close();
  }


  delete() {
    this.dialog.close({ method: 'delete' })
  }

  cancelbtn() {
    this.dialog.close({ method: 'close' })
  }

  convert(){
   
   domtoimage.toJpeg(document.getElementById('imageDIV'), { quality: 0.95 })
    .then(function (dataUrl) {
        var link = document.createElement('a');
        document.body.style.background = "#fff";
        link.download = 'Record-of-completion.jpeg';
        link.href = dataUrl;
        link.click();
    });
   
    
  }

}