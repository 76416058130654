import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-addremovejobposition',
  templateUrl: './addremovejobposition.component.html',
  styleUrls: ['./addremovejobposition.component.scss']
})
export class AddremovejobpositionComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  selected_users:any=[];
  user_data:any=[];
  backup_data:any=[];
  backup_users:any=[];
  sel_data:any=[];
  rem_data:any=[];
  filter_companyid:any='';
  sel_user_array=[];
  temp_group_data:any=[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {

    this.filter_companyid=data['filter_companyid'];
    this.sel_user_array=data['sel_users_list'];
    this.temp_group_data=data['temp_group_data']
    if(localStorage.getItem("temp_sel_users")!=undefined && localStorage.getItem("temp_sel_users")!=null && localStorage.getItem("temp_sel_users")!=''){
    this.selected_users=JSON.parse(localStorage.getItem("temp_sel_users"));
    for(var i=0; i<this.selected_users.length; i++){
      this.selected_users[i].isSelected=false;
    }
    this.backup_users=this.selected_users;
    }else{
    for(var i=0; i<this.sel_user_array.length; i++){
      this.selected_users.push({
        'companyId':this.sel_user_array[i].companyId,
        'displayName':this.sel_user_array[i].userName,
        'email':this.sel_user_array[i].email,
        'isSelected':false,
        'loginName':this.sel_user_array[i].loginName,
        'empCode':this.sel_user_array[i].empId
      })
    }
    this.backup_users=this.selected_users;
  }
  }

  ngOnInit(): void {
    this.get_userslist();
  }

  get_userslist(){
    this.loadspinner = true;
    const endpoint1 = 'users';
    const endpoint2 = 'searchusersfilter';
    let body = {
      "filters": {
        "firstName": "",
        "lastName": "",
        "loginName":"",
        "displayName": "",
        "email":"",
        "companyId": "",
        "empCode": "",
        "jobPositionName": "",
        "groupName": "",
        "roleName":"",
        "managerName":""
        }
        
        }

        this.apiService.manager_filterpost(endpoint1, endpoint2, body, 'POST')
        .subscribe((response) => {
        this.loadspinner = false;

       let temp_data:any = response["userListFilter"];

      // temp_data= temp_data.filter(option => ( option.companyId.toLowerCase().includes(this.filter_companyid.toLowerCase())));
      for(var i=0; i<temp_data.length; i++){
        temp_data[i].isSelected=false;
      }

      for(var i=0; i<temp_data.length; i++){
        for(var j=0; j<this.selected_users.length; j++){
          if(temp_data[i].loginName==this.selected_users[j].loginName){
             temp_data[i].isSelected=true;
           }
      }
     }

     temp_data = temp_data.filter(x => x.isSelected != true);

      this.user_data=temp_data;
     // this.user_data = response["userList"];

      this.backup_data=this.user_data;
        this.backup_data=this.user_data;
      },
      (err) => {
        this.loadspinner = false;
      })
  }

  cancel(){

    this.dialog.close();
        // let element = document.getElementById("course-content") ||  document.getElementById("user-content")
    // element.classList.remove('blur-content');
  }

  search_left(ev){

    this.user_data=this.backup_data;
    let filterValue=ev;
    this.user_data= this.user_data.filter(option => ( option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase()) || option.email.toLowerCase().includes(filterValue.toLowerCase())));
     }

  search_right(ev){

    this.selected_users=this.backup_users;
    let filterValue=ev;
    this.selected_users= this.selected_users.filter(option => ( option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase()) || option.email.toLowerCase().includes(filterValue.toLowerCase())));
      }

  itemSelected_leftbox(ev){
    let present=false;
    for(var k=0; k<this.sel_data.length; k++){
      if(ev.loginName==this.sel_data[k].loginName){
      present=true;
      this.sel_data.splice(k, 1);
    }
  }
  if(!present){
    this.sel_data.push(ev)
  }

  for(var l=0; l<this.backup_data.length; l++){
    if(!present){
    if(ev.loginName == this.backup_data[l].loginName){
      this.backup_data[l].isSelected=true;
    }
  }else{
    if(ev.loginName == this.backup_data[l].loginName){
      this.backup_data[l].isSelected=false;
    }
  }
  }
  for(var l=0; l<this.user_data.length; l++){
    if(!present){
    if(ev.loginName == this.user_data[l].loginName){
      this.user_data[l].isSelected=true;
    }
  }else{
    if(ev.loginName == this.user_data[l].loginName){
      this.user_data[l].isSelected=false;
    }
  }
  }

  }
  itemSelected_rightbox(ev){

    let present=false;
    for(var k=0; k<this.rem_data.length; k++){
      if(ev.loginName==this.rem_data[k].loginName){
        present=true;
        this.rem_data.splice(k, 1);
      }
    }
    if(!present){
      this.rem_data.push(ev)
    }
    for(var l=0; l<this.backup_users.length; l++){
      if(!present){
      if(ev.loginName == this.backup_users[l].loginName){
        this.backup_users[l].isSelected=true;
      }
    }else{
      if(ev.loginName == this.backup_users[l].loginName){
        this.backup_users[l].isSelected=false;
      }
    }
    }
    for(var l=0; l<this.selected_users.length; l++){
      if(!present){
      if(ev.loginName == this.selected_users[l].loginName){
        this.selected_users[l].isSelected=true;
      }
    }else{
      if(ev.loginName == this.selected_users[l].loginName){
        this.selected_users[l].isSelected=false;
      }
    }
    }
  }

  add_users(){

    if(this.sel_data.length>0){

  for(var j=0; j<this.backup_data.length; j++){
    if(this.backup_data[j].isSelected){
      this.selected_users.push(this.backup_data[j])
    }
  }


 this.user_data=this.backup_data;
  this.user_data = this.user_data.filter(x => x.isSelected != true);
  this.sel_data=[];
  for(var m=0; m<this.backup_data.length; m++){
    for(var n=0; n<this.selected_users.length; n++){
      if(this.selected_users[n].loginName==this.backup_data[m].loginName){
        this.backup_data[m].isSelected=true;
      }
    }
  }

  this.backup_data = this.backup_data.filter(x => x.isSelected != true);
  this.user_data=this.backup_data;
  for(var j=0; j<this.selected_users.length; j++){
    this.selected_users[j].isSelected=false;
  }
  this.backup_users=this.selected_users;
  //setting the serach value
  const name:any =  document.getElementById('sid1');
  name.value = "";
}
  }

  remove_user(){

    if(this.rem_data.length>0){

    for(var j=0; j<this.backup_users.length; j++){
      if(this.backup_users[j].isSelected){
        this.user_data.push(this.backup_users[j])
      }
    }

  this.selected_users=this.backup_users;
  this.selected_users = this.selected_users.filter(x => x.isSelected != true);
  this.rem_data=[];
  for(var m=0; m<this.backup_users.length; m++){
    for(var n=0; n<this.user_data.length; n++){
      if(this.user_data[n].loginName==this.backup_users[m].loginName){
        this.backup_users[m].isSelected=true;
      }
    }
  }
  }


  this.backup_users = this.backup_users.filter(x => x.isSelected != true);
  this.selected_users=this.backup_users;
  for(var j=0; j<this.user_data.length; j++){
    this.user_data[j].isSelected=false;
  }
  this.backup_data=this.user_data;
    const name:any =  document.getElementById('sid2');
    name.value = "";
  }


  add_users_api(){

        this.dialog.close(this.backup_users);

        // this.loadspinner=true;
        // let endpoint1='groups';
        // let endpoint2='update';
      //   let user_list:any=[];
      //   if(this.backup_users.length>0){
      //   for(var i=0; i<this.backup_users.length; i++){
      //     user_list.push(this.backup_users[i].loginName)
      //   }
      // }

      //   let body={
      //       "groupName":this.temp_group_data[0].groupName,
      //       "groupId":this.temp_group_data[0].groupId,
      //       "companyId":this.temp_group_data[0].companyId,
      //       "evaluatorId":this.temp_group_data[0].evaluatorId,
      //       "description":this.temp_group_data[0].description,
      //       "groupStatus":this.temp_group_data[0].groupStatus,
      //       "employees":user_list
      //   }
      //   this.apiService.update_group(endpoint1, endpoint2, body, 'PATCH')
      //   .subscribe((response) => {

      //    let res:any=response;
      //    if(res.message=='success' || res.message=='Success'){
      //     this.loadspinner = false;
      //    this.save();
      //    }else{
      //     let data = {
      //       status:res.message
      //     };
      //     this.dialogopen.open(DialogpopupComponent, {
      //       width: '550px',
      //       data: {  from: 'error-popup',
      //       name: data },
      //       position: {
      //         top: '40vh'
      //       }
      //     });
      //    }

      //   },
      //   (err) => {
      //     this.loadspinner = false;
      //   })
      }




  save(){
     this.dialog.close(this.selected_users);
    this.dialogopen.open(DialogpopupComponent, {
      width: '500px',
      disableClose: true,
      data:
      {
        from:"adduser-success",
      successmsg: "Hurray!!",
      successmsgbody:'User list updated successfully',
      imagelogo:"assets/images/thumbsup.svg"
      },
      position: {
        top: '25vh'
      }
    });
  }

  cancel_user(){
    let element = document.getElementById("CreateCompany-content")
    element.classList.add('blur-content')
    const dialogRef = this.dialogopen.open(DialogpopupComponent, {
    width: '530px',
    height: '330px',
    disableClose: true,
    data:{
      from:'image-cancel'
    }
    });
    dialogRef.afterClosed().subscribe(result => {
      let element = document.getElementById("CreateCompany-content")
      element.classList.remove('blur-content');
    });
}


}
