<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>


<div class="row headr" id="addremoveusertoexam">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec">
      <img src="assets/images/share.svg">
      <span class="titles">Schedule Report</span>

      <button type="button" class="close" aria-label="Close" (click)="close()">
        <mat-icon class="clear-btn">clear</mat-icon>
      </button>
    </div>
  </div>
  <hr style="margin-left: -20px;width: 104%">

  <div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <mat-label class="labelname">&nbsp;&nbsp; Report Name
    </mat-label>
    <br>
    <mat-form-field appearance="outline"  class="form-field">
    <input matInput placeholder="Enter Report Name" [(ngModel)]="reportname" name="reportname" class="textinput" 
     readonly>
    
    </mat-form-field>
  </div>
 
 
  </div>

  <!-- <div class="row">
    <div class="col-12">
      frequency
    </div>

  </div>

  <div class="row">
    <div class="col-12">
      date range
    </div>

  </div> -->

  <div class="row user-box">
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
     <div class="list-header">User List</div>
     <div class="list-search">
      <mat-form-field appearance="outline" class="matformfieldheader">
        <input type="text" #searchinput1 id="sid1" matInput (keyup)="search_left(searchinput1.value)" class="textsearchinput" placeholder="Search by name" autocomplete="off">
        <mat-icon matPrefix  class="searchbtn">search</mat-icon>
      </mat-form-field>
     </div>
     <div class="list-scroll">
      <div class="list-data" *ngIf="user_data.length>0">
        <mat-checkbox  [ngClass]="req_selectall==true?'checkboxbody ':'checkboxbody'" [(ngModel)]="req_selectall"  (change)="reqAll()">&nbsp;&nbsp;Select All
        </mat-checkbox>
      </div>
      <div *ngFor="let data of user_data" class="list-data">
         <mat-checkbox class="checkboxbody" [checked]="data.isSelected"  (change)="itemSelected_leftbox(data)">
         </mat-checkbox>
         &nbsp;&nbsp;&nbsp;  {{data.lastName}}, {{data.firstName}} ({{data.loginName}})</div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 btn-center">
      <div class="inner-container">
      <div class="row sub-row">
      <button type="button" mat-button class="addbtn"(click)="add_users()">
        Add
        <br>
        <mat-icon class="Cancelicon" style="color: white;" alt="clear">east</mat-icon>
      </button>
    </div>
    <div class="row sub-row" style="margin-top: 20px;">
      <button type="button" mat-button class="removebtn" (click)="remove_user()">
          Remove <br>
        <mat-icon class="Cancelicon" alt="clear">west</mat-icon>
      </button>
      </div>
    </div>
    </div>
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
      <div class="list-header">User Added</div>
      <div class="list-search">
       <mat-form-field appearance="outline" class="matformfieldheader">
         <input type="text" #searchinput2 id="sid2" matInput (keyup)="search_right(searchinput2.value)" class="textsearchinput" placeholder="Search by " autocomplete="off">
         <mat-icon matSuffix  class="searchbtn">search</mat-icon>
       </mat-form-field>
      </div>
      <div class="list-scroll">
        <div class="list-data" *ngIf="selected_users.length>0">
          <mat-checkbox  [ngClass]="requirement_selectall_right==true?'checkboxbody ':'checkboxbody'" [(ngModel)]="requirement_selectall_right"  (change)="reqAll_right()">&nbsp;&nbsp;Select All
          </mat-checkbox>
        </div>
      <div *ngFor="let data of selected_users" class="list-data checkbox">
          <mat-checkbox class="checkboxbody checkbox-right" [checked]="data.isSelected"   (change)="itemSelected_rightbox(data)">
          </mat-checkbox>
          {{data.lastName}}, {{data.firstName}} ({{data.loginName}})
      </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br><br>
      <button mat-button class="cancel-btn" (click)="close()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br><br>
      <button mat-button class="Filterbtn" (click)="submit()">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Schedule
      </button>
    </div>
  </div>
