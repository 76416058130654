import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { MatAccordion } from "@angular/material/expansion";
import { ApiServiceService } from 'src/app/Services/api-service.service';
import * as $ from 'jquery';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';
import { MatDialog } from '@angular/material/dialog';

export interface DragDropListItem {
  camera: boolean,
  performanceRatingNA: boolean,
  stepText: {
    type: string,
    label: string
  },
  stepDescription: {
    type: string,
    label: string
  },
  levels: [
    {
      label: string,
      inputType: string,
      subLevel: [
        {
          label: string,
          inputType: string,
        }
      ]
    }
  ]
}

@Component({
  selector: 'app-archivedformstructure',
  templateUrl: './archivedformstructure.component.html',
  styleUrls: ['./archivedformstructure.component.scss']
})
export class ArchivedformstructureComponent implements OnInit {

  public selectTabIndex: any;
  loadspinner: any;
  panelOpenState: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  structureForm: FormGroup;
  structurelist: DragDropListItem[];
  structuredata: any;
  disablebutton: any;
  expandbtn: boolean = false;
  collapsebtn: boolean = true;
  addline: boolean = false;
  addsubline: boolean = false;
  savebutton: boolean = true;
  publishbutton: boolean = true;
  cancelbutton: boolean = true;
  User: any;
  count: any;
  fromstr: any;
  subRubrosSelec;

  public myImage: HTMLElement;
  public options = { animation: 150 };
  A_C_FORMS_STRUCTURE_VIEW: any;
  A_C_FORMS_STRUCTURE_PUBLISH: any;
  sharedForm:any='0'
  constructor(private cd: ChangeDetectorRef, private formBuilder: FormBuilder, private apiservice: ApiServiceService, public dialog: MatDialog) {
    if(localStorage.getItem("formsharedFlagtocompany") != null && localStorage.getItem("formsharedFlagtocompany") != 'null' && localStorage.getItem("formsharedFlagtocompany") != undefined && localStorage.getItem("formsharedFlagtocompany") != 'undefined'){
      this.sharedForm=localStorage.getItem("formsharedFlagtocompany")
    }
    this.structureForm = this.formBuilder.group({
      imagecapturing: [''],
      performancerating: [],
      steptext: [''],
      stepdesc: ['']
    })

    // document.querySelector("body").style.cssText = "--formcursorval: not-allowed";
    // document.querySelector("body").style.cssText = "--formpointerval: none";


  }


  public started(e) {
    this.myImage = e.srcElement
      .getElementsByTagName('mat-expansion-panel-header')[0]
      .cloneNode(true);
    document.body.appendChild(this.myImage);
    e.dataTransfer.setDragImage(this.myImage, 0, 0);
  }

  public ended() {
    document.body.removeChild(this.myImage);
  }

  ngOnInit(): void {
    this.loadspinner = true;
    this.disablebutton = false;
    this.default_date();
    this.setdefaultpermission();
  }

  setdefaultpermission(){
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
    console.log(Permissiongroupdata)
    let login_role = localStorage.getItem("loginRole");

    if (login_role == 'System Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["System Administrator"]).length != 0) {
        this.A_C_FORMS_STRUCTURE_VIEW=Permissiongroupdata["rolesPermissions"]["System Administrator"]["A_C_FORMS_STRUCTURE_VIEW"];
        this.A_C_FORMS_STRUCTURE_PUBLISH=Permissiongroupdata["rolesPermissions"]["System Administrator"]["A_C_FORMS_STRUCTURE_PUBLISH"];
     }
    }
    else if (login_role == 'Primary Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Primary Administrator"]).length != 0) {
        this.A_C_FORMS_STRUCTURE_VIEW=Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["A_C_FORMS_STRUCTURE_VIEW"];
        this.A_C_FORMS_STRUCTURE_PUBLISH=Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["A_C_FORMS_STRUCTURE_PUBLISH"];
      }
     }
     else if (login_role == 'Company Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Company Administrator"]).length != 0) {
        this.A_C_FORMS_STRUCTURE_VIEW=Permissiongroupdata["rolesPermissions"]["Company Administrator"]["A_C_FORMS_STRUCTURE_VIEW"];
        this.A_C_FORMS_STRUCTURE_PUBLISH=Permissiongroupdata["rolesPermissions"]["Company Administrator"]["A_C_FORMS_STRUCTURE_PUBLISH"];
      }
    }
    else if (login_role == 'Manager') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Manager"]).length != 0) {
      }
      this.A_C_FORMS_STRUCTURE_VIEW=Permissiongroupdata["rolesPermissions"]["Manager"]["A_C_FORMS_STRUCTURE_VIEW"];
      this.A_C_FORMS_STRUCTURE_PUBLISH=Permissiongroupdata["rolesPermissions"]["Manager"]["A_C_FORMS_STRUCTURE_PUBLISH"];
    }

    else if (login_role == 'Proctor') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Proctor"]).length != 0) {
      }
    }
    else if (login_role == 'Learner') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Learner"]).length != 0) {
      }
    }

    }

  // enable_edit(){
  //   document.getElementById("btn_div").style.display = "flex";
  //   document.getElementById("view_active").style.display = "none";
  //   document.getElementById("edit_active").style.display = "block";
  //   document.querySelector("body").style.cssText = "--formcursorval: pointer";
  //   document.querySelector("body").style.cssText = "--formpointerval: all";
  //   this.savebutton = false;
  //   this.cancelbutton = false;
  // }

  disable_edit(){
    document.getElementById("btn_div").style.display = "none";
    document.getElementById("view_active").style.display = "block";
    document.getElementById("edit_active").style.display = "none";
    // document.querySelector("body").style.cssText = "--formcursorval: not-allowed";
    // document.querySelector("body").style.cssText = "--formpointerval: none";
    this.savebutton = true;
    this.cancelbutton = true;
  }

  default_date() {
    this.loadspinner = true;
    let endpoint = '/pef/fetchFormStructure/';
    let body = {
      "formId": localStorage.getItem("formId"),
      "version": localStorage.getItem("version")
    }

    this.apiservice.formstructure(endpoint, body).subscribe((res) => {
      this.structuredata = res
      this.structurelist = this.structuredata.formStructure.steps;

      if (this.structurelist == null) {
        this.structurelist = [
          {
            "camera": true,
            "performanceRatingNA": false,
            "stepText": {
              "type": "input",
              "label": ""
            },
            "stepDescription": {
              "type": "textarea",
              "label": ""
            },
            "levels": [
              {
                "label": "",
                "inputType": "TextBox",
                "subLevel": [
                  {
                    "label": "",
                    "inputType": "TextBox"
                  }
                ]
              }
            ]
          }
        ];
        this.count = 1;
      }

      this.loadspinner = false;
    });

  }

  Addsteps() {

    this.count = this.count + 1;

    if (this.structurelist == null) {
      this.structurelist = [];
    }
    this.structurelist.push({
      camera: true,
      performanceRatingNA: false,
      stepText: {
        type: "input",
        label: ""
      },
      stepDescription: {
        type: "textarea",
        label: ""
      },
      levels: [
        {
          label: "",
          inputType: "TextBox",
          subLevel: [
            {
              label: "",
              inputType: "TextBox",
            }
          ]
        }
      ]
    });

    this.savebutton = false;

    this.cd.detectChanges();
  }

  //drag and drop functionality for the steps
  drop(event: CdkDragDrop<DragDropListItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  // cancel() {
  //   event.preventDefault();
  //   let element = document.getElementById("course-content")
  //   element.classList.add('blur-content')
  //   const dialogRef = this.dialog.open(DialogpopupComponent, {
  //     width: '530px',
  //     height: '330px',
  //     disableClose: true,
  //     data: {
  //       from: 'course-cancel'
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     let element = document.getElementById("course-content")
  //     element.classList.remove('blur-content');
  //   });
  // }

  //Delete Steps dynamically
  delete_steps(count) {
    if (this.structurelist.length > 1) {
      this.structurelist.splice(count, 1);
    } else {
      this.count = this.count - 1;
    }

  }


  Addlevel(count) {
    document.getElementById("addlevel_connection").style.display = "none";
    document.getElementById("addlevel_div" + count).style.display = "block";
    document.getElementById("addlevel_btn" + count).style.display = "none";
  }


  addleveltext(count) {
    document.getElementById("addlevel_btn" + count).style.display = "none";
    this.addline = true;
    this.structurelist[count].levels.push({
      label: "",
      inputType: "TextBox",
      subLevel: [
        {
          label: "",
          inputType: "TextBox",
        }
      ]
    });
    this.cd.detectChanges();
  }

  Addsublevels(count, j) {
    this.addsubline = true;
    this.structurelist[count].levels[j].subLevel.push(
      {
        label: "",
        inputType: "TextBox",
      }
    );
    this.cd.detectChanges();
  }

  deleteleveltext(count, j) {
    this.structurelist[count].levels.splice(j, 1);
    if(this.structurelist[count].levels.length < 1){
      document.getElementById("addlevel_btn" + count).style.display = "block";
    }
  }

  deletesubleveltext(count, j, k) {

    if(this.structurelist[count].levels[j].subLevel.length > 1){
      this.structurelist[count].levels[j].subLevel.splice(k, 1);
    } else {
      this.count = this.count - 1;
    }

  }



  // save(status) {


  //   var dict = [];
  //   var levelarr = [];
  //   var sublevelarr = [];
  //   var pref

  //   for (var i = 0; i < this.structurelist.length; i++) {

  //     levelarr = [];
  //     sublevelarr = [];
  //      var radio_in = $('input[name="imagecap-' + i + '"]:checked').val();


  //     if ($('input[name="performance' + i + '"]:checked').val()) {
  //       pref = true;
  //     } else {
  //       pref = false;
  //     }

  //     var steptext = $('input[name="steptext' + i + '"]').val();
  //     var stepdesc = $('textarea[name="stepdesc' + i + '"]').val();

  //     for (var j = 0; j < this.structurelist[i].levels.length; j++) {

  //      sublevelarr = [];
  //       var leveltext = $('textarea[name="leveltext' + i + "-" + j + '"]').val();

  //       for (var k = 0; k < this.structurelist[i].levels[j].subLevel.length; k++) {
  //         var sublevel = $('textarea[name="sublevel' + i + "-" + j + "-" + k + '"]').val();
  //         sublevelarr.push({
  //           "label": sublevel,
  //           "inputType": "TextBox"
  //         })
  //       }

  //       levelarr.push({
  //         "label": leveltext,
  //         "inputType": "TextBox",
  //         "subLevel": sublevelarr
  //       })

  //     }

  //     dict.push({
  //       camera: radio_in,
  //       performanceRatingNA: pref,
  //       "stepText": {
  //         "type": "input",
  //         "label": steptext
  //       },
  //       "stepDescription": {
  //         "type": "textarea",
  //         "label": stepdesc
  //       },
  //       "levels": levelarr
  //     });

  //   }

  //   this.savebutton = true;
  //   this.publishbutton = false;
  //   this.cancelbutton = true;



  //   this.publish(status, dict);


  // }

  publish(status, data) {

    let publishflag;

    if (status === "Published") {
      this.savebutton = true;
      this.publishbutton = true;
      this.cancelbutton = true;
      publishflag = true;
      // document.querySelector("body").style.cssText = "--formcursorval: not-allowed";
      // document.querySelector("body").style.cssText = "--formpointerval: none";
      this.structureForm.disable();
      this.disable_edit();
    } else {
      publishflag = false;
    }

    this.loadspinner = true;
    var body = {
      "formId": localStorage.getItem("formId"),
      "formSettingId":localStorage.getItem("formSettingId"),
      "publishFlag": publishflag,
      "version": localStorage.getItem("version"),
      "status": status,
      "formStructure": {
        "steps": data
      }
    }

    console.log("........", body);
    let endpoint = '/pef/formstructure/';
    this.apiservice.formstructure(endpoint, body).subscribe((res) => {
      var response;
      response = res;

      this.loadspinner = false;

      if (response.message === "Success") {

        this.loadspinner = false;
        if (status === "Published") {
          document.getElementById("publish_sucess").style.display = "flex";


        } else if (status === "Draft") {
          document.getElementById("save_sucess").style.display = "flex";
        }
        setTimeout(() => {
          document.getElementById("publish_sucess").style.display = "none";
          document.getElementById("save_sucess").style.display = "none";
        }, 5000);
      }

    });



  }

  openAll() {
    this.expandbtn = true;
    this.collapsebtn = false;
    this.accordion.openAll();
  }

  closeAll() {
    this.expandbtn = false;
    this.collapsebtn = true;
    this.accordion.closeAll();
  }


}
