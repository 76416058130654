import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { CommondataserviceService } from "../../../Services/commondataservice.service";

@Component({
  selector: 'app-viewgroupusersfilter',
  templateUrl: './viewgroupusersfilter.component.html',
  styleUrls: ['./viewgroupusersfilter.component.scss'],
  providers: [DatePipe]
})
export class ViewgroupusersfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  today = new Date();
  data_details: any;
  dataList: any;
  companyId: any;
  groupName: any;

  constructor(private datePipe: DatePipe,private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>,private CommondataserviceService:CommondataserviceService) {
    this.data_details = this.CommondataserviceService.getdetails();
    this.groupName = (this.data_details['data2'])
    // console.log(this.groupName)
    //get data even after reload by not clicking view icon
    if (this.data_details['data1'] === "" || this.data_details['data1'] === undefined || this.data_details['data1'] === null) {
      let resdata = localStorage.getItem('user_details');
      this.dataList = JSON.parse(resdata);
      this.companyId = this.dataList['data1'];

    }
    else {
      localStorage.setItem('user_details', JSON.stringify(this.data_details));
      this.companyId = this.data_details['data1'];

    }
    this.filterform = this.formBuilder.group({
      userName: [''],
      jobPosition: [''],
      CompanyId: [''],
      assignedGroups: [''],
      Startdate: [''],
      Enddate: [''],
    });
    this.apicallgetdefaultdropdown();
    
  }

  ngOnInit(): void {
  }

  dateAddedFrom(value) {
    this.dynamicEnableDisablebtn();
  }
  dateAddedTo(value) {
    this.dynamicEnableDisablebtn();
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    this.filterform.controls['CompanyId'].setValue(this.companyId)

    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['userName'].setValue(data["User Name"]);
      this.filterform.controls['jobPosition'].setValue(data["Job Position"]);
      this.filterform.controls['CompanyId'].setValue(this.companyId)
      this.filterform.controls['assignedGroups'].setValue(data["Assigned Groups"]);
         // for date range
         let a = data["Date Added"].split('-')[0];
         let b = data["Date Added"].split('-')[1];
        //  console.log(a);
         if (data["Date Added"] === null || data["Date Added"] === undefined || data["Date Added"] === "") {
           this.filterform.controls['Startdate'].setValue("");
           this.filterform.controls['Enddate'].setValue("");
         } else {
           this.filterform.controls['Startdate'].setValue(new Date(a));
           this.filterform.controls['Enddate'].setValue(new Date(b));
         }
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="User Name"){
          this.filterform.controls['userName'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Job Position"){
          this.filterform.controls['jobPosition'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Assigned Groups"){
          this.filterform.controls['assignedGroups'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Date Added") {
          this.filterform.controls['Startdate'].setValue("");
          this.filterform.controls['Enddate'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    // this.loadspinner = true;
    // const endpoint1 = 'lookup';
    // this.apiService.Simpleget(endpoint1, 'GET')
    //   .subscribe((response) => {
        // this.loadspinner = false;
        // this.companylistdata = response["companies"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Active" },
          { "status_id": 2, "statusName": "Inactive" }
        ]
        this.setdefaultdropdownvalues();
      // },
      //   (err) => {
      //     this.loadspinner = false;
      //    // console.log(err)

      //   });
  }


  // on keyup enable filter button
  inputchangegroupname(value){
   this.dynamicEnableDisablebtn()
  }

  inputchangegroupID(value){
  this.dynamicEnableDisablebtn()
  }


  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }



  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let daterange="";
    let startdate="";
    let enddate="";
    let apidaterange="";
    let apistartdate="";
    let apienddate="";
    if(this.filterform.value.Startdate==="" || this.filterform.value.Startdate===undefined || this.filterform.value.Startdate===null){
      daterange="";
    }
    else{
     startdate=this.datePipe.transform(this.filterform.value.Startdate, 'MM/dd/yyyy');
     enddate=this.datePipe.transform(this.filterform.value.Enddate, 'MM/dd/yyyy');
     daterange=startdate + " - " + enddate;
     apistartdate=this.datePipe.transform(this.filterform.value.Startdate, 'yyyy-MM-dd');
     apienddate=this.datePipe.transform(this.filterform.value.Enddate, 'yyyy-MM-dd');
     apidaterange='2021-07-25'

    }
    
    let groupfilterdata = {
      "User Name": this.filterform.value.userName.trim(),
      "Job Position": this.filterform.value.jobPosition.trim(),
      "Assigned Groups": this.filterform.value.assignedGroups.trim(),
      "Date Added":  daterange,
      
    }
    this.getapibodycontent(apidaterange,apistartdate,apienddate);
    return groupfilterdata

  }

  getapibodycontent(apidaterange,apistartdate,apienddate) {
    let grpName;
    if(this.filterform.value.assignedGroups) {
      if(this.groupName === this.filterform.value.assignedGroups){
        console.log(this.groupName)
        grpName = this.groupName
      } else {
        console.log(this.groupName)
        grpName = this.groupName+","+this.filterform.value.assignedGroups.trim()
      }
      this.groupapibodycontent = {
        "userName":this.filterform.value.userName.trim(),
        "companyId": this.companyId,
        "jobPosition": this.filterform.value.jobPosition.trim(),
        "dateAdded":"",
        "groupName":grpName.trim(),
        "groupId":"",
        "startDate": apistartdate,
        "endDate": apienddate,
      }
    } else {
      this.groupapibodycontent = {
        "userName":this.filterform.value.userName.trim(),
        "companyId": this.companyId,
        "jobPosition": this.filterform.value.jobPosition.trim(),
        "dateAdded":"",
        "groupName": this.groupName,
        "groupId":"",
        "startDate": apistartdate,
        "endDate": apienddate,
      }
    }
  }

  checkformvalue(){
    // console.log(this.filterform.value.Startdate)
    // console.log(this.filterform.value.Enddate)
    let formempty;
    if(this.filterform.value.Startdate != ""){
      if(this.filterform.value.Enddate === null){
        formempty=true;
        return formempty;
      }
    }
    if(this.filterform.value.userName.trim()==="" && this.filterform.value.jobPosition.trim()==="" && 
    this.filterform.value.assignedGroups.trim()==="" && this.filterform.value.Startdate==="" &&  this.filterform.value.Enddate === ""){
        formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true ){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

  EnterSubmit(event){
    // console.log(event)
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  changeStartDate(event) {
  }
   changeEndDate(event) {
    
    if(event.value === null){
      this.disablebutton=true;
    }
    else{
     this.dynamicEnableDisablebtn();
    }
}
}